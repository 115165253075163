import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Problem, { problemConverter } from '../model/Problem';

export function subscribeToProblems(
  parent: string,
  onNext: (problems: Problem[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'problems').withConverter(problemConverter);
  let q = query(colRef, where('parent', '==', parent))

  if (!isPrivileged) {
    q = query(q, where('public', '==', true));
  }

  q = query(q, orderBy('order'));

  return (
    onSnapshot(q, 
      (querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data()))),
      onError,
      onCompletion
    )
  );
}

