import React from 'react';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';


export default function Permissions() {
  return (
    <Container>
      <Alert variant="danger">
        <Alert.Heading>
          Permissions Error
        </Alert.Heading>
        <p>
          You do not have permission to view this content.
        </p>
      </Alert>
    </Container>
  );
}

