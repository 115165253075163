import {
  collection,
  query,
  where,
  orderBy,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { sectorConverter } from '../model/Sector';

export async function getSectors(
  parent: string,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'sectors').withConverter(sectorConverter);
  let q = query(colRef, where('parent', '==', parent))

  if (!isPrivileged) {
    q = query(q, where('public', '==', true));
  }

  q = query(q, orderBy('order'));

  return (await getDocs(q)).docs.map(doc => doc.data());
}

