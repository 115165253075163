import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import Bezier from 'bezier-js';
import deepClone from 'lodash/cloneDeep';

import type { Topo } from '../../model/Topo';
import TopoLine from '../../model/TopoLine';
import Point from '../../model/Point';
import BezierChain, { BezierPoint }from '../../model/BezierChain';
import { ImageStorage } from '../../Firebase';
import { drawBezierChain, getMousePos } from '../../utils/canvas';
import RectBoundingBox from '../../model/RectBoundingBox';
import { rectContainsPoint, hitPoint, contain, findSnapPoint } from '../../utils/math';
import {
  getDownloadURL,
  listAll,
  ref
} from 'firebase/storage';

const EditorCanvas = styled.canvas`
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
`

interface EditorProps {
  topo: Topo;
  setTopo: (_: Topo) => void;
  showAllTopoLines: boolean;
  showLabels: boolean;
  pointSnapping: boolean;
  selectedTopoLine?: string;
};

export default function Editor(props: EditorProps) {
  // set this when parent topo needs updating
  const [updateTopo, setUpdateTopo] = React.useState(false);
  // local copy for visual changes
  const [topo, setTopo] = React.useState(props.topo);
  const [imgBounds, setImgBounds] = React.useState<RectBoundingBox>({offsetX: 0, offsetY: 0, width: 0, height: 0});
  const [img] = React.useState<HTMLImageElement>(new Image());
  const [canvasRef] = React.useState(React.createRef<HTMLCanvasElement>());
  const [tempPoint, setTempPoint] = React.useState<Point | null>();

  // state for moving points
  const [selectedPointIndex, setSelectedPointIndex] = React.useState<number>(-1);
  const [selectedPointValue, setSelectedPointValue] = React.useState<BezierPoint | null>(null);
  const [selectedControl, setSelectedControl] = React.useState<-1 | 1 | 2>(-1);
  const [snapPoints, setSnapPoints] = React.useState<Point[]>([]); // points relative to the img, not percentages or the canvas
  const [dragging, setDragging] = React.useState(false);

  // resize the canvas when the window resizes
  React.useEffect(() => {
    const onResize = () => {
      resizeCanvas();
      fixImgBounds();
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
    // I only want this to fire once ever. Not everytime we rerender and make a new function
    // eslint-disable-next-line
  }, []);

  // setup canvas
  React.useEffect(() => {
    resizeCanvas();
    fixImgBounds();
    // I only want this to fire only when canvasRef changes not every rerender when React builds new functions
    // eslint-disable-next-line
  }, [canvasRef])

  // when selected line changes, reset selected point
  React.useEffect(() => {
    setSelectedPointIndex(-1);
    setSelectedControl(-1);
    setSelectedPointValue(null);
  }, [props.selectedTopoLine])

  // get img
  React.useEffect(() => {
    listAll(ref(ImageStorage, props.topo.imageId))
      .then(list => {
        const item = list.items.find(item => item.name.includes('large'))
        if (item) {
          return getDownloadURL(item);
        } else {
          throw(Error(`Item ${props.topo.imageId} does not exist.`));
        }
      })
      .then(url => {
        img.src = url
        img.onload = () => { fixImgBounds() }
      })
      .catch(err => {
        console.log('There was a problem getting the download url: ', err);
      });
      // Don't rerender when functions change
      //eslint-disable-next-line
  }, [props.topo]);

  // update the parent topo when requested
  React.useEffect(() => {
    if (updateTopo) {
      setUpdateTopo(false);
      // todo do I even need to do this? It seemed to work without it...
      const updatedTopo = deepClone(topo);
      props.setTopo(updatedTopo);
    }
  }, [props, updateTopo, topo]);

  // update our topo when parent topo changes
  React.useEffect(() => {
    setTopo(props.topo);
  }, [props.topo]);

  // get a list of snapping points
  React.useEffect(() => {
    if (selectedPointIndex > -1) {
      const snapPoints: Point[] = [];
      props.topo.topoLines.forEach(line => {
        if (line.problemId !== props.selectedTopoLine) {
          line.bezier.points.forEach((point, index) => {
            if (selectedControl === -1) {
              snapPoints.splice(0, 0, {
                x: imgBounds.offsetX +imgBounds.width*point.x,
                y: imgBounds.offsetY +imgBounds.height*point.y
              });
            } else {
              if (index > 0) {
                snapPoints.splice(0, 0, {
                x: imgBounds.offsetX +imgBounds.width*point.cx1,
                y: imgBounds.offsetY +imgBounds.height*point.cy1
                });
              }
              if (index < line.bezier.points.length-1) {
                snapPoints.splice(0, 0, {
                x: imgBounds.offsetX +imgBounds.width*point.cx2,
                y: imgBounds.offsetY +imgBounds.height*point.cy2
                });
              }
            }
          });
        }
      });
      setSnapPoints(snapPoints);
    }
  }, [selectedPointIndex, selectedControl, imgBounds, props.selectedTopoLine, props.topo.topoLines]);


  // redraw
  React.useEffect(() => {
    // todo when hit regions becomes supported, use them

    const canvas = canvasRef.current!;
    const ctx = canvas.getContext('2d')!;
    const dpr = window.devicePixelRatio || 1;

    const {
      offsetX, offsetY,
      width, height
    } = imgBounds;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 3 * dpr;

    // draw background image
    ctx.drawImage(img, offsetX, offsetY, width, height);

    // draw temp point if applicable
    if (tempPoint) {
      ctx.beginPath();
      ctx.ellipse(offsetX + width * tempPoint.x, offsetY + height * tempPoint.y,
                 10, 10, 0, 0, 160);
      ctx.stroke();
    }

    // draw bezier curves
    if (props.showAllTopoLines) {
      topo.topoLines.forEach(line => {
        if (line.problemId !== props.selectedTopoLine) {
          // TODO: If topo line is not in problem make stroke style red
          drawBezierChain(ctx, line.bezier, imgBounds);
          // draw the label
          if (props.showLabels) {
            const i = line.labelIndex ?? 0;
            const points = line.bezier.points
            const calculationBezier = new Bezier(offsetX + width*points[i].x, offsetY + height*points[i].y,
                                                 offsetX + width*points[i].cx2, offsetY + height*points[i].cy2,
                                                 offsetX + width*points[i+1].cx1, offsetY + height*points[i+1].cy1,
                                                 offsetX + width*points[i+1].x, offsetY + height*points[i+1].y);
            const labelPoint = calculationBezier.get((line.labelPosition ?? 50) / 100);
            ctx.beginPath();
            ctx.arc(labelPoint.x, labelPoint.y, 10 * dpr, 0, 360);
            ctx.fillStyle = 'white';
            ctx.fill();
            }
          }
      });
    }

    // draw active bezier curve
    const activeLine = topo.topoLines.find(line => line.problemId === props.selectedTopoLine);
    if (activeLine) {
      // redraw selected dot differently
      drawBezierChain(ctx, activeLine.bezier, imgBounds);
      activeLine.bezier.points.forEach((p, i, a) => {
        ctx.beginPath()
        ctx.arc(offsetX + p.x * width, offsetY + p.y * height, 5 * dpr, 0, 360);
        ctx.stroke();
        if (i > 0) {
          ctx.beginPath()
          ctx.arc(offsetX + p.cx1 * width, offsetY + p.cy1 * height, 5 * dpr, 0, 360);
          ctx.stroke();
          ctx.beginPath()
          ctx.moveTo(offsetX + p.cx1 * width, offsetY + p.cy1 * height);
          ctx.lineTo(offsetX + p.x * width, offsetY + p.y * height);
          ctx.stroke();
        }
        if (i < a.length-1) {
          ctx.beginPath()
          ctx.arc(offsetX + p.cx2 * width, offsetY + p.cy2 * height, 5 * dpr, 0, 360);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(offsetX + p.x * width, offsetY + p.y * height);
          ctx.lineTo(offsetX + p.cx2 * width, offsetY + p.cy2 * height);
          ctx.stroke();
        }
      });
      // draw the label
      const i = activeLine.labelIndex ?? 0;
      const points = activeLine.bezier.points
      const calculationBezier = new Bezier(offsetX + width*points[i].x, offsetY + height*points[i].y,
                                           offsetX + width*points[i].cx2, offsetY + height*points[i].cy2,
                                           offsetX + width*points[i+1].cx1, offsetY + height*points[i+1].cy1,
                                           offsetX + width*points[i+1].x, offsetY + height*points[i+1].y);
      const labelPoint = calculationBezier.get((activeLine.labelPosition ?? 50) / 100);
      ctx.beginPath();
      ctx.arc(labelPoint.x, labelPoint.y, 10 * dpr, 0, 360);
      ctx.fillStyle = 'white';
      ctx.fill();
      // draw selected point
      if (selectedPointIndex > -1 && selectedPointValue) {
        const p = activeLine.bezier.points[selectedPointIndex];
        if (p) {

          ctx.beginPath()
          switch (selectedControl) {
            case -1:
              ctx.arc(offsetX + p.x * width, offsetY + p.y * height, 7 * dpr, 0, 360);
            break;
            case 1:
              ctx.arc(offsetX + p.cx1 * width, offsetY + p.cy1 * height, 7 * dpr, 0, 360);
            break;
            case 2:
              ctx.arc(offsetX + p.cx2 * width, offsetY + p.cy2 * height, 7 * dpr, 0, 360);
            break;
          }
          ctx.stroke();
        }
      }
    }

  }, [canvasRef,
      img,
      imgBounds,
      tempPoint,
      topo,
      props.selectedTopoLine,
      props.showAllTopoLines,
      props.showLabels,
      selectedPointIndex,
      selectedPointValue,
      selectedControl])

  const onClick = (e: React.MouseEvent) => {
    const canvas = canvasRef.current!;
    const {
      offsetX, offsetY,
      width, height
    } = imgBounds;

    const {x, y} = getMousePos(canvas, e.clientX, e.clientY);

    const currentTopoLine = topo.topoLines.find(line => line.problemId === props.selectedTopoLine);

    if (rectContainsPoint(offsetX, offsetY, width, height, x, y)) {
      if (props.selectedTopoLine) {
        if (currentTopoLine) {
          // do nothing
        } else {
          if (!tempPoint) {
            setTempPoint({
              x: (x - offsetX) / width,
              y: (y - offsetY) / height
            })
          } else {
            const endPoint = {
              x: (x - offsetX) / width,
              y: (y - offsetY) / height
            }
            generateTopo(tempPoint, endPoint);
            setTempPoint(null);
          }
        }
      }
    }
  }

  const onDoubleClick = (e: React.MouseEvent) => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext('2d')!;
    const dpr = window.devicePixelRatio;
    const {
      offsetX, offsetY,
      width, height
    } = imgBounds;

    const {x, y} = getMousePos(canvas, e.clientX, e.clientY);

    const currentTopoLine = topo.topoLines.find(line => line.problemId === props.selectedTopoLine);

    if (currentTopoLine) {
      const oldLineWidth = ctx.lineWidth * dpr;
      ctx.lineWidth = 10 * dpr;
      // find a line segment that includes the clicked point
      for (let i = 0; i < currentTopoLine.bezier.points.length-1; i++) {
        // create a path that represents the stroke
        const p1 = currentTopoLine.bezier.points[i];
        const p2 = currentTopoLine.bezier.points[i+1];
        const path = new Path2D();
        path.moveTo(offsetX + p1.x * width, offsetY + p1.y * height);
        path.bezierCurveTo(offsetX + p1.cx2 * width, offsetY + p1.cy2 * height,
          offsetX + p2.cx1 * width, offsetY + p2.cy1 * height,
          offsetX + p2.x * width, offsetY + p2.y * height);

        if (ctx.isPointInStroke(path, x , y)) {
          // generate bezier from bezier-js for projection calculation
          // todo use bezier-js to project a point on the line nearest
          //      the clicked point. Use this point instead of the
          //      clicked point
          //      maybe even place the controls so they don't change the line
          const p = {
            x: (x - offsetX) / width,
            y: (y - offsetY) / height,
            cx1: (x - offsetX - 10) / width,
            cy1: (y - offsetY) / height,
            cx2: (x - offsetX + 10) / width,
            cy2: (y - offsetY) / height
          }
          const newTopo = deepClone(topo);
          const newLine = newTopo.topoLines.find(line => line.problemId === props.selectedTopoLine);
          if (newLine) {
            newLine.bezier.points.splice(i+1, 0, p);
            setTopo(newTopo);
            setUpdateTopo(true);
          }
        }
      }
      // splice a point in after that index
      ctx.lineWidth = oldLineWidth;
    }
  }

  const onMouseDown = (e: React.MouseEvent) => {
    const canvas = canvasRef.current!;
    const {
      offsetX, offsetY,
      width, height
    } = imgBounds;

    const {x, y} = getMousePos(canvas, e.clientX, e.clientY);

    const currentTopoLine = topo.topoLines.find(line => line.problemId === props.selectedTopoLine);

    setDragging(true);
    let index = -1;
    if (currentTopoLine) {
      // control 1
      index = currentTopoLine.bezier.points.findIndex(p => hitPoint({x: offsetX + p.cx1 * width, y: offsetY + p.cy1 * height}, {x: x, y: y}, 5));
      if (index > -1 && index !== 0) {
        setSelectedPointIndex(index);
        setSelectedPointValue(currentTopoLine.bezier.points[index]);
        setSelectedControl(1);
        return;
      }

      // control 2
      index = currentTopoLine.bezier.points.findIndex(p => hitPoint({x: offsetX + p.cx2 * width, y: offsetY + p.cy2 * height}, {x: x, y: y}, 5));
      if (index > -1 && index !== currentTopoLine.bezier.points.length-1) {
        setSelectedPointIndex(index);
        setSelectedPointValue(currentTopoLine.bezier.points[index]);
        setSelectedControl(2);
        return;
      }

      // main point
      index = currentTopoLine.bezier.points.findIndex(p => hitPoint({x: offsetX + p.x * width, y: offsetY + p.y * height}, {x: x, y: y}, 5));
      if (index > -1) {
        setSelectedPointIndex(index);
        setSelectedPointValue(currentTopoLine.bezier.points[index]);
        setSelectedControl(-1);
        return;
      }
      setSelectedPointIndex(-1);
      setSelectedPointValue(null);
      setSelectedControl(-1);
    }
  }

  const onMouseMove = (e: React.MouseEvent) => {
    const canvas = canvasRef.current!;
    const {
      offsetX, offsetY,
      width, height
    } = imgBounds;

    const {x, y} = getMousePos(canvas, e.clientX, e.clientY);

    const currentTopoLine = topo.topoLines.find(line => line.problemId === props.selectedTopoLine);

    if (currentTopoLine && selectedPointIndex !== -1 && selectedPointValue && dragging) {
      const newTopo = deepClone(topo);
      // ugh, thankfully these arrays are small
      const pointRef = newTopo.topoLines.find(line => line.problemId === currentTopoLine.problemId)?.bezier.points[selectedPointIndex];
      const pointToSnap = findSnapPoint({x, y}, snapPoints, 8);
      if (pointRef) {
        const p = {x: 0, y: 0}
        let deltaX = 0;
        let deltaY = 0;
        switch (selectedControl) {
          case -1:
            p.x = offsetX + selectedPointValue.x * width;
            p.y = offsetY + selectedPointValue.y * height;
            break;
          case 1:
            p.x = offsetX + selectedPointValue.cx1 * width;
            p.y = offsetY + selectedPointValue.cy1 * height;
            break;
          case 2:
            p.x = offsetX + selectedPointValue.cx2 * width;
            p.y = offsetY + selectedPointValue.cy2 * height;
            break;
        }
        if (props.pointSnapping && pointToSnap) {
          deltaX = (pointToSnap.x - p.x) / width;
          deltaY = (pointToSnap.y - p.y) / height;
        } else {
          deltaX = (x - p.x) / width;
          deltaY = (y - p.y) / height;
        }
        switch (selectedControl) {
          case -1:
            pointRef.x = selectedPointValue.x + deltaX;
            pointRef.y = selectedPointValue.y + deltaY;
            pointRef.cx1 = selectedPointValue.cx1 + deltaX;
            pointRef.cy1 = selectedPointValue.cy1 + deltaY;
            pointRef.cx2 = selectedPointValue.cx2 + deltaX;
            pointRef.cy2 = selectedPointValue.cy2 + deltaY;
            break;
          case 1:
            pointRef.cx1 = selectedPointValue.cx1 + deltaX;
            pointRef.cy1 = selectedPointValue.cy1 + deltaY;
            break;
          case 2:
            pointRef.cx2 = selectedPointValue.cx2 + deltaX;
            pointRef.cy2 = selectedPointValue.cy2 + deltaY;
            break;
        }
        setTopo(newTopo);
      }
    }
  }

  const onMouseUp = (_: React.MouseEvent) => {
    if (!deepEqual(topo.topoLines, props.topo.topoLines)) {
      setUpdateTopo(true);
    }
    setDragging(false);
  }

  const onKeyPress = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "x":
        onDelete();
        break;
    }
  }

  const onDelete = () => {
    if (props.selectedTopoLine && selectedPointIndex > -1 && selectedControl === -1) {
      const newTopo = deepClone(topo);

      const line = newTopo.topoLines.find(line => line.problemId === props.selectedTopoLine);
      if (line && line.bezier.points.length > 2) {
        line.bezier.points.splice(selectedPointIndex, 1);
        // move the label if it's affected by the splice
        if (line.labelIndex > line.bezier.points.length - 2) {
          line.labelIndex--;
        }
        setSelectedPointIndex(-1);
        setSelectedPointValue(null);
        setSelectedControl(-1);
        setTopo(newTopo);
        setUpdateTopo(true);
      }
    }
  }


  const generateTopo = (p1: Point, p2: Point) => {
    if (props.selectedTopoLine) {
      const bezierChain: BezierChain = {
        points: [{
          x: p1.x,
          y: p1.y,
          cx1: p1.x,
          cy1: p1.y,
          cx2: p1.x + (p2.x-p1.x) * 0.25,
          cy2: p1.y + (p2.y-p1.y) * 0.25
        },
        {
          x: p2.x,
          y: p2.y,
          cx1: p2.x - (p2.x-p1.x) * 0.25,
          cy1: p2.y - (p2.y-p1.y) * 0.25,
          cx2: p2.x,
          cy2: p2.y
        }]
      }

      const newTopo = deepClone(topo);

      // There shouldn't be duplicate topos lines on a given topo, so for safety, lets make sure there isn't one.
      newTopo.topoLines = topo.topoLines.filter(line => line.problemId !== props.selectedTopoLine);

      const topoLine: TopoLine = {
        problemId: props.selectedTopoLine,
        bezier: bezierChain,
        labelIndex: 0,
        labelPosition: 50
      }

      newTopo.topoLines.push(topoLine);
      setTopo(newTopo);
      setUpdateTopo(true);
    }
  }

  const resizeCanvas = () => {
    const dpr = window.devicePixelRatio || 1;
    const canvas = canvasRef.current!;
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
  };

  const fixImgBounds = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      setImgBounds(contain(canvas.width, canvas.height, img.width, img.height));
    }
  }


  return (
    <EditorCanvas
      ref={canvasRef}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onDoubleClick={onDoubleClick}
      onKeyPress={onKeyPress}
      tabIndex={0} // feels hacky, need css to get rid of the ugly outline
    />
  );
}

