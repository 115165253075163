import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Zone from '../model/Zone';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  zones: Zone[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderZonesModal({ zones, show, onHide }: Props) {
  const [unsortedZones, setUnsortedZones] = React.useState<Zone[]>([]);
  const [sortedZones, setSortedZones] = React.useState<Zone[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Zone[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Zone[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(zones.filter(zone => zone.order === null));
    setSortedResetState(zones.filter(zone => zone.order !== null));
  }, [zones])

  // Initial zone state
  useEffect(() => {
    setUnsortedZones(zones.filter(zone => zone.order === null));
    setSortedZones(zones.filter(zone => zone.order !== null));
  }, [zones])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedZones, sortedResetState) && deepEqual(unsortedZones, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedZones, sortedResetState, unsortedZones, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedZones.forEach((zone, index) => {
          const original = zones.find(original => original.id === zone.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `zones/${zone.id}`), {order: index})
          }
        });
        unsortedZones.forEach(zone => {
          const original = zones.find(original => original.id === zone.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `zones/${zone.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [zones, sortedZones, unsortedZones, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = zones.filter(zone => unsorted.find(sortable => sortable.id === zone.id));
    const newSorted = zones.filter(zone => sorted.find(sortable => sortable.id === zone.id))
      // sort zones based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedZones(newUnsorted);
    setSortedZones(newSorted);
  }), [zones]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Zones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedZones.map(zone => ({id: zone.id ?? '', display: zone.name}))}
          defaultOrdered={sortedZones.map(zone => ({id: zone.id ?? '', display: zone.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

