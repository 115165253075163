import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import UserContext from '../context/UserContext';
import { BoulderSearch } from '../search/index';


interface BoulderCountProps {
  ancestryPath: string | null;
}

export default function BoulderCount({ ancestryPath }: BoulderCountProps) {
  const { user } = useContext(UserContext);
  const { data: boulderCount } = useQuery(
    ['boulderCount', [ ancestryPath, user ]],
    () => {
      let boulderSearch = new BoulderSearch(user?.hasElevatedReadAccess());

      if (ancestryPath) {
        boulderSearch.filterBy({ ancestryId: ancestryPath });
      }

      return (
        boulderSearch.get()
          .then(data => data.nbHits)
          .catch(e => { console.log(e); return null; })
      );
    }
  );

  return (
    <>
      {boulderCount ?? '-'}
    </>
  );
}

