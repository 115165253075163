import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import {
  BsPencil,
  BsQuestionCircle,
  BsSortUp,
  BsTrash,
} from 'react-icons/bs';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Accordion from 'react-bootstrap/Accordion';
import { useQuery, useQueryClient } from 'react-query';
import { PropagateLoader } from 'react-spinners'

import CustomIcon from '../Icon';
import * as Database from '../database';
import { deleteRegion } from '../database';
import UserContext from '../context/UserContext';
import { Note } from '../model/Note';
import Region from '../model/Region';
import { Title, Subtitle, Text } from './ThemedComponents';
import AreaCard from './AreaCard';
import ResponsiveCardDeck from './ResponsiveCardDeck';
import RegionModal from './RegionModal';
import AreaModal from './AreaModal';
import RequestsModal from './RequestsModal';
import FeedbackModal from './FeedbackModal';
import SectionContainer from './ContentSectionContainer';
import ReorderAreasModal from './ReorderAreasModal';
import NoteAccordion from './NoteAccordion';

interface Props {
  region: Region;
}

export default function RegionContent({ region }: Props) {
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState<string>('');
  const history = useHistory();

  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showAreaModal, setShowAreaModal] = useState(false);
  const [showReorderAreasModal, setShowReorderAreasModal] = useState(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalRegards, setFeedbackModalRegards] = useState('');

  const fetchAreas = useCallback(async () => {
    return Database.getAreas(`regions/${region.id}`, user?.hasElevatedReadAccess());
  }, [user, region.id]);

  const fetchReqs = useCallback(async () => {
    return Database.getInfoRequests(`regions/${region.id}`);
  }, [region.id]);

  const { isLoading: isLoadingAreas, data: areas } = useQuery(['areas', region.id, user], fetchAreas);
  const { data: reqs } = useQuery(['reqs', region.id], fetchReqs);

  const setHighlightViaHash = useCallback(() => {
    const hash = window.location.hash;
    if (hash.split('-').length > 1) {
      setHighlight(hash.split('-')[1]);
    }
  }, [])

  // highlight on hash change
  useEffect(() => {
    // init
    setHighlightViaHash();
    window.addEventListener('hashchange', setHighlightViaHash, false)
    return (() => window.removeEventListener('hashchange', setHighlightViaHash, false));
  }, [setHighlightViaHash])

  // subscribe to areas
  useEffect(() => {
    if (region && region.id) {
      return Database.subscribeToAreas(`regions/${region.id}`, () => {
        queryClient.invalidateQueries(['areas', region.id]);
      }, (error) => {
        console.log('There was an error subscribing to areas:', error);
      }, undefined, user?.hasElevatedReadAccess());
    }
  }, [region, user, queryClient]);

  const handleRequestModalSubmitInfo = useCallback((note: Note) => {
    setShowRequestsModal(false);
    setFeedbackModalRegards(`note ${note.id}`);
    setShowFeedbackModal(true);
  }, [])

  const handleShowRegionModal = useCallback(() => {
    setShowRegionModal(true);
  }, []);

  const handleCloseRegionModal = useCallback(() => {
    setShowRegionModal(false);
  }, []);

  const handleShowAreaModal = useCallback(() => {
    setShowAreaModal(true);
  }, []);

  const handleCloseAreaModal = useCallback(() => {
    setShowAreaModal(false);
  }, []);

  const handleShowReorderAreasModal = useCallback(() => {
    setShowReorderAreasModal(true);
  }, []);

  const handleCloseReorderAreasModal = useCallback(() => {
    setShowReorderAreasModal(false);
  }, []);

  const handleDeleteRegion = useCallback(async () => {
    if (window.confirm(`Area you sure you want to delete ${region.name}? This cannot be undone and will leave orphans.`)) {
      await deleteRegion(region.id);
      history.push('/guidebook');
    }
  }, [region.id, region.name, history]);

  return (
    <>
      { user?.hasWriteAccess() &&
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" onClick={handleShowRegionModal}><BsPencil width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowAreaModal}><CustomIcon.AddArea width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowReorderAreasModal}><BsSortUp width="1em" height="1em" /></Button>
            <Button variant="danger" onClick={handleDeleteRegion}><BsTrash width="1em" height="1em" /></Button>
          </ButtonGroup>
        </ButtonToolbar>
      }

      <ButtonToolbar className="justify-content-end">
        <ButtonGroup>
          {reqs !== undefined && reqs.length > 0 &&
          <Button
            onClick={() => setShowRequestsModal(true)}
          >
            <BsQuestionCircle />
            {" "}
            <Badge className="text-dark rounded-pill" bg="light">{reqs?.length}</Badge>
          </Button>
          }
        </ButtonGroup>
      </ButtonToolbar>

      <SectionContainer>
        <Row>
          <Col xs="auto">
            <Title>{region ? region.name : ''}</Title>
          </Col>
          {region.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
          </Col>
          }
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Row>
              <Col>
                <Subtitle id="description">Description</Subtitle>
                <Text>{region.description}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Subtitle id="directions">Directions</Subtitle>
                <Text>{region.directions}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContainer>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4>Areas</h4>
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                { isLoadingAreas &&
                  <Row>
                    <Col />
                    <Col xs="auto">
                      <PropagateLoader size={15} color='#c28465'/>
                    </Col>
                    <Col />
                  </Row>
                }
                {areas &&
                  <ResponsiveCardDeck>
                    <>
                    {areas.map((area, index) => {
                      return <AreaCard highlight={highlight === area.id} htmlId={`area-${area.id}`} key={`area-${index}`} area={area}/>
                    })}
                    </>
                  </ResponsiveCardDeck>
                }
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      { user?.hasElevatedReadAccess &&
      <NoteAccordion
        parent={`regions/${region.id}`}
      />
      }

      {/* Modals */}
      {user?.hasWriteAccess() &&
        <>
          <RegionModal
            show={showRegionModal}
            onHide={handleCloseRegionModal}
            region={region}
          />
          <AreaModal
            show={showAreaModal}
            onHide={handleCloseAreaModal}
            parent={`regions/${region.id}`}
          />
          <RequestsModal
            notes={reqs ?? []}
            show={showRequestsModal}
            onHide={() => setShowRequestsModal(false)}
            onSubmitInfo={handleRequestModalSubmitInfo}
          />
          <FeedbackModal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            withRegards={feedbackModalRegards}
          />
          {areas &&
            <ReorderAreasModal
              show={showReorderAreasModal}
              onHide={handleCloseReorderAreasModal}
              areas={areas}
            />
          }
        </>
      }
    </>
  );
}

