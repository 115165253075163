import React, { ChangeEvent, useCallback, useRef, useState, HTMLAttributes } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import { FirebaseStorage, UploadTask } from 'firebase/storage';
import { useMutation } from 'react-query';
import { upload, cancel, pause, resume } from '../utils/storage';
import { v4 as uuidv4 } from 'uuid';
import {
  BsPauseFill,
  BsPlayFill,
  BsStopFill
} from 'react-icons/bs';


interface Props {
  storage: FirebaseStorage;
  path: string;
  accept?: string;
  onUpload?: (path: string) => void;
}

/**
 * This component will upload a file to a FirebaseStorage
 * bucket at a specified path.
 *
 * This component will upload a file with a uuidv4, keeping the extension the
 * same as the file which was chosen.
 *
 * Path should be a directory which it can be located in. It should not end in
 * `/`. If the root directory is desired pass an empty string.
 */
export default function UploadButton( { storage, path, accept, onUpload, children, ...other }: Props & HTMLAttributes<HTMLDivElement>) {
  const input = useRef<HTMLInputElement | null>(null);
  const [task, setTask] = useState<UploadTask>();
  const [progress, setProgress] = useState<number>();
  const [error, setError] = useState<string>();
  const [paused, setPaused] = useState<boolean>();
  const { mutate, status, reset } = useMutation(async (file: File) => {
    const completePath = `${path}/${uuidv4()}.${file.name.split('.').pop()?.toLowerCase()}`;
    const task = upload({
      storage: storage,
      path: completePath,
      file: file,
      onProgressUpdate: ((n: number) => { setProgress(n); }),
      onFailure: ((s: string) => { setError(s); }),
      onComplete: (() => { onUpload && onUpload(completePath); }),
      onPause: ((b: boolean) => { setPaused(b); })
    });

    setTask(task);
    await task;
  });

  const handleButtonClicked = useCallback(() => {
    input?.current?.click();
  }, [input]);

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  const handleCancel = useCallback(() => {
    task && cancel(task);
  }, [task]);

  const handlePause = useCallback(() => {
    task && pause(task);
  }, [task]);

  const handleResume = useCallback(() => {
    task && resume(task);
  }, [task]);

  const handleSubmission = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length !== 1) {
      console.log('No map to upload');
      return;
    }

    const file = event.target.files[0]
    mutate(file);
  }, [mutate]);

  return (
    <div {...other}>
      { status === 'idle' &&
        <Button
          disabled={status !== 'idle'}
          onClick={handleButtonClicked}
          className="w-100 h-100"
          variant="outline-secondary"
        >
          {children}
        </Button>
      }
      { status === 'loading' &&
        <Alert className="h-100" variant="info">
          <Row className="mb-3">
            <Col>
              <ProgressBar now={progress} />
            </Col>
          </Row>
          <Row>
            <Col>
              {paused ?
              <Button className="w-100" variant="outline-success" onClick={handleResume}>
                <BsPlayFill />
              </Button>
              :
              <Button className="w-100" variant="outline-warning" onClick={handlePause}>
                <BsPauseFill />
              </Button>
              }
            </Col>
            <Col>
              <Button disabled={paused} className="w-100" variant="outline-danger" onClick={handleCancel}>
                <BsStopFill />
              </Button>
            </Col>
          </Row>
        </Alert>
      }
      { status === 'error' &&
        <Alert className="h-100" variant="danger">
          <div>{error}</div>
          <Button onClick={handleReset}>Try again</Button>
        </Alert>
      }
      { status === 'success' &&
        <Alert className="h-100" variant="success">
          <div>Successfully uploaded</div>
        </Alert>
      }
      <input type="file" ref={input} accept={accept} onChange={handleSubmission} style={{display: 'none'}}/>
    </div>
  );
}
