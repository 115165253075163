import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  startAfter,
  DocumentSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Photo, { PhotoConverter } from '../model/Photo';

export async function getPhotos(n=10, startAfterSnapshot=null): Promise<[Photo[], DocumentSnapshot]> {
  const colRef = collection(Firestore, 'gallery').withConverter(PhotoConverter);
  let q = query(colRef, orderBy('pid'), limit(n));

  if (startAfterSnapshot) {
    q = query(q, startAfter(startAfterSnapshot));
  }

  const photoSnapshots = await getDocs(q);
  const photos = photoSnapshots.docs.map(doc => doc.data()) as Photo[];
  return [photos, photoSnapshots.docs[photoSnapshots.docs.length-1]];
}

