import {
  FirebaseStorage,
  UploadTask,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

interface params {
  storage: FirebaseStorage;
  path: string;
  file: File;
  onProgressUpdate?: (n: number) => void;
  onFailure?: (s :string) => void;
  onComplete?: () => void;
  onPause?: (b: boolean) => void;
}

// TODO Export an api which can be used to pause and resume
// uploads. There is also a state change for firebase which
// can be listened to for feedback on this.
export function upload( { storage, path, file, onProgressUpdate, onFailure, onComplete, onPause }: params ) {
  const newRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(newRef, file);

  uploadTask.on('state_changed',
    (snapshot) => {
      onProgressUpdate && onProgressUpdate(snapshot.bytesTransferred / snapshot.totalBytes * 100);
      switch(snapshot.state) {
        case 'paused':
          onPause && onPause(true);
          break;
        case 'running':
          onPause && onPause(false);
      }
    },
    (_) => {
      // TODO Handle errors
      onFailure && onFailure("There was an error.");
    },
    () => {
      // TODO Maybe return the download url, that could be useful
      // return { path: $path, download: $downloadUrl }
      onComplete && onComplete();
    },
  );

  return uploadTask;
}

export function cancel(task: UploadTask) {
  task.cancel();
}

export function pause(task: UploadTask) {
  task.pause();
}

export function resume(task: UploadTask) {
  task.resume();
}

