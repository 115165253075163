import React, { useCallback } from 'react';
import { useHistory }from 'react-router-dom';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Header } from './ThemedComponents';
import Region from '../model/Region';

interface RegionCardProps {
  htmlId?: string;
  region: Region;
  highlight?: boolean;
}

export default function RegionCard({ htmlId, region, highlight }: RegionCardProps) {
  const history = useHistory();

  const navigate = useCallback(() => {
    history.push(`/guidebook/region?id=${region.id}`)
  }, [region.id, history]);

  return (
    <Card className={highlight ? 'bg-secondary' : ''} id={htmlId} onClick={navigate} style={{maxWidth: '500px', cursor: 'pointer'}}>
      <Card.Img variant="top" />
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>
              <Header>{region.name}</Header>
            </Col>
            {region.private &&
            <Col xs="auto">
              <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
            </Col>
            }
          </Row>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

