import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

interface ZoneData {
  name: string;
  directions: string;
  description: string;
  parent: string;
  order: number | null;
  private: boolean;
}

interface Zone extends ZoneData {
  id: string;
  discriminator: 'Zone'
}

const zoneConverter: FirestoreDataConverter<Zone> = {
  toFirestore(zone: WithFieldValue<Zone>): DocumentData {
    let copy = {...zone};
    delete copy.id;
    delete copy.discriminator;

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Zone {
    const data = snapshot.data(options);
    const zone: Zone = {
      id: snapshot.id,
      name: data.name ?? '',
      directions: data.directions ?? '',
      description: data.description ?? '',
      parent: data.parent ?? null,
      order: data.order ?? null,
      private: data.private ?? false,
      discriminator: 'Zone'
    }

    return zone;
  }
};

function isZone(obj: any): obj is Zone {
  try {
    return (obj as Zone).discriminator === 'Zone';
  } catch {
    return false;
  }
}

export default Zone;
export { zoneConverter, isZone };
export type { ZoneData };

