import React from 'react';
import styled from 'styled-components';
import deepClone from 'lodash/cloneDeep';

import * as Database from '../../database/index';
import type { Topo } from '../../model/Topo';
import Problem from '../../model/Problem';
import Editor from './Editor';
import ControlPanel from './ControlPanel';
import Menubar from './Menubar';

const Backdrop = styled.div`
  z-index: 10000;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const CustomModal = styled.div`
  z-index: 10001;
  position: fixed;
  left: 1em;
  top: 1em;
  right: 1em;
  bottom: 1em;
`

// todo fix up the sizes
const RootContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(200px, 300px);
  grid-template-rows: minmax(min-content, max-content) minmax(0, 1fr);
  grid-template-areas:
    "menubar  controlpanel"
    "editor   controlpanel";
  width: 100%;
  height: 100%;
  background-color: white;
`

// todo fixup the margins
const MenubarContainer = styled.div`
  grid-area: menubar;
`

const EditorContainer = styled.div`
  grid-area: editor;
`

const ControlPanelContainer = styled.div`
  grid-area: controlpanel;
`


interface Props {
  topo: Topo;
  onHide: () => void;
  onSave: (_: Topo) => void;
}

export default function TopoEditor(props: Props) {
  const [initTopo, setInitTopo] = React.useState<Topo>();
  const [showAllTopoLines, setShowAllTopoLines] = React.useState(true);
  const [showLabels, setShowLabels] = React.useState(true);
  const [pointSnapping, setPointSnapping] = React.useState(true);
  const [topo, setTopo] = React.useState(props.topo);
  const [problems, setProblems] = React.useState<Problem[]>([]);
  const [selectedProblemId, setSelectedProblemId] = React.useState<string>();

  // remove me when done debugging
  React.useEffect(() => {
    console.log('Topo changed:', topo);
  }, [topo]);

  const onReset = () => {
    if (initTopo) {
      // for safety, lets create a copy
      // when I am sure I do not edit the topo
      // manually everywhere, I will just make it
      // setTopo(initTopo)
      const oldTopo = deepClone(initTopo);
      setTopo(oldTopo);
    }
  };

  const onSave = () => {
    props.onSave(topo);
  };

  const onClose = () => {
    props.onHide();
  };

  // remember original topo
  React.useEffect(() => {
    setInitTopo(deepClone(props.topo));
    // todo unsure about this, but I think I don't want to change everytime props.topo changes, unsure...
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // TODO change to use query
    Database.getProblems(`boulders/${props.topo.boulderId}`, true)
    .then(problems => setProblems(problems))
    .catch(e => console.log(e));
  }, [props.topo]);

    return (
      <>
        <Backdrop className="fade show modal-backdrop"/>
        <CustomModal>
          <RootContainer>
            <MenubarContainer>
              <Menubar
                onReset={onReset}
                onSave={onSave}
                onClose={onClose}
                onShowAllTopoLines={() => setShowAllTopoLines(!showAllTopoLines)}
                onShowLabels={() => setShowLabels(!showLabels)}
                onPointSnapping={() => setPointSnapping(!pointSnapping)}

                showAllTopoLines={showAllTopoLines}
                showLabels={showLabels}
                pointSnapping={pointSnapping}
              />
            </MenubarContainer>
            <EditorContainer>
              <Editor
                topo={topo}
                setTopo={setTopo}
                showAllTopoLines={showAllTopoLines}
                showLabels={showLabels}
                pointSnapping={pointSnapping}
                selectedTopoLine={selectedProblemId}
              />
            </EditorContainer>
            <ControlPanelContainer>
              <ControlPanel
                topo={topo}
                setTopo={setTopo}
                problems={problems}
                selectedProblemId={selectedProblemId}
                setSelectedProblemId={setSelectedProblemId}
              />
            </ControlPanelContainer>
          </RootContainer>
        </CustomModal>
      </>
    );
}

