import {
  collection,
  query,
  where,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import type { Topo } from '../model/Topo';
import { topoConverter } from '../model/Topo';

export function subscribeToTopos(
  parentId: string,
  onNext: (topos: Topo[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  const colRef = collection(Firestore, 'topos').withConverter(topoConverter);
  const q = query(colRef, where('boulderId', '==', parentId))

  return (
    onSnapshot(q, 
      (querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data()))),
      onError,
      onCompletion
    )
  );
}

