import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  ref,
  listAll,
  getDownloadURL,
} from 'firebase/storage';

import { ImageStorage } from '../Firebase';
import { getTopos } from '../database';
import { Header, Text } from './ThemedComponents';
import Boulder from '../model/Boulder';
import ProblemCount from './ProblemCount';

interface BoulderCardProps {
  boulder: Boulder;
  htmlId?: string;
  highlight?: boolean;
}

const LargeCount = styled(Text)`
  font-size: large;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
`;

const CountHeader = styled(Text)`
  font-size: x-small;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
`;

export default function BoulderCard({ htmlId, boulder, highlight }: BoulderCardProps) {
  const history = useHistory();
  const { data: imageSource } = useQuery(['boulderImage', boulder.id], async () => {
    const topos = await getTopos(boulder.id);
    if (topos.length < 1) {
      return '';
    }

    // The most appropriate topo to use for a preview image is likely to be
    // that which has the most topo lines
    const topoWithMostLines = topos.reduce(
      (topo, currentTopo) => topo.topoLines.length > currentTopo.topoLines.length ? topo : currentTopo
    );

    const files = await listAll(ref(ImageStorage, `${topoWithMostLines.imageId}`))
    let smallFile = files.items.find(image => image.name.includes('small'));
    if (smallFile) {
      return await getDownloadURL(smallFile)
    } else {
      return '';
    }
  });

  const navigate = useCallback(() => {
    history.push(`/guidebook/boulder?id=${boulder.id}`)
  }, [boulder.id, history]);

  return (
    <Card className={highlight ? 'bg-secondary' : ''} id={htmlId} onClick={navigate} style={{maxWidth: '500px', cursor: 'pointer'}}>
      <Card.Img src={imageSource} variant="top" style={{objectFit: 'contain'}} />
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>
              <Header>{boulder.name}</Header>
            </Col>
            {boulder.private &&
            <Col xs="auto">
              <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
            </Col>
            }
          </Row>
        </Card.Title>
        <Row>
          <Col sm={true}>
            <LargeCount>
              <ProblemCount ancestryPath={`${boulder.id}`}/>
            </LargeCount>
            <CountHeader>Problems</CountHeader>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

