import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import {
  BsPencil,
  BsQuestionCircle,
  BsSortUp,
  BsTrash,
  BsUpload,
} from 'react-icons/bs';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { PropagateLoader } from 'react-spinners'
import { getDownloadURL, ref } from 'firebase/storage';

import * as Database from '../database';
import {
  deleteArea,
  updateArea
} from '../database';
import UserContext from '../context/UserContext';
import { Note }from '../model/Note';
import Area from '../model/Area';
import { Title, Subtitle, Text } from './ThemedComponents';
import SectorCard from './SectorCard';
import AreaModal from './AreaModal';
import SectorModal from './SectorModal';
import NewProblemCollectionModal from './NewProblemCollectionModal';
import DeleteAreaModal from './DeleteAreaModal';
import ResponsiveCardDeck from './ResponsiveCardDeck';
import RequestsModal from './RequestsModal';
import FeedbackModal from './FeedbackModal';
import SectionContainer from './ContentSectionContainer';
import UploadButton from './UploadButton';
import CustomIcon from '../Icon';
import ReorderSectorsModal from './ReorderSectorsModal';
import NoteAccordion from './NoteAccordion';
import { MapStorage } from '../Firebase';

interface Props {
  area: Area;
}

export default function AreaContent({ area }: Props) {
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState<string>('');
  const history = useHistory();

  const [showMapDelete, setShowMapDelete] = useState(false);
  const [showAreaModal, setShowAreaModal] = useState(false);
  const [showSectorModal, setShowSectorModal] = useState(false);
  const [showReorderSectorsModal, setShowReorderSectorsModal] = useState(false);
  const [showNewProblemCollectionModal, setShowNewProblemCollectionModal] = useState(false);
  const [showDeleteAreaModal, setShowDeleteAreaModal] = useState(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalRegards, setFeedbackModalRegards] = useState('');

  const { mutate: addMap } = useMutation(async (path: string) => {
    await updateArea(area.id, {map: path});
  });

  const { mutate: removeMap } = useMutation(async () => {
    await updateArea(area.id, {map: null});
  });

  const fetchSectors = useCallback(async () => {
    return Database.getSectors(`areas/${area.id}` ?? null, user?.hasElevatedReadAccess());
  }, [user, area.id]);

  const fetchReqs = useCallback(async () => {
    return Database.getInfoRequests(`areas/${area.id}`);
  }, [area.id]);

  const { isLoading: isLoadingSectors, data: sectors } = useQuery(['sectors', area.id, user], fetchSectors);
  const { data: reqs } = useQuery(['reqs', area.id], fetchReqs);
  const { data: mapSource } = useQuery(['map', area.map], async () => area.map ? getDownloadURL(ref(MapStorage, area.map)) : null );

  const setHighlightViaHash = useCallback(() => {
    const hash = window.location.hash;
    if (hash.split('-').length > 1) {
      setHighlight(hash.split('-')[1]);
    }
  }, [])

  // highlight on hash change
  useEffect(() => {
    // init
    setHighlightViaHash();
    window.addEventListener('hashchange', setHighlightViaHash, false)
    return (() => window.removeEventListener('hashchange', setHighlightViaHash, false));
  }, [setHighlightViaHash])

  // get sectors
  useEffect(() => {
    if (area && area.id) {
      return Database.subscribeToSectors(`areas/${area.id}`, () => {
        queryClient.invalidateQueries(['sectors', area.id]);
      }, (error) => {
        console.log('There was an error subscribing to sectors:', error);
      }, undefined, user?.hasElevatedReadAccess());
    }
  }, [area, user, queryClient]);

  const handleRequestModalSubmitInfo = useCallback((note: Note) => {
    setShowRequestsModal(false);
    setFeedbackModalRegards(`note ${note.id}`);
    setShowFeedbackModal(true);
  }, [])

  const handleShowAreaModal = useCallback(() => {
    setShowAreaModal(true);
  }, []);

  const handleCloseAreaModal = useCallback(() => {
    setShowAreaModal(false);
  }, []);

  const handleShowSectorModal = useCallback(() => {
    setShowSectorModal(true);
  }, []);

  const handleCloseSectorModal = useCallback(() => {
    setShowSectorModal(false);
  }, []);

  const handleShowReorderSectorsModal = useCallback(() => {
    setShowReorderSectorsModal(true);
  }, []);

  const handleCloseReorderSectorsModal = useCallback(() => {
    setShowReorderSectorsModal(false);
  }, []);

  const handleMapUpload = useCallback((path: string) => {
    addMap(path);
  }, [addMap]);

  const handleDeleteMap = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete the map?')) {
      removeMap();
    }
  }, [removeMap]);

  const handleDeleteArea = useCallback(async () => {
    if (window.confirm(`Are you sure you want to delete ${area.name}? This cannot be undone and will leave orphans.`)) {
      await deleteArea(area.id);
      history.push('/guidebook');
    }
  }, [area.id, area.name, history]);

  return (
    <>
      <Row>
        <Col>
        { user?.hasWriteAccess() &&
          <ButtonToolbar>
            <ButtonGroup>
              <Button variant="primary" onClick={handleShowAreaModal}><BsPencil width="1em" height="1em" /></Button>
              <Button variant="primary" onClick={handleShowSectorModal}><CustomIcon.AddArea width="1em" height="1em" /></Button>
              <Button variant="primary" onClick={handleShowReorderSectorsModal}><BsSortUp width="1em" height="1em" /></Button>
              <Button variant="danger" onClick={handleDeleteArea}><BsTrash width="1em" height="1em" /></Button>
            </ButtonGroup>
          </ButtonToolbar>
        }
        </Col>
        <Col>
          <ButtonToolbar className="justify-content-end">
            <ButtonGroup>
              {reqs !== undefined && reqs.length > 0 &&
              <Button
                onClick={() => setShowRequestsModal(true)}
              >
                <BsQuestionCircle />
                {" "}
                <Badge className="text-dark rounded-pill" bg="light">{reqs?.length}</Badge>
              </Button>
              }
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>

      <SectionContainer>
        <Row>
          <Col xs="auto">
            <Title>{area ? area.name : ''}</Title>
          </Col>
          {area.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
          </Col>
          }
        </Row>
        <Row className="d-flex flex-wrap">
          <Col md="6" sm="12" className="flex-grow-1">
            <Row>
              <Col>
                <Subtitle id="description">Description</Subtitle>
                <Text>{area.description}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Subtitle id="directions">Directions</Subtitle>
                <Text>{area.directions}</Text>
              </Col>
            </Row>
          </Col>
          { (!area.map && user?.isAdmin()) &&
          <Col md="6" sm="12" className="flex-grow-1">
            <UploadButton
              storage={MapStorage}
              path={'temp'}
              className="w-100 h-100"
              accept=".svg"
              onUpload={handleMapUpload}
            >
              <div>
                <BsUpload size="1.5em"/>
              </div>
              Upload Map
            </UploadButton>
          </Col>
          }
          { area.map &&
          <Col md="6" sm="12" className="flex-grow-1" style={{position: 'relative'}}
            onMouseEnter={() => setShowMapDelete(true)}
            onMouseLeave={() => setShowMapDelete(false)}
          >
            {/* TODO On-click expand */}
            <Image
              style={{background: 'white', borderRadius: '0.5em'}}
              fluid
              src={mapSource ?? ''}
            />
            {showMapDelete &&
            <Button
              variant="danger"
              style={{position: 'absolute', right: '1em', top: '0.5em'}}
            >
              <BsTrash width="1em" height="1em" onClick={handleDeleteMap}/>
            </Button>
            }
          </Col>
          }
        </Row>
      </SectionContainer>

      <SectionContainer>
        <Row>
          <Col>
            { isLoadingSectors &&
              <Row>
                <Col />
                <Col xs="auto">
                  <PropagateLoader size={15} color='#c28465'/>
                </Col>
                <Col />
              </Row>
            }
            {sectors &&
              <ResponsiveCardDeck>
                <>
                {sectors.map((sector, index) => {
                  return <SectorCard highlight={highlight === sector.id} htmlId={`sector-${sector.id}`} key={`sector-${index}`} sector={sector}/>
                })}
                </>
              </ResponsiveCardDeck>
            }
          </Col>
        </Row>
      </SectionContainer>

      { user?.hasElevatedReadAccess &&
      <NoteAccordion
        parent={`areas/${area.id}`}
      />
      }

      {/* Modals */}
      {user?.hasWriteAccess() &&
        <>
          <NewProblemCollectionModal
            show={showNewProblemCollectionModal}
            onHide={() => setShowNewProblemCollectionModal(false)}
            parentArea={area.id}
          />
          <DeleteAreaModal
            show={showDeleteAreaModal}
            onHide={() => setShowDeleteAreaModal(false)}
            area={area}
          />
          <RequestsModal
            notes={reqs ?? []}
            show={showRequestsModal}
            onHide={() => setShowRequestsModal(false)}
            onSubmitInfo={handleRequestModalSubmitInfo}
          />
          <FeedbackModal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            withRegards={feedbackModalRegards}
          />
          <AreaModal
            show={showAreaModal}
            onHide={handleCloseAreaModal}
            area={area}
          />
          <SectorModal
            show={showSectorModal}
            onHide={handleCloseSectorModal}
            parent={`areas/${area.id}`}
          />
          {sectors &&
            <ReorderSectorsModal
              show={showReorderSectorsModal}
              onHide={handleCloseReorderSectorsModal}
              sectors={sectors}
            />
          }
        </>
      }
    </>
  );
}

