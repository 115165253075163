import React, { useState, useContext } from 'react';

import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BsFullscreen } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';

import Loading from './components/Loading';
import UserContext from './context/UserContext';
import FeedbackMonitor from './components/AdminComponents/FeedbackMonitor';
import UserViewToggle from './components/AdminComponents/UserViewToggle';


export default function Admin() {
  const { user } = useContext(UserContext);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  if (user === undefined) {
    return (
      <Loading />
    );
  } else if (!(user?.hasGuideAccess())) {
    return (
      <Redirect
        to="/permission"
      />
    );
  }

  // cards with admin components, expand button in header opens content in large modal
  return (
    <Container className="mt-3">
      <Row xs={1} sm={2} md={4} className="g-4">
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  <h4>Feedback</h4>
                </Col>
                <Col xs="auto">
                  <Button variant="outline-primary" onClick={() => setShowFeedbackModal(true)}><BsFullscreen /></Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <FeedbackMonitor />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  <h4>User View</h4>
                </Col>
                <Col xs="auto">
                  <UserViewToggle />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              Not implemented
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Modals */}
      <>
        <Modal
          show={showFeedbackModal}
          onHide={() => setShowFeedbackModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FeedbackMonitor />
          </Modal.Body>
        </Modal>
      </>
    </Container>
  );
}

