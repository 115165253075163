import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface MenubarProps {
  /* events */
  onReset: () => void;
  onSave: () => void;
  onClose: () => void;
  onShowAllTopoLines: () => void;
  onShowLabels: () => void;
  onPointSnapping: () => void;

  /* state */
  showAllTopoLines: boolean;
  showLabels: boolean;
  pointSnapping: boolean;
};

export default function Menubar(props: MenubarProps) {
  return (
    <ButtonGroup>
      <DropdownButton as={ButtonGroup} title="File">
        <Dropdown.Item onClick={props.onReset}>Reset</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={props.onSave}>Save and Close</Dropdown.Item>
        <Dropdown.Item onClick={props.onClose}>Close without saving</Dropdown.Item>
      </DropdownButton>
      <DropdownButton as={ButtonGroup} title="Edit">
        <Dropdown.Item
          onClick={props.onPointSnapping}
          active={props.pointSnapping}
        >
          Point Snapping
        </Dropdown.Item>
      </DropdownButton>
      <DropdownButton as={ButtonGroup} title="View">
        <Dropdown.Item
          onClick={props.onShowAllTopoLines}
          active={props.showAllTopoLines}
        >
          Show all topo lines
        </Dropdown.Item>
        <Dropdown.Item
          onClick={props.onShowLabels}
          active={props.showLabels}
        >
          Show labels
        </Dropdown.Item>
      </DropdownButton>
    </ButtonGroup>
  );
}

