import React, { useState, useContext, useCallback, ChangeEvent } from 'react';

import Container from 'react-bootstrap/Container';
import { v4 as uuidv4 } from 'uuid';
import Button from 'react-bootstrap/Button';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptchaSiteKey from './utils/reCaptchaSiteKey';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FormCheck from 'react-bootstrap/FormCheck';
import { useQuery, useInfiniteQuery } from 'react-query';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { saveAs } from 'file-saver';
import {
  ref,
  listAll,
  getDownloadURL,
} from 'firebase/storage';

import { FirestoreV8Compat, ImageStorage, Functions } from './Firebase';
import UserContext from './context/UserContext';
import ContributeImageModal from './components/ContributeImageModal';
import LoadingSpinner from './components/Loading';
import { getPhotoContributions } from './database';
import PhotoContribution from './model/PhotoContribution';

export default function Contributions() {
  const [unread, setUnread] = useState(true);
  const [showContributeImageModal, setShowContributeImageModal] = useState(false);
  const { user } = useContext(UserContext);
  const { isLoading: contributionsLoading, data: contributions, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ['contributions', unread],
    async ({ pageParam = null }) => await getPhotoContributions(unread, pageParam),
    {
      getNextPageParam: (contributions => contributions.length ? contributions[contributions.length-1].timestamp : undefined)
    }
  );

  const handleReadContributionsSwitch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUnread(e.target.checked);
  }, []);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={ReCaptchaSiteKey}>
      <div className="pt-4 pb-4" style={{backgroundColor: "#e9ecef"}}>
        <Container>
          <h1>
            Contribute to the guidebook
          </h1>
          <p>
            Help make the guidebook better by contributing photos or information.
          </p>
        </Container>
      </div>
      <div className="pt-4 pb-4" style={{backgroundColor: "white"}}>
        <Container>
          <h1>
            Images
          </h1>
          <p>
            Provide your royalty-free images to the guidebook.
          </p>
          <Button onClick={() => setShowContributeImageModal(true)}>Submit Image</Button>
        </Container>
      </div>
      {user?.role === 'admin' &&
      <div className="pt-4 pb-4" style={{backgroundColor: "#e9ecef"}}>
        <Container>
          <Row>
            <Col xs="auto">
              <h1>
                Review Contributions
              </h1>
            </Col>
            <Col/>
            <Col xs="auto">
              <FormCheck
                id={uuidv4()}
                type="switch"
                label="Unread"
                defaultChecked={true}
                onChange={handleReadContributionsSwitch}
              />
            </Col>
          </Row>
          <Row>
            <Container fluid>
            {contributionsLoading ?
              <Row>
                <Col />
                  <Col xs="auto">
                    <LoadingSpinner />
                  </Col>
                <Col />
              </Row>
            : contributions ?
              <Row className="flex-nowrap " style={{overflowX: 'auto', backgroundColor: 'white'}}>
              {contributions.pages.map(page => (
                <>
                {page.map(c => (
                  <Col key={c.id} className="mt-3 mb-3" xs="12" sm="6" md="4"> {/* May need to change colspan on different sized devices*/}
                    <AdminContributionCard
                      contribution={c}
                      onUpdate={refetch}
                    />
                  </Col>
                ))}
                </>
              ))}
              </Row>
            :
              <Row>
                <Col>
                  {'There seems to be an issue...'}
                </Col>
              </Row>
            }
              <Row className="mt-3">
                <Col>
                  <Button
                    onClick={handleLoadMore}
                    disabled={!hasNextPage}
                  >
                    Load More
                  </Button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
      }
      <>
        <ContributeImageModal
          show={showContributeImageModal}
          onHide={() => setShowContributeImageModal(false)}
        />
      </>
    </GoogleReCaptchaProvider>
  );
}


interface AdminContributionCardProps {
  contribution: PhotoContribution;
  onUpdate?: () => void;
}

function AdminContributionCard({contribution, onUpdate}: AdminContributionCardProps) {
  const [read, setRead] = useState(contribution.read);
  const { data: previewDownloadUrl } = useQuery(
    ['contributionImageMedium', contribution.id],
    async () => {
      try {
        const children = await listAll(ref(ImageStorage, `${contribution.pid}`));
        let preview = children.items.find(child => child.name.includes('medium'));
        if (!preview && children.items.length > 0) {
          preview = children.items[0];
        }
        if (preview) {
          return await getDownloadURL(preview);
        }
      } catch(err) {
        console.log(`There was an error getting download url for image ${contribution.id}`, err);
        return undefined;
      }
    }
  );

  const { data: fullDownloadUrl } = useQuery(
    ['contributionImageOriginal', contribution.id],
    async () => {
      try {
        const children = await listAll(ref(ImageStorage, `${contribution.pid}`));
        const original = children.items.find(child => child.name.includes('original'));
        if (original) {
          return await getDownloadURL(original);
        }
      } catch(err) {
        console.log(`There was an error getting download url for image ${contribution.id}`, err);
        return undefined;
      }
    }
  );

  const { data: contributor } = useQuery(
    ['contributor', contribution.id],
    async () => {
      if ((contribution as any).uid) {
        const getUser = Functions.httpsCallable('AdminTools-getUser');
        try {
          const result = await getUser({uid: (contribution as any).uid});
          return result.data.email ?? '<Unknown User>';
        } catch (e) {
          console.error(e);
          return ((contribution as any).uid) ?? 'Bad state';
        }
      } else {
        return (contribution as any).contributor ?? 'Bad state';
      }
    }
  );

  const toggleRead = useCallback(() => {
    setRead(!read);
    FirestoreV8Compat.collection('PhotoContributions').doc(contribution.id).update({read: !read})
    .then(() => {
      if (onUpdate) {
        onUpdate();
      }
    }).catch((err) => {
      console.log(`Error updating document ${contribution.id}`, err);
      if (onUpdate) {
        onUpdate();
      }
    });
  }, [read, contribution.id, onUpdate]);

  const startDownload = useCallback(() => {
    if (fullDownloadUrl) {
      saveAs(fullDownloadUrl);
    } else {
      console.error('Error: Attempting to save an falsey download uri.');
    }
  }, [fullDownloadUrl]);

  const handleDelete = useCallback(() => {
    const confirmed = window.confirm('Are you sure? This cannot be undone');
    if (confirmed) {
      FirestoreV8Compat.collection('PhotoContributions').doc(contribution.id).delete()
      .then(() => {
        console.log(`Deleted document ${contribution.id}`)
        if (onUpdate) {
          onUpdate();
        }
      }).catch((err) => {
        console.log(`Error deleting document ${contribution.id}`, err);
        if (onUpdate) {
          onUpdate();
        }
      });
    }
  }, [contribution.id, onUpdate]);

  return (
    <Card>
      <div style={{position: 'absolute', top: '0.5rem', right: '0.5rem'}}>
        <Dropdown>
          <Dropdown.Toggle variant="text" id="dropdown-basic">
            <BsThreeDotsVertical />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={toggleRead}>Mark as {read ? 'unread' : 'read'}</Dropdown.Item>
            <Dropdown.Item onClick={startDownload}>Download</Dropdown.Item>
            <Dropdown.Item style={{color: 'red'}} onClick={handleDelete}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Card.Img
        variant="top"
        src={previewDownloadUrl}
        style={{objectFit: 'contain'}}
      />
      <Card.Body>
        <p>
          <b>Description</b>: {contribution.description}
        </p>
        <p>
          <b>Photographer</b>: {contribution.photographer}
        </p>
        <p>
          <b>Contributor</b>: {contributor}
        </p>
      </Card.Body>
      <Card.Footer>
        <Container>
          <Row>
            <Col/>
            <Col xs="auto">
              {!read && <span className="mr-2" style={{color: 'blue'}}>{'\u2022'}</span>}
              <span>{new Date(contribution.timestamp * 1000).toDateString()}</span>
            </Col>
          </Row>
        </Container>
      </Card.Footer>
    </Card>
  );
}

