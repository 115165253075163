import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  limit,
  startAfter
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { PhotoContributionConverter } from '../model/PhotoContribution';


export async function getPhotoContributions(onlyUnread: boolean=false, beforeEpoch:(number|null)=null) {
  const colRef = collection(Firestore, 'PhotoContributions').withConverter(PhotoContributionConverter);
  let q = query(colRef, orderBy('timestamp', 'desc'), limit(5));

  if (onlyUnread) {
    q = query(q, where('read', '==', false));
  }

  if (beforeEpoch) {
    q = query(q, startAfter(beforeEpoch));
  }

  return (await getDocs(q)).docs.map(doc => doc.data());
}

