import {
  collection,
  query,
  where,
  orderBy,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { regionConverter } from '../model/Region';

export async function getRegions(
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'regions').withConverter(regionConverter);
  let q = query(colRef);

  if (!isPrivileged) {
    q = query(q, where('public', '==', true));
  }

  q = query(q, orderBy('order'));

  return (await getDocs(q)).docs.map(doc => doc.data());
}

