import {
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { topoConverter, Topo } from '../model/Topo';

export async function getTopos(
  parentId: string | null
): Promise<Topo[]> {
  const colRef = collection(Firestore, 'topos').withConverter(topoConverter);
  const q = query(colRef, where('boulderId', '==', parentId));
  return (await getDocs(q)).docs.map(doc => doc.data());
}

