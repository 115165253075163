import React, { useState, useCallback, useContext, ChangeEvent, MouseEvent } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import Image from 'react-bootstrap/Image';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  ref,
  uploadBytes
} from 'firebase/storage';

import UserContext from '../context/UserContext';
import { Storage } from '../Firebase';
import { createPhotoContribution } from '../database/index';
import Loading from './Loading';


interface ContributeImageModalProps {
  show: boolean;
  onHide: () =>  void;
}

export default function ContributeImageModal({show, onHide}: ContributeImageModalProps) {
  const { user } = useContext(UserContext);
  const [submitContributionMutateKey] = useState('submitContributionMutateKey');
  const [image, setImage] = useState<File|null>(null);
  const [isOwnedByUser, setIsOwnedByUser] = useState(false);
  const [photographer, setPhotographer] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [contributor, setContributor] = useState("");
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const submitContribution = useMutation(submitContributionMutateKey,
    async ({ 
      file,
      photographer,
      description,
      uid,
      contributor
    }: {
      file: File;
      photographer: string;
      description: string;
      uid: string | null;
      contributor: string | null;
    }) => {

      if (!uid && !contributor) {
        throw Error('Must be logged in or supply contributor.');
      }

      const id = uuidv4();
      const ext = file.name.split('.').pop();
      const timestamp = Math.floor(Date.now() / 1000)

      // upload image to storage bucket
      const newImageRef = ref(Storage, `uploaded_images/${id}.${ext}`);

      try {
        await uploadBytes(newImageRef, file);
      } catch {
        throw Error('Failed to upload image.');
      }

      try {
        if (uid) {
          createPhotoContribution({
            uid: uid,
            photographer: photographer,
            pid: id,
            description: description,
            read: false,
            timestamp: timestamp
          });
        } else {
          createPhotoContribution({
            contributor: contributor ?? '',
            photographer: photographer,
            pid: id,
            description: description,
            read: false,
            timestamp: timestamp
          });
        }
      } catch {
        throw Error('Failed to submit contribution.');
      }
  });

  const handleSubmit = useCallback(async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (!(image && isOwnedByUser && photographer && captchaToken)) {
      return;
    }

    try {
      if (user) {
        await submitContribution.mutateAsync({
          file: image,
          photographer: photographer,
          uid: user.firebaseUser.uid,
          description: description,
          contributor: null
        });
      } else {
        await submitContribution.mutateAsync({
          file: image,
          photographer: photographer,
          uid: null,
          description: description,
          contributor: contributor
        });
      }
    } catch {
      return;
    }

    setImage(null);
    setIsOwnedByUser(false);
    setPhotographer('');
  }, [image, isOwnedByUser, photographer, captchaToken, submitContribution, description, user, contributor])

  const handleCancel = useCallback(async () => {
    setImage(null);
    setIsOwnedByUser(false);
    setPhotographer('');
    await queryClient.cancelQueries(submitContributionMutateKey);
    onHide();
  }, [onHide, queryClient, submitContributionMutateKey]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          Submit Image
        </Modal.Header>
        <Modal.Body>
          {submitContribution.isError &&
          <Alert variant="danger">
            <Alert.Heading>
              Error
            </Alert.Heading>
            <p>{(submitContribution?.error as Error)?.message ?? ''}</p>
          </Alert>
          }
          {submitContribution.status === 'success' &&
            <Alert variant="success">
              <Alert.Heading>
                Success
              </Alert.Heading>
              <p>Successfully submitted image. Thank you for your contribution.</p>
            </Alert>
          }
          { submitContribution.status === 'loading' ?
            <Loading />
            :
            <Container>
              {image &&
              <Row>
                <Col>
                  <p>
                    <Image src={URL.createObjectURL(image)} fluid/>
                  </p>
                </Col>
              </Row>
              }
              <Row>
                <Col>
                  <p>
                    <FormControl
                      // image should exist
                      id={uuidv4()}
                      type="file"
                      accept={"image/*"}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setImage(e.target?.files ? e.target.files[0] : null)}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <FormCheck
                      id={uuidv4()}
                      type='checkbox'
                      label='I certify I own the rights to this image and allow bhbouldering.com to utilize it.'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setIsOwnedByUser(e.target.checked)}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <FormControl
                      type='text'
                      placeholder='Photographer'
                      onChange={e => setPhotographer(e.target.value)}
                    />
                  </p>
                </Col>
              </Row>
              {!user && // no firebase user
              <Row>
                <Col>
                  <p>
                    <FormControl
                      type='text'
                      placeholder='Contributor'
                      onChange={e => setContributor(e.target.value)}
                    />
                  </p>
                </Col>
              </Row>
              }
              <Row>
                <Col>
                  <p>
                    <FormControl
                      type='text'
                      placeholder='Description'
                      onChange={e => setDescription(e.target.value)}
                    />
                  </p>
                </Col>
              </Row>
            </Container>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>{submitContribution.status === 'loading' ? 'Cancel' : 'Close'}</Button>
          {submitContribution.status !== 'loading' &&
          <Button
            variant="primary"
            disabled={!(image && isOwnedByUser && photographer && captchaToken && (user || contributor)) ||
              submitContribution.isLoading
            }
            onClick={handleSubmit}
          >Submit
          </Button>}
        </Modal.Footer>
      </Modal>
      <GoogleReCaptcha onVerify={setCaptchaToken} />
    </>
  );
}

