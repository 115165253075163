import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { BsSortUp } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import UserContext from '../context/UserContext';
import * as Database from '../database';
import RegionCard from './RegionCard';
import RegionModal from './RegionModal';
import ReorderRegionsModal from './ReorderRegionsModal';
import CustomIcon from '../Icon';
import ResponsiveCardDeck from './ResponsiveCardDeck';
import { Title } from './ThemedComponents';
import Loading from './Loading';


export default function HomeContent() {
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showReorderRegionsModal, setShowReorderRegionsModal] = useState(false);
  const fetchRegions = useCallback(async () => {
    return Database.getRegions(user?.hasElevatedReadAccess());
  }, [user]);

  const { isLoading, data: regions } = useQuery(['regions', null, user], fetchRegions);

  useEffect(() => {
    return Database.subscribeToRegions(() => {
      queryClient.invalidateQueries(['regions', null]);
    }, (error) => {
      console.log('There was an error subscribing to regions:', error);
    }, undefined, user?.hasElevatedReadAccess());
  }, [user, queryClient]);
  
  const handleShowRegionModal = useCallback(() => {
    setShowRegionModal(true);
  }, []);

  const handleCloseRegionModal = useCallback(() => {
    setShowRegionModal(false);
  }, []);

  const handleShowReorderRegionsModal = useCallback(() => {
    setShowReorderRegionsModal(true);
  }, []);

  const handleCloseReorderRegionsModal = useCallback(() => {
    setShowReorderRegionsModal(false);
  }, []);

  return (
    <>
      {user?.hasWriteAccess() &&
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" onClick={handleShowRegionModal}><CustomIcon.AddArea width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowReorderRegionsModal}><BsSortUp width="1em" height="1em" /></Button>
          </ButtonGroup>
        </ButtonToolbar>
      }

      <Title>Guidebook Home</Title>

      { isLoading &&
      <Loading />
      }

      { regions &&
        <ResponsiveCardDeck>
          { regions.map(region => <RegionCard key={region.id} region={region} /> )}
        </ResponsiveCardDeck>
      }

      {/* Modals */}
      <>
        <RegionModal
          show={showRegionModal}
          onHide={handleCloseRegionModal}
        />
        {regions &&
          <ReorderRegionsModal
            show={showReorderRegionsModal}
            onHide={handleCloseReorderRegionsModal}
            regions={regions}
          />
        }
      </>

    </>
  );
}

