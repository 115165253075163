import {
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { NoteConverter } from '../model/Note';

export async function getInfoRequests(
  parentPath: string
) {
  const colRef = collection(Firestore, 'notes').withConverter(NoteConverter);
  const q = query(colRef, where('parent', '==', parentPath), where('classification', '==', 'REQ'))

  return (await getDocs(q)).docs.map(doc => doc.data());
}

