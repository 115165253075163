import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import deepEqual from 'deep-equal';
import { BsArrowCounterclockwise, BsTrash } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import deepClone from 'lodash/cloneDeep';

import type { Topo } from '../../model/Topo';
import TopoLine from '../../model/TopoLine';
import Problem from '../../model/Problem';

interface ControlPanelProps {
  topo: Topo;
  setTopo: (_: Topo) => void;
  problems: Problem[];
  selectedProblemId?: string;
  setSelectedProblemId: (_: string) => void;
};

export default function ControlPanel({topo, setTopo, ...props}: ControlPanelProps) {
  const [originalTopo] = useState(topo);
  const [nSegments, setNSegments] = useState<number>();
  const [selectedLine, setSelectedLine] = useState<TopoLine>();

  const captionRef = React.createRef<HTMLInputElement>();
  const labelSegRef = React.createRef<HTMLInputElement>();
  const labelPosRef = React.createRef<HTMLInputElement>();

  // get the selected line and segment size
  useEffect(() => {
    const topoLine = topo.topoLines.find(line => line.problemId === props.selectedProblemId);
    if (topoLine) {
      const n = topoLine.bezier.points.length;
      setSelectedLine(topoLine);
      setNSegments(n-2);
    } else {
      setSelectedLine(undefined);
      setNSegments(undefined);
    }
  }, [topo, props.selectedProblemId]);

  // set value of caption
  useEffect(() => {
    const captionInput = captionRef.current!;
    captionInput.value = topo.caption;
  }, [captionRef, topo]);

  // set value of label segment input
  useEffect(() => {
    if (selectedLine) {
      const labelSegInput = labelSegRef.current!;
      if (selectedLine.labelIndex) {
        labelSegInput.value = selectedLine.labelIndex.toString();
      } else {
        labelSegInput.value = '0';
      }
    }
  }, [labelSegRef, selectedLine]);

  // set value of label position input
  useEffect(() => {
    if (selectedLine) {
      const labelPosInput = labelPosRef.current!;
      if (selectedLine.labelPosition !== undefined) {
        labelPosInput.value = selectedLine.labelPosition.toString();
      } else {
        labelPosInput.value = '50';
      }
    }
  }, [labelPosRef, selectedLine]);

  const sanitizeLines = useCallback(() => {
    const newTopo = deepClone(topo);
    let topoLines = [...newTopo.topoLines];
    topoLines = topoLines.filter(line => props.problems.find(problem => problem.id === line.problemId));
    newTopo.topoLines = topoLines;
    setTopo(newTopo);
  }, [topo, setTopo, props.problems]);

  const onProblemChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setSelectedProblemId(e.target.id);
  };

  const onCaptionChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== originalTopo.caption) {
      const newTopo = deepClone(topo);
      newTopo.caption = e.target.value;
      setTopo(newTopo);
    }
  };

  const onPrivateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    const newTopo = deepClone(topo);
    newTopo.private = checked
    setTopo(newTopo);
  }, [setTopo, topo]);

  const onLabelSegChange = (e: ChangeEvent<HTMLInputElement>) => {
    const topoLine = topo.topoLines.find(line => line.problemId === props.selectedProblemId);
    const lineIndex = topo.topoLines.findIndex(line => line.problemId === props.selectedProblemId);
    if  (topoLine && +e.target.value !== topoLine.labelIndex) {
      const newTopo = deepClone(topo);
      newTopo.topoLines[lineIndex].labelIndex = +e.target.value;
      setTopo(newTopo);
    }
  }

  const onLabelPosChange = (e: ChangeEvent<HTMLInputElement>) => {
    const topoLine = topo.topoLines.find(line => line.problemId === props.selectedProblemId);
    const lineIndex = topo.topoLines.findIndex(line => line.problemId === props.selectedProblemId);
    if (topoLine && +e.target.value !== topoLine.labelPosition) {
      const newTopo = deepClone(topo);
      newTopo.topoLines[lineIndex].labelPosition = +e.target.value;
      setTopo(newTopo);
    }
  }

  const resetCaption = () => {
    const newTopo = deepClone(topo);
    newTopo.caption = originalTopo.caption;
    setTopo(newTopo);
  }

  const topoLineChanged = (problemId: string) => {
    const orig = originalTopo.topoLines.find(line => line.problemId === problemId);
    const newLine = topo.topoLines.find(line => line.problemId === problemId);

    return !deepEqual(newLine, orig) && newLine;
  }

  const resetLine = (problemId: string) => {
    const newTopo: Topo = {
      id: topo.id,
      boulderId: topo.boulderId,
      imageId: topo.imageId,
      caption: topo.caption,
      topoLines: [],
      private: topo.private
    };

    // reset current line
    // todo reset line that was deleted. This would require two iterators through the topo lists. Kind gross.
    newTopo.topoLines = topo.topoLines.map(line => {
      if (line.problemId !== problemId)  {
        return line;
      }
      return originalTopo.topoLines.find(line => line.problemId === problemId);
    }).filter(topo => topo ? true : false) as TopoLine[];

    setTopo(newTopo);
  }

  const deleteLine = (problemId: string) => {
    const newTopo: Topo = {
      id: topo.id,
      boulderId: topo.boulderId,
      imageId: topo.imageId,
      caption: topo.caption,
      topoLines: [],
      private: topo.private
    };

    // delete the line
    newTopo.topoLines = topo.topoLines.filter(line => line.problemId !== problemId);

    setTopo(newTopo);
  }

  return (
    <Accordion alwaysOpen>
      <Accordion.Item eventKey="topo_lines">
        <Accordion.Header>
          Topo Lines
        </Accordion.Header>
        <Accordion.Body>
          <fieldset>
            <Form.Group>
              {props.problems.map(problem => {
                return (
                  <InputGroup>
                    <InputGroup.Radio
                      defaultChecked={props.selectedProblemId === problem.id}
                      name="problems"
                      id={problem.id}
                      type="radio"
                      onChange={onProblemChange}
                    />
                    <Form.Control value={problem.name} disabled={true} />
                      <Button
                        variant="warning"
                        disabled={!topoLineChanged(problem.id ?? '')}
                        onClick={() => resetLine(problem.id ?? '')}
                        >
                        <BsArrowCounterclockwise/>
                      </Button>
                      <Button
                        variant="danger"
                        disabled={!topo.topoLines.find(line => line.problemId === problem.id)}
                        onClick={() => deleteLine(problem.id ?? '')}
                        >
                        <BsTrash/>
                      </Button>
                  </InputGroup>
                );
              })}
            </Form.Group>
            <Form.Group>
              <div className="d-grip gap-2">
                <Button onClick={sanitizeLines}>Sanitize Lines</Button>
              </div>
            </Form.Group>
          </fieldset>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="topo_properties">
        <Accordion.Header>
          Topo Line Properties
        </Accordion.Header>
        <Accordion.Body>
          <Form.Group>
            <Form.Label>Label Segment</Form.Label>
            <InputGroup>
              <Form.Control
                ref={labelSegRef}
                type="range"
                min={0}
                max={nSegments ?? 0}
                disabled={nSegments === undefined}
                onChange={onLabelSegChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>Label Position</Form.Label>
            <InputGroup>
              <Form.Control
                ref={labelPosRef}
                type="range"
                min={0}
                max={100}
                disabled={selectedLine === undefined}
                onChange={onLabelPosChange}
              />
            </InputGroup>
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="topo_caption">
        <Accordion.Header>
            Topo Properties
        </Accordion.Header>
        <Accordion.Body>
          <Form.Group>
            <Form.Label>Private</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={topo.private}
              label="Private"
              onChange={onPrivateChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Caption</Form.Label>
            <InputGroup>
              <Form.Control
                defaultValue={topo.caption}
                ref={captionRef}
                type="text"
                placeholder="e.g. North face"
                onBlur={onCaptionChange}
              />
              <Button variant="warning" onClick={resetCaption}><BsArrowCounterclockwise/></Button>
            </InputGroup>
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
