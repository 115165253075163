import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory }from 'react-router-dom';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Header, Text } from './ThemedComponents';
import Area from '../model/Area';
import ProblemCount from './ProblemCount';
import BoulderCount from './BoulderCount';
import AreaCount from './AreaCount';

interface AreaCardProps {
  htmlId?: string;
  area: Area;
  highlight?: boolean;
}

const LargeCount = styled(Text)`
  font-size: large;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
`;

const CountHeader = styled(Text)`
  font-size: x-small;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
`;

export default function AreaCard({ htmlId, area, highlight }: AreaCardProps) {
  const history = useHistory();

  const navigate = useCallback(() => {
    history.push(`/guidebook/area?id=${area.id}`)
  }, [area.id, history]);

  return (
    <Card className={highlight ? 'bg-secondary' : ''} id={htmlId} onClick={navigate} style={{maxWidth: '500px', cursor: 'pointer'}}>
      <Card.Img variant="top" />
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>
              <Header>{area.name}</Header>
            </Col>
            {area.private &&
            <Col xs="auto">
              <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
            </Col>
            }
          </Row>
        </Card.Title>
        <Row>
          {/* TODO: Don't render this if the area is 0... Either move ownership
          of count components or move the count query to a function */}
          <Col sm={true}>
            <LargeCount>
              <AreaCount ancestryPath={`${area.id}`}/>
            </LargeCount>
            <CountHeader>Areas</CountHeader>
          </Col>
          <Col sm={true}>
            <LargeCount>
              <BoulderCount ancestryPath={`${area.id}`}/>
            </LargeCount>
            <CountHeader>Boulders</CountHeader>
          </Col>
          <Col sm={true}>
            <LargeCount>
              <ProblemCount ancestryPath={`${area.id}`}/>
            </LargeCount>
            <CountHeader>Problems</CountHeader>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

