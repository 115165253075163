import {
  collection,
  query,
  where,
  orderBy,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { NoteConverter } from '../model/Note';

export async function getNotes(
  parent: string
) {
  const colRef = collection(Firestore, 'notes').withConverter(NoteConverter);
  let q = query(colRef, where('parent', '==', parent))

  q = query(q, orderBy('creationTime'));

  return (await getDocs(q)).docs.map(doc => doc.data());
}

