import React, { useState, useEffect, useCallback } from 'react';

import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import jsonDiff from 'json-diff';
import Ansi from 'ansi-to-react';
import styled from 'styled-components'

import { FirestoreV8Compat } from './Firebase';

const PreWrapAnsi = styled(Ansi)`
white-space: pre-line
`

export default function Logs() {
  const [areaLogs, setAreaLogs] = useState<any[]>([]);
  const [boulderLogs, setBoulderLogs] = useState<any[]>([]);
  const [problemLogs, setProblemLogs] = useState<any[]>([]);

  // pagination helpers
  const [lastAreaTimestamp, setLastAreaTimestamp] = useState<string>('9999');
  const [lastBoulderTimestamp, setLastBoulderTimestamp] = useState<string>('9999');
  const [lastProblemTimestamp, setLastProblemTimestamp] = useState<string>('9999');

  // get first areas
  useEffect(() => {
    fetchAreas();
    fetchBoulders();
    fetchProblems();
    // I only want these to run the first time the page loads
    // eslint-disable-next-line
  }, [])

  const fetchAreas = useCallback(() => {
    FirestoreV8Compat.collection('logs')
    .where('collection', '==', 'areas')
    .orderBy('timestamp', 'desc')
    .startAfter(lastAreaTimestamp)
    .limit(10)
    .get()
    .then(snapshotQuery => {
      const data = snapshotQuery.docs.map(snapshot => snapshot.data());
      setAreaLogs([...areaLogs, ...data]);
      if (data.length === 10) {
        setLastAreaTimestamp(data[9].timestamp);
      } else {
        setLastAreaTimestamp('');
      }
    })
    .catch(err => console.log('There was an error getting area logs:', err));
  }, [lastAreaTimestamp, areaLogs]);

  const fetchBoulders = useCallback(() => {
    FirestoreV8Compat.collection('logs')
    .where('collection', '==', 'boulders')
    .orderBy('timestamp', 'desc')
    .startAfter(lastBoulderTimestamp)
    .limit(10)
    .get()
    .then(snapshotQuery => {
      const data = snapshotQuery.docs.map(snapshot => snapshot.data());
      setBoulderLogs([...boulderLogs, ...data]);
      if (data.length === 10) {
        setLastBoulderTimestamp(data[9].timestamp);
      } else {
        setLastBoulderTimestamp('');
      }
    })
    .catch(err => console.log('There was an error getting boulder logs:', err));
  }, [lastBoulderTimestamp, boulderLogs]);

  const fetchProblems = useCallback(() => {
    FirestoreV8Compat.collection('logs')
    .where('collection', '==', 'problems')
    .orderBy('timestamp', 'desc')
    .startAfter(lastProblemTimestamp)
    .limit(10)
    .get()
    .then(snapshotQuery => {
      const data = snapshotQuery.docs.map(snapshot => snapshot.data());
      setProblemLogs([...problemLogs, ...data]);
      if (data.length === 10) {
        setLastProblemTimestamp(data[9].timestamp);
      } else {
        setLastProblemTimestamp('');
      }
    })
    .catch(err => console.log('There was an error getting problem logs:', err));
  }, [lastProblemTimestamp, problemLogs]);

  return (
    <Tab.Container defaultActiveKey="#areapane">
      <Row>
        <Col>
          <h3>Change Logs</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <ListGroup>
            <ListGroup.Item action href="#areapane">
              Areas
            </ListGroup.Item>
            <ListGroup.Item action href="#boulderpane">
              Boulders
            </ListGroup.Item>
            <ListGroup.Item action href="#problempane">
              Problems
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="#areapane">
              <ListGroup>
                {areaLogs.map((areaLog, i) => <Diffs key={i} logData={areaLog}/> )}
              </ListGroup>
              <Row>
                <Col />
                <Col sm="auto">
                  <Button variant="primary" onClick={fetchAreas}>
                    Load More
                  </Button>
                </Col>
                <Col />
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="#boulderpane">
              <ListGroup>
                {boulderLogs.map((boulderLog, i) => <Diffs key={i} logData={boulderLog}/> )}
              </ListGroup>
              <Row>
                <Col />
                <Col sm="auto">
                  <Button variant="primary" onClick={fetchBoulders}>
                    Load More
                  </Button>
                </Col>
                <Col />
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="#problempane">
              <ListGroup>
                {problemLogs.map((problemLog, i) => <Diffs key={i} logData={problemLog}/> )}
              </ListGroup>
              <Row>
                <Col />
                <Col sm="auto">
                  <Button variant="primary" onClick={fetchProblems}>
                    Load More
                  </Button>
                </Col>
                <Col />
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

interface DiffsProps {
  logData: any;
}

function Diffs({logData}: DiffsProps) {
  let displayName = (logData.newData?.name ?? logData.oldData?.name ?? logData.data?.name ?? '<No Name>') as string;
  let documentId = logData.document as string;
  let oldData = logData.oldData ?? {};
  let newData = logData.newData ?? logData.data ?? {};

  return (
    <ListGroup.Item>
      <Row>
        {documentId}
      </Row>
      <Row>
        {displayName}
      </Row>
      <Row>
        {(oldData && newData) &&
        <Col>
          <PreWrapAnsi>
            {`${jsonDiff.diffString(oldData, newData)}`}
          </PreWrapAnsi>
        </Col>
        }
      </Row>
    </ListGroup.Item>
  );
}

