import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import {
  BsPencil,
  BsQuestionCircle,
  BsSortUp,
  BsTrash
} from 'react-icons/bs';
import {
  AiOutlineEyeInvisible
} from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { useQuery, useQueryClient } from 'react-query';
import { PropagateLoader } from 'react-spinners'

import { deleteZone } from '../database';
import ResponsiveCardDeck from './ResponsiveCardDeck';
import * as Database from '../database';
import UserContext from '../context/UserContext';
import { Note } from '../model/Note';
import Zone from '../model/Zone';
import { Title, Subtitle, Text } from './ThemedComponents';
import BoulderCard from './BoulderCard';
import ZoneModal from './ZoneModal';
import RequestsModal from './RequestsModal';
import FeedbackModal from './FeedbackModal';
import SectionContainer from './ContentSectionContainer';
import CustomIcon from '../Icon';
import BoulderModal from './BoulderModal';
import ReorderBouldersModal from './ReorderBouldersModal';
import NoteAccordion from './NoteAccordion';

interface Props {
  zone: Zone;
}

export default function ZoneContent({ zone }: Props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState<string>('');

  const [showZoneModal, setShowZoneModal] = useState(false);
  const [showBoulderModal, setShowBoulderModal] = useState(false);
  const [showReorderBouldersModal, setShowReorderBouldersModal] = useState(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalRegards, setFeedbackModalRegards] = useState('');

  const fetchBoulders = useCallback(async () => {
    return Database.getBoulders(`zones/${zone.id}` ?? null, user?.hasElevatedReadAccess());
  }, [user, zone.id]);

  const fetchReqs = useCallback(async () => {
    return Database.getInfoRequests(`zones/${zone.id}`);
  }, [zone.id]);

  const { isLoading: isLoadingBoulders, data: boulders } = useQuery(['boulders', zone.id, user], fetchBoulders);
  const { data: reqs } = useQuery(['reqs', zone.id], fetchReqs);
  // TODO: Notes

  const setHighlightViaHash = useCallback(() => {
    const hash = window.location.hash;
    if (hash.split('-').length > 1) {
      setHighlight(hash.split('-')[1]);
    }
  }, [])

  // highlight on hash change
  useEffect(() => {
    // init
    setHighlightViaHash();
    window.addEventListener('hashchange', setHighlightViaHash, false)
    return (() => window.removeEventListener('hashchange', setHighlightViaHash, false));
  }, [setHighlightViaHash])

  // get boulders
  useEffect(() => {
    if (zone && zone.id) {
      return Database.subscribeToBoulders(`zones/${zone.id}`, () => {
        queryClient.invalidateQueries(['boulders', zone.id]);
      }, (error) => {
        console.log('There was an error subscribing to boulders:', error);
      }, undefined, user?.hasElevatedReadAccess());
    }
  }, [zone, user, queryClient]);

  const handleRequestModalSubmitInfo = useCallback((note: Note) => {
    setShowRequestsModal(false);
    setFeedbackModalRegards(`note ${note.id}`);
    setShowFeedbackModal(true);
  }, [])

  const handleShowZoneModal = useCallback(() => {
    setShowZoneModal(true);
  }, []);

  const handleCloseZoneModal = useCallback(() => {
    setShowZoneModal(false);
  }, []);

  const handleShowBoulderModal = useCallback(() => {
    setShowBoulderModal(true);
  }, []);

  const handleCloseBoulderModal = useCallback(() => {
    setShowBoulderModal(false);
  }, []);

  const handleShowReorderBouldersModal = useCallback(() => {
    setShowReorderBouldersModal(true);
  }, []);

  const handleCloseReorderBouldersModal = useCallback(() => {
    setShowReorderBouldersModal(false);
  }, []);

  const handleDeleteZone = useCallback(async () => {
    if (window.confirm(`Are you sure you want to delete ${zone.name}? This cannot be undone and will leave orphans.`)) {
      await deleteZone(zone.id);
      history.push('/guidebook');
    }
  }, [zone.id, zone.name, history]);

  return (
    <>
      { user?.hasWriteAccess() &&
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" onClick={handleShowZoneModal}><BsPencil width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowBoulderModal}><CustomIcon.AddBoulder width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowReorderBouldersModal}><BsSortUp width="1em" height="1em" /></Button>
            <Button variant="danger" onClick={handleDeleteZone}><BsTrash width="1em" height="1em" /></Button>
          </ButtonGroup>
        </ButtonToolbar>
      }

      <ButtonToolbar className="justify-content-end">
        <ButtonGroup>
          {reqs !== undefined && reqs.length > 0 &&
          <Button
            onClick={() => setShowRequestsModal(true)}
          >
            <BsQuestionCircle />
            {" "}
            <Badge className="text-dark rounded-pill" bg="light">{reqs?.length}</Badge>
          </Button>
          }
        </ButtonGroup>
      </ButtonToolbar>

      <SectionContainer>
        <Row>
          <Col xs="auto">
            <Title>{zone ? zone.name : ''}</Title>
          </Col>
          {zone.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
          </Col>
          }
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Row>
              <Col>
                <Subtitle id="description">Description</Subtitle>
                <Text>{zone.description}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Subtitle id="directions">Directions</Subtitle>
                <Text>{zone.directions}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContainer>

      <SectionContainer>
        <Row>
          <Col>
            { isLoadingBoulders &&
              <Row>
                <Col />
                <Col xs="auto">
                  <PropagateLoader size={15} color='#c28465'/>
                </Col>
                <Col />
              </Row>
            }
            {boulders &&
              <ResponsiveCardDeck>
                <>
                {boulders.map((boulder, index) => {
                    return <BoulderCard highlight={highlight === boulder.id} htmlId={`boulder-${boulder.id}`} key={`boulder-${index}`} boulder={boulder}/>
                })}
                </>
              </ResponsiveCardDeck>
            }
          </Col>
        </Row>
      </SectionContainer>

      { user?.hasElevatedReadAccess &&
      <NoteAccordion
        parent={`zones/${zone.id}`}
      />
      }

      {/* Modals */}
      {user?.hasWriteAccess() &&
        <>
          <ZoneModal
            show={showZoneModal}
            onHide={handleCloseZoneModal}
            zone={zone}
          />
          <RequestsModal
            notes={reqs ?? []}
            show={showRequestsModal}
            onHide={() => setShowRequestsModal(false)}
            onSubmitInfo={handleRequestModalSubmitInfo}
          />
          <FeedbackModal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            withRegards={feedbackModalRegards}
          />
          <BoulderModal
            show={showBoulderModal}
            onHide={handleCloseBoulderModal}
            parent={`zones/${zone.id}`}
          />
          {boulders &&
            <ReorderBouldersModal
              show={showReorderBouldersModal}
              onHide={handleCloseReorderBouldersModal}
              boulders={boulders}
            />
          }
        </>
      }
    </>
  );
}

