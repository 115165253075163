import React, { useState, useEffect, useContext, useCallback } from 'react';

import { GiBrokenBone } from 'react-icons/gi'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
  BsJournalCheck,
  BsPencil,
  BsStarFill,
  BsTrash,
} from 'react-icons/bs';
import {
  AiOutlineEyeInvisible
} from 'react-icons/ai'

import * as Database from '../database';
import { deleteProblem } from '../database';
import { Note } from '../model/Note';
import NotesModal from './NotesModal';
import ProblemModal from './ProblemModal';
import ThemeContext from '../context/ThemeContext';
import Problem from '../model/Problem';
import { Header, Text } from './ThemedComponents';

interface Props {
  htmlId?: string;
  label?: number;
  problem: Problem;
  editable?: boolean;
  highlight?: boolean;
}

export default function ProblemListItem({label, problem, htmlId, editable, highlight}: Props) {
  const { theme } = useContext(ThemeContext);

  const [notes, setNotes] = useState<Note[]>([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // get notes
  // TODO: This means I read all the notes twice.. kind of ugly
  useEffect(() => {
    return Database.subscribeToNotes(problem, setNotes);
  }, [problem])

  const handleDeleteProblem = useCallback(async () => {
    if (window.confirm(`Are you sure you want to delete ${problem.name}? This cannot be undone and will leave orphans.`)) {
      await deleteProblem(problem.id);
    }
  }, [problem.id, problem.name]);

  return (
    <>
      <ListGroupItem className={highlight ? 'bg-secondary' : ''} id={htmlId}>
        <Row>
          <Col />
          {problem.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b"/>
          </Col>
          }
          <Col xs="auto">
            {editable &&
              <ButtonToolbar>
                <ButtonGroup>
                  <Button variant="primary" onClick={() => setShowNotesModal(true)}>
                    <BsJournalCheck />
                    {notes.length > 0 &&
                    <>
                      {" "}
                      <Badge className="text-dark rounded-pill" bg="light">{notes.length}</Badge>
                    </>
                    }
                  </Button>
                  <Button variant="primary" onClick={() => setShowEditModal(true)}><BsPencil /></Button>
                  <Button variant="danger" onClick={handleDeleteProblem}><BsTrash /></Button>
                </ButtonGroup>
              </ButtonToolbar>
            }
          </Col>
        </Row>
        <Row>
          {label &&
          <Col xs="auto">
            <Text>{label}.</Text>
          </Col>
          }
          <Col xs="auto">
            <Header>{problem.name}</Header>
          </Col>
          <Col xs="auto">
            <Text>{problem.grade}</Text>
          </Col>
          {problem.unsafe &&
          <Col xs="auto">
            <GiBrokenBone />
          </Col>
          }
          <Col xs="auto">
            {problem &&
              [...Array(problem.rating).keys()].map((_, index) => {
                return <BsStarFill key={index} color={`${theme.primaryColor}`} />
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Text>{problem.description}</Text>
          </Col>
        </Row>
        { problem.fa &&
        <>
          <Row>
            <Col xl="4" lg="5" md="6" />
            <Col xl="8" lg="7" md="6" className="text-muted">
              <p className="text-end">
                {problem.fa.party} {problem.fa.party && problem.fa.year ? '-' : ''} {problem.fa.year}
              </p>
            </Col>
          </Row>
        </>
        }
      </ListGroupItem>

      {editable &&
        <>
          <NotesModal
            show={showNotesModal}
            onHide={() => setShowNotesModal(false)}
            parent={`problems/${problem.id}`}
          />
          <ProblemModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            problem={problem}
          />
        </>
      }
    </>
  );
}
