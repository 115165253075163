import { collection, onSnapshot } from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Feedback, { FeedbackConverter } from '../model/Feedback';


export function subscribeToFeedback(
  onNext: (feedback: Feedback[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  const colRef = collection(Firestore, 'feedback').withConverter(FeedbackConverter);

  return (
    onSnapshot(colRef,
      querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data())),
      onError,
      onCompletion
    )
  );
}


