import React from 'react';
import { Image, ImageProps } from 'react-bootstrap';
import {
  getDownloadURL,
  list,
  ref
} from 'firebase/storage';

import { ImageStorage } from '../Firebase'

interface Props extends ImageProps {
  imageId: string;
  size?: 'sm' | 'lg' | 'orig';
}

export default function FirebaseImage(props: Props) {
  const [url, setUrl] = React.useState<string>();

  React.useEffect(() => {
    let size: string;

    switch(props.size) {
      case 'sm':
        size = 'small';
        break;
      case 'lg':
        size = 'large'
        break;
      case 'orig':
        size =  'original';
        break;
      default:
        size = 'medium';
        break;
    }

    list(ref(ImageStorage, `${props.imageId}`))
      .then(listResult => {
        const imageRef = listResult.items.filter(item => item.name.includes(size))[0];
        try {
          return getDownloadURL(imageRef);
        } catch {
          throw(Error(`Image ${props.imageId} doesn't exist.`));
        }
      })
      .then(downloadUrl => {
        setUrl(downloadUrl);
      })
      .catch(err => {
        console.log('There was an error loading the image:', err);
      })
  }, [props.imageId, props.size]);

  return (
    <Image {...props} src={url} />
  );
}

