import React, { useCallback } from 'react';

import Table from 'react-bootstrap/Table';
import { useTable, Column } from 'react-table';
import { BsCaretUp, BsCaretDown } from 'react-icons/bs';
import styled from 'styled-components';


const StyledTh = styled.th`
cursor: pointer;
white-space: nowrap;
`

type GenericObject = { [key: string]: any };
export type SortedHeader = { id: string; direction: 'ascending' | 'descending' };

interface CustomTableProps {
  columns: Column<GenericObject>[];
  data: GenericObject[];
  sort?: SortedHeader;
  onHeaderClick?: (id: string) => void;
  onRowClick?: (row: GenericObject) => void;
}

export default function CustomTable({ columns, data, onHeaderClick, onRowClick, sort }: CustomTableProps) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  // If onRowClick, this will be called when a row in clicked, returning the row data
  const handleRowSelect = useCallback((row: GenericObject) => {
    if (onRowClick) {
      onRowClick(row);
    }
  }, [onRowClick])

  const handleHeaderSelect = useCallback((id: string) => {
    if (onHeaderClick) {
      onHeaderClick(id);
    }
  }, [onHeaderClick])

  return (
    <Table responsive bordered striped hover {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <StyledTh
                onClick={() => handleHeaderSelect(column.id)}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
                {sort && sort.id === column.id ?
                  sort.direction === 'ascending' ? <BsCaretUp /> : <BsCaretDown />
                  :
                  null
                }
              </StyledTh>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr
              onClick={() => handleRowSelect(row.original)}
              style={{cursor: onRowClick ? 'pointer' : undefined}}
              {...row.getRowProps()}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

