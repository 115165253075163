import React, { useCallback } from 'react';

import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Button from 'react-bootstrap/Button';

import Feedback from '../../model/Feedback';

import { FirestoreV8Compat } from '../../Firebase';


interface Props {
  feedback: Feedback;
}

export default function FeedbackItem({ feedback }: Props) {
  const onResolve = useCallback(() => {
    FirestoreV8Compat.collection('Feedback').doc(feedback.id).update({resolved: true, resolvedTime: Date.now()})
  }, [feedback]);

  const onUnresolve = useCallback(() => {
    FirestoreV8Compat.collection('Feedback').doc(feedback.id).update({resolved: false, resolvedTime: -1})
  }, [feedback]);

  return (
    <ListGroupItem>
      <p>{feedback.content}</p>
      <p>uid: {feedback.issuer}</p>
      <hr />
      <a href={feedback.url}>{feedback.url}</a>
      <hr />
      {feedback.resolved ?
        <>
          <span>Resolved at {new Date(feedback.resolvedTime ?? 0).toString()}</span>
          <Button variant="warning" onClick={onUnresolve}>
            Unresolve
          </Button>
        </>
      :
        <Button variant="success" onClick={onResolve}>
          Resolve
        </Button>
      }
    </ListGroupItem>
  );
}

