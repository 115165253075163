import styled from 'styled-components';

import WhiteInlineLogo from '../assets/inline_logo_128.png';
import ColoredInlineLogo from '../assets/inline_logo_color_128.png';


const Logo = styled.div`
height: 56px;
width: 100px;
background: url(${WhiteInlineLogo}) no-repeat;
background-size: contain;
opacity: 0.5;
transition-duration: 50ms;

&:hover {
  background: url(${ColoredInlineLogo}) no-repeat;
  background-size: contain;
  opacity: 1;
}
`

export default Logo;
