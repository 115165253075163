import React, {  useCallback } from 'react';

import { useQueryClient } from 'react-query';
import { useMutation } from 'react-query'
import Modal from 'react-bootstrap/Modal';

import { Note, NoteData } from '../model/Note';
import { createNote, updateNote } from '../database';
import NoteForm from './NoteForm';


interface NewNoteProps {
  show: boolean;
  onHide: () => void;
  parent: string;
}

interface EditNoteProps {
  show: boolean;
  onHide: () => void;
  note: Note;
}

type NoteModalProps = NewNoteProps | EditNoteProps;

function isNew(obj: {parent:string}|{note:Note}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function NoteModal({ show, onHide, ...props }: NoteModalProps) {
  const queryClient = useQueryClient();
  const { mutateAsync: mutateNote } = useMutation((note: NoteData): Promise<void> => {
    if (isNew(props)) {
      return createNote(note);
    } else {
      return updateNote(props.note.id, note);
    }
  });

  const handleSave = useCallback(async (note: NoteData) => {
    try {
      await mutateNote(note);
    } catch (e) {
      console.log('There was a problem mutating note:', e);
    }
    queryClient.invalidateQueries('notes');
    onHide();
  }, [queryClient, onHide, mutateNote]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isNew(props) ? 'Create Note' : 'Update Note'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isNew(props) ?
          <NoteForm
            onSubmit={handleSave}
            parent={props.parent}
          />
          :
          <NoteForm
            onSubmit={handleSave}
            note={props.note}
          />
        }
      </Modal.Body>
    </Modal>
  );
}
