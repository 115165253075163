import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

interface ProblemCollectionData {
  name: string;
  problems: string[];
  parentArea: string | null;
}

interface ProblemCollection extends ProblemCollectionData {
  id: string;
}

const ProblemCollectionConverter: FirestoreDataConverter<ProblemCollection> = {
  toFirestore(collection: WithFieldValue<ProblemCollection>): DocumentData {
    let copy = {...collection};
    
    delete copy.id;

    return {...copy};

  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ProblemCollection {
    let data = snapshot.data(options);

    return ({
      id: snapshot.id,
      name: data.name ?? '',
      problems: data.problems ?? [],
      parentArea: data.parentArea ?? null
    });
  }
}

export default ProblemCollection;
export { ProblemCollectionConverter };
export type { ProblemCollectionData };

