import {
  doc,
  getDoc
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { regionConverter } from '../model/Region';

export async function getRegion(
  id: string
) {
  let docRef = doc(Firestore, `regions/${id}`).withConverter(regionConverter);

  return (await getDoc(docRef)).data();
}

