import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

interface PhotoData {
  pid: string;
  uid: string;
  caption: string;
  photographer: string;
  markings: PhotoMarking[];
}

interface PhotoMarking {
  type: "action" | "scenic" | "feature";
  parent: string;
}

interface Photo extends PhotoData {
  id: string;
}

const PhotoConverter = {
  toFirestore(photo: Photo): DocumentData {
    let copy = {...photo};
    delete copy.id;
    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    let data = snapshot.data(options);
    return {
      id: snapshot.id,
      pid: data.pid ?? '',
      uid: data.uid ?? '',
      caption: data.caption ?? '',
      photographer: data.photographer ?? '',
      markings: data.markings
    }
  }
}

export default Photo;
export type { PhotoMarking };
export { PhotoConverter };

