interface StringValidator {
  isAcceptable(s: string): boolean;
}

const vgradeRegexp = /^(Project)|(V(\?|B|\d+|(\d+\/\d+)|(\d+(\+|-))))$/;
const basicPattern = /^VB$/i;
const projectPattern = /^project$/i;
const unknownPattern = /^V\?$/i;
const vPattern = /^V(\d+)((\/(\d+))|(\+|-))?$/i;

export enum SpecialGrade {
  Basic = 'basic',
  Project = 'project',
  Unknown = 'unknown'
}

export const GRADE_MAX = 17

export type VGrade = {
  value: number
}

export type GradeInfo = SpecialGrade | VGrade

export function gradeInfo(grade: string) {
  if (basicPattern.test(grade)) {
    return SpecialGrade.Basic;
  }

  if (projectPattern.test(grade)) {
    return SpecialGrade.Project;
  }

  if (unknownPattern.test(grade)) {
    return SpecialGrade.Unknown;
  }

  if (vPattern.test(grade)) {
    return { value: valueOfVGrade(grade) };
  }

  return SpecialGrade.Unknown;
}

function valueOfVGrade(grade: string): number {
  if (!vPattern.test(grade)) {
    return -1;
  }

  const match = grade.match(vPattern);

  if (!match) {
    return -1;
  }

  const base = Number(match[1]) // V(\d+)
  // 0.4 max bias because base +/- 1 should not happen
  const biasPerSlash = 0.4 / Math.max(Math.abs(base), Math.abs(GRADE_MAX - base))

  let bias = 0.0

  // slash grade
  if (match[3] !== undefined) { // \/\d+
    const slashGrade = Number(match[4]) // \/(\d+)
    bias += (slashGrade - base) * biasPerSlash;
  }

  // modifier
  if (match[5] !== undefined) {
    const modifier = match[5];
    if (modifier === "+") {
      bias += biasPerSlash / 2;
    } else if (modifier === "-") {
      bias -= biasPerSlash / 2;
    }
  }

  return base + 0.5 + bias;
}

export function compareGrades(lhs: string, rhs: string) {
  return compareGradeInfos(gradeInfo(lhs), gradeInfo(rhs));
}

function compareGradeInfos(lhs: GradeInfo, rhs: GradeInfo) {
  if (lhs === SpecialGrade.Basic) {
    return -1;
  }

  if (rhs === SpecialGrade.Basic) {
    return 1;
  }

  if (lhs === SpecialGrade.Project) {
    return 1
  }

  if (rhs === SpecialGrade.Project) {
    return -1;
  }

  if (lhs === SpecialGrade.Unknown) {
    return 1;
  }

  if (rhs === SpecialGrade.Unknown) {
    return -1;
  }

  return lhs.value - rhs.value;
}

export class VGradeValidator implements StringValidator {
  isAcceptable(s: string) {
    return vgradeRegexp.test(s)
  }
}


