import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import UserContext from '../context/UserContext';
import Logo from './Logo';
import { Text } from './ThemedComponents';
import FeedbackModal from './FeedbackModal';

const UnstyledLink = styled(Link)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
            text-decoration: none;
                
  }
`

export default function NavigationBar() {
  const { user } = useContext(UserContext);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  React.useEffect(() => {
    console.log(JSON.stringify(user, null ,2));
  }, [user])

  return (
    <>
      <Navbar className="p-2" collapseOnSelect expand="lg" bg="dark" variant="dark">
          <UnstyledLink to="/">
            <Navbar.Brand as="div">
              <Logo />
            </Navbar.Brand>
          </UnstyledLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {user?.hasGuideAccess() &&
              <UnstyledLink to="/guidebook">
                <Nav.Link as="div">
                  <Text>
                    Guidebook
                  </Text>
                </Nav.Link>
              </UnstyledLink>
              }
              {user?.isPhotographer() &&
              <UnstyledLink to="/gallery">
                <Nav.Link as="div">
                  <Text>
                    Gallery
                  </Text>
                </Nav.Link>
              </UnstyledLink>
              }
              <UnstyledLink to="/contribute">
                <Nav.Link as="div">
                  <Text>
                    Contribute
                  </Text>
                </Nav.Link>
              </UnstyledLink>
            </Nav>
            <Nav>
              {user?.hasGuideAccess() &&
                <Nav.Link
                  style={{cursor: 'pointer'}}
                  onClick={() => setShowFeedbackModal(true)}
                  as="div"
                >
                  <Text>
                    Feedback
                  </Text>
                </Nav.Link>
              }
              {!user &&
                <UnstyledLink to="/signin">
                  <Nav.Link as="div">
                    <Text>
                      Sign In
                    </Text>
                  </Nav.Link>
                </UnstyledLink>
              }
              {user &&
                <UnstyledLink to="/account">
                  <Nav.Link as="div">
                    <Text>
                      Account
                    </Text>
                  </Nav.Link>
                </UnstyledLink>
              }
              {user?.role === 'admin' &&
                <UnstyledLink to="/admin">
                  <Nav.Link as="div">
                    <Text>
                      Admin
                    </Text>
                  </Nav.Link>
                </UnstyledLink>
              }
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      {showFeedbackModal &&
      <FeedbackModal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
      />
      }
    </>
  );
}
