import React, { useEffect, useState } from 'react';
import './App.css';
import './App.scss'
import 'react-awesome-lightbox/build/style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { Auth } from './Firebase';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import UserContext from './context/UserContext';
import User, { UserFromFirebaseUser } from './model/User'
import ThemeContext from './context/ThemeContext';
import Home from './Home';
import Guidebook from './Guidebook';
import Gallery from './Gallery';
import Admin from './Admin';
import Search from './Search'
import About from './About';
import Logs from './Logs';
import Permission from './Permissions';
import Contributions from './Contributions';
//import Pricing from './Pricing';
import NoPageFound from './NoPageFound';
import NavigationBar from './components/NavigationBar';
import SignIn from './SignIn';
import Account from './Account';
import { defaultTheme } from './themes';

// react-query client
const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState<User | null>();
  const [theme, setTheme] = useState(defaultTheme);

  // set user on auth change
  useEffect(() => {
    return Auth.onAuthStateChanged(user => {
      UserFromFirebaseUser(user)
      .then(u => setUser(u))
      .catch(e => console.log(e));
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
    <UserContext.Provider value={{user, setUser}}>
    <ThemeContext.Provider value={{theme, setTheme}}>
    <ThemeProvider theme={theme}>
      <Router>
        <QueryParamProvider>
          <header>
            <NavigationBar />
          </header>
          <main>
            { user && !user.verified &&
            <Alert variant="warning">
              <Container>
                <Alert.Heading>
                  Email not verified
                </Alert.Heading>
                <p>
                  Your email must be verified to access many account features such as purchasing the online guidebook or ticks.
                </p>
                <Button variant="primary" onClick={() => user.firebaseUser.sendEmailVerification()}> Send Verification Email</Button>
              </Container>
            </Alert>
            }
            <Switch>
              <Route exact path="/" component={Home} />
              {/* TODO Temporary hide for public release
              <Route path="/pricing" component={Pricing} />
              */}
              <Route path="/guidebook" component={Guidebook} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/search" component={Search} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/about" component={About} />
              <Route exact path="/logs" component={Logs} />
              <Route exact path="/permission" component={Permission} />
              <Route exact path="/contribute" component={Contributions} />
              <Route component={NoPageFound} />
            </Switch>
          </main>
        </QueryParamProvider>
      </Router>
    </ThemeProvider>
    </ThemeContext.Provider>
    </UserContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
