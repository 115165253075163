import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { RegionData } from '../model/Region';


export async function createRegion(region: RegionData): Promise<void> {
  await FirestoreV8Compat.collection('regions').add(region);
  // returning void so all of these have the same return type
  return;
}

export async function updateRegion(id: string, data: UpdateData<RegionData>) {
  return FirestoreV8Compat.doc(`regions/${id}`).update(data);
}

export async function deleteRegion(id: string) {
  return FirestoreV8Compat.doc(`regions/${id}`).delete();
}

