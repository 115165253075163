import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import UserContext from '../context/UserContext';
import Region, { isRegion } from '../model/Region';
import Area, { isArea } from '../model/Area';
import Sector, { isSector } from '../model/Sector';
import Zone, { isZone } from '../model/Zone';
import Boulder, { isBoulder } from '../model/Boulder';
import {
  getRegion,
  getArea,
  getSector,
  getZone,
  getAreas,
  getSectors
} from '../database';
import QuickSearch from './QuickSearch';


interface SidenavProps {
  selected: Region | Area | Sector | Zone | Boulder | null;
}

const NavbarParentLink = styled(Link)`
  text-decoration: none;
  color: var(--dark);
  display: block;
  padding: .5rem 1rem;
  opacity: 1.0;
  transition: opacity .2s ease-out;
  &:hover {
    text-decoration: none;
    color: var(--dark);
    display: block;
    padding: .5rem 1rem;
    opacity: 0.5;
  }
  &.active {
    margin-top: 1rem;
    font-weight: 650;
    opacity: 1.5;
  }
`

const NavbarSubLink = styled(Link)`
  text-decoration: none;
  font-size: 90%;
  color: var(--dark);
  display: block;
  padding: .5rem 1rem;
  padding-top: 0.25rem;
  opacity: 1.0;
  transition: opacity .2s ease-out;
  &:hover {
    text-decoration: none;
    color: var(--dark);
    display: block;
    padding: .5rem 1rem;
    padding-top: 0.25rem;
    opacity: 0.5;
  }
  &.active {
    font-weight: 650;
    opacity: 1.5;
  }
`

interface FullTreeBranch {
  region: Region | null;
  area: Area | null;
  sector: Sector | null;
  zone: Zone | null;
  boulder: Boulder | null;
}

export default function Sidenav({ selected }: SidenavProps) {
  const { data: branch } = useQuery(['sidenav-branch', selected], async () => {
    // updating the branch will be done atomically to avoid issues with placing
    // sectors beneath the wrong area, especially when changing regions
    const branch: FullTreeBranch = {
      region: null,
      area: null,
      sector: null,
      zone: null,
      boulder: null
    };

    // use selected to generate the tree branch
    try {
      if (isRegion(selected)) {
        branch.region = selected;
      } else if (isArea(selected)) {
        branch.area = selected;
        branch.region = await getRegion(branch.area.parent.split('/')[1]) ?? null;
      } else if (isSector(selected)) {
        branch.sector = selected;
        branch.area = await getArea(branch.sector.parent.split('/')[1]) ?? null;
        branch.region = await getRegion(branch.area!.parent.split('/')[1]) ?? null;
      } else if (isZone(selected)) {
        branch.zone = selected;
        branch.sector = await getSector(branch.zone!.parent.split('/')[1]) ?? null
        branch.area = await getArea(branch.sector!.parent.split('/')[1]) ?? null;
        branch.region = await getRegion(branch.area!.parent.split('/')[1]) ?? null;
      } else if (isBoulder(selected)) {
        branch.boulder = selected;
        const parentType = branch.boulder.parent.split('/')[0];
        if (parentType === 'zones') {
          branch.zone = await getZone(branch.boulder!.parent.split('/')[1]) ?? null;
          branch.sector = await getSector(branch.zone!.parent.split('/')[1]) ?? null
          branch.area = await getArea(branch.sector!.parent.split('/')[1]) ?? null;
          branch.region = await getRegion(branch.area!.parent.split('/')[1]) ?? null;
        } else if (parentType === 'sectors') {
          branch.sector = await getSector(branch.boulder!.parent.split('/')[1]) ?? null
          branch.area = await getArea(branch.sector!.parent.split('/')[1]) ?? null;
          branch.region = await getRegion(branch.area!.parent.split('/')[1]) ?? null;
        }
      }
    } catch (e) {
      console.log('Something bad happened when attempting to generate the navigation branch:', e);
      branch.region = null;
      branch.area = null;
      branch.sector = null;
      branch.zone = null;
      branch.boulder = null;
    }

    return branch;
  });

  const { data: areas } = useQuery(['sidenav-region', branch?.region], async () => {
    if (!branch?.region) {
      return [];
    }

    return await getAreas(`regions/${branch.region.id}`, user?.hasElevatedReadAccess());
  }, {
    enabled: !!branch
  });

  const { data: sectors } = useQuery(['sidenav-area', branch?.area], async () => {
    if (!branch?.area) {
      return [];
    }

    return await getSectors(`areas/${branch.area.id}`, user?.hasElevatedReadAccess());
  }, {
    enabled: !!branch
  });

  const { user } = useContext(UserContext);

  return (
    <>
      <div className="mt-3">
        <QuickSearch />
      </div>
      <Navbar expand="md" className="p-0 d-inline d-md-inline-block">
        <Navbar.Collapse className="border-top pt-2 pb-4">
          <Nav className="w-100 flex-column">
            <>
              {areas?.map(area => {
                return (
                  <div key={`sidenav-item-${area.id}`}>
                    <NavbarParentLink
                      className={`${(area.id === branch?.area?.id) ? 'active' : ''}`}
                      to={`/guidebook/area?id=${area.id}`}
                    >
                      {area.name}
                    </NavbarParentLink>
                    {/* if area is in the currently viewed branch show sectors*/
                      (area.id === branch?.area?.id) &&
                        sectors?.map(sector => (
                          <NavbarSubLink
                            key={`subsidenav-${sector.id}`}
                            className={`${(sector.id === branch?.sector?.id) ? 'active' : ''}`}
                            to={`/guidebook/sector?id=${sector.id}`}
                          >
                            {sector.name}
                          </NavbarSubLink>
                      ))
                    }
                  </div>
                );
              })}
            </>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

