import React, { useState, useEffect, useCallback } from 'react';

import { Subtext } from './ThemedComponents';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import styled from 'styled-components';

import { getProblem } from '../database';
import { Text, Span } from './ThemedComponents';
import { QuickSearch, QuickSearchHit } from '../search/quickSearch';


const ClickableListGroupItem = styled(ListGroupItem)`
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`

export default function Search() {
  const [searchText, setSearchText] = useState("");
  const { data: searchResults, isIdle, isError, isLoading } = useQuery(['QuickSearch', searchText], async () => {
    return await new QuickSearch().limitTo(5).setText(searchText).get();
  });

  const resultsPopover = (
    <Popover id="search-popover" style={{width: '100%', maxHeight: '50vh', overflowY: 'auto'}}>
      <Popover.Header as="h3">
        <Row>
          <Col>
            <Span>
              Search Results
            </Span>
          </Col>
        </Row>
      </Popover.Header>
      <Popover.Body>
        { isIdle ?
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Text>
                Search for areas, boulders, and problems.
              </Text>
            </ListGroup.Item>
          </ListGroup>
        : isLoading ?
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Text>Loading...</Text>
            </ListGroup.Item>
          </ListGroup>
        : isError ?
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Text>There was a problem performing quick search...</Text>
            </ListGroup.Item>
          </ListGroup>
        : searchResults?.hits.length === 0 ?
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Text>No results</Text>
            </ListGroup.Item>
          </ListGroup>
          :
          <ListGroup variant="flush">
            {searchResults?.hits.map((hit: any) => <QuickSearchItem key={hit.objectID} hit={hit} />)}
          </ListGroup>
        }
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="focus" placement="bottom-start" overlay={resultsPopover}>
      <FormControl
        type="text"
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search"
      />
    </OverlayTrigger>
  );
}

interface QuickSearchItemProps {
  hit: QuickSearchHit;
}

function QuickSearchItem({ hit }: QuickSearchItemProps) {
  const [highlightedHTML, setHighlightedHTML] = React.useState<string>('');
  const history = useHistory();

  useEffect(() => {
    // make change bold because its easier to see
    let highlightedText = (hit._highlightResult?.name?.value ?? hit.name).replaceAll('em>', 'b>');
    setHighlightedHTML(highlightedText);
  }, [hit]);

  const navigate = useCallback(async () => {
    let id = hit.objectID.split('/')[1];

    let page = '';
    console.log(hit.type);
    switch(hit.type) {
      case 'region':
        page = 'region';
        break;
      case 'area':
        page = 'area';
        break;
      case 'sector':
        page = 'sector';
        break;
      case 'zone':
        page = 'zone';
        break;
      case 'boulder':
      case 'problem':
        page = 'boulder';
        break;
    }

    if (hit.type === 'problem') {
      let problem = await getProblem(id);
      id = problem?.parent.split('/')[1] ?? '';
    }

    history.push(`/guidebook/${page}?id=${id}`);
  }, [history, hit]);

  return (
    <ClickableListGroupItem
      key={hit.objectID}
      onClick={navigate}
    >
      <Container>
        <Row>
          <Col style={{textAlign: 'right'}}><Subtext>{hit.type}</Subtext></Col>
        </Row>
        <Row>
          <Col sm="auto" dangerouslySetInnerHTML={{__html: highlightedHTML}} />
        </Row>
      </Container>
    </ClickableListGroupItem>
  );
}
