import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Problem from '../model/Problem';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  problems: Problem[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderProblemsModal({ problems, show, onHide }: Props) {
  const [unsortedProblems, setUnsortedProblems] = React.useState<Problem[]>([]);
  const [sortedProblems, setSortedProblems] = React.useState<Problem[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Problem[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Problem[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(problems.filter(problem => problem.order === null));
    setSortedResetState(problems.filter(problem => problem.order !== null));
  }, [problems])

  // Initial problem state
  useEffect(() => {
    setUnsortedProblems(problems.filter(problem => problem.order === null));
    setSortedProblems(problems.filter(problem => problem.order !== null));
  }, [problems])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedProblems, sortedResetState) && deepEqual(unsortedProblems, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedProblems, sortedResetState, unsortedProblems, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedProblems.forEach((problem, index) => {
          const original = problems.find(original => original.id === problem.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `problems/${problem.id}`), {order: index})
          }
        });
        unsortedProblems.forEach(problem => {
          const original = problems.find(original => original.id === problem.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `problems/${problem.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [problems, sortedProblems, unsortedProblems, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = problems.filter(problem => unsorted.find(sortable => sortable.id === problem.id));
    const newSorted = problems.filter(problem => sorted.find(sortable => sortable.id === problem.id))
      // sort problems based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedProblems(newUnsorted);
    setSortedProblems(newSorted);
  }), [problems]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Problems</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedProblems.map(problem => ({id: problem.id ?? '', display: problem.name}))}
          defaultOrdered={sortedProblems.map(problem => ({id: problem.id ?? '', display: problem.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

