import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Region from '../model/Region';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  regions: Region[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderRegionsModal({ regions, show, onHide }: Props) {
  const [unsortedRegions, setUnsortedRegions] = React.useState<Region[]>([]);
  const [sortedRegions, setSortedRegions] = React.useState<Region[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Region[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Region[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(regions.filter(region => region.order === null));
    setSortedResetState(regions.filter(region => region.order !== null));
  }, [regions])

  // Initial region state
  useEffect(() => {
    setUnsortedRegions(regions.filter(region => region.order === null));
    setSortedRegions(regions.filter(region => region.order !== null));
  }, [regions])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedRegions, sortedResetState) && deepEqual(unsortedRegions, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedRegions, sortedResetState, unsortedRegions, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedRegions.forEach((region, index) => {
          const original = regions.find(original => original.id === region.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `regions/${region.id}`), {order: index})
          }
        });
        unsortedRegions.forEach(region => {
          const original = regions.find(original => original.id === region.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `regions/${region.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [regions, sortedRegions, unsortedRegions, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = regions.filter(region => unsorted.find(sortable => sortable.id === region.id));
    const newSorted = regions.filter(region => sorted.find(sortable => sortable.id === region.id))
      // sort regions based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedRegions(newUnsorted);
    setSortedRegions(newSorted);
  }), [regions]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Regions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedRegions.map(region => ({id: region.id ?? '', display: region.name}))}
          defaultOrdered={sortedRegions.map(region => ({id: region.id ?? '', display: region.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

