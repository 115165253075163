import {
  doc,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Boulder, { boulderConverter } from '../model/Boulder';

export function subscribeToBoulder(
  id: string,
  onNext: (boulder?: Boulder) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  const docRef = doc(Firestore, `boulders/${id}`).withConverter(boulderConverter);

  return (
    onSnapshot(docRef,
      (snapshot => onNext(snapshot.data())),
      onError,
      onCompletion
    )
  );
}

