import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react';

import { useQueryParam, useQueryParams, NumberParam, StringParam, BooleanParam, DelimitedNumericArrayParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { BsFilter } from 'react-icons/bs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Label from 'react-bootstrap/FormLabel'

import UserContext from './context/UserContext';
import CustomTable, { SortedHeader } from './components/CustomTable';
import Pagination from './components/Pagination';
import SearchFiltersModal from './components/SearchFiltersModal';
import { ProblemSearch, ProblemHit, ProblemFilters } from './search/index';

const undefinedFilterSet: ProblemFilters = {
  vGradeBasic: undefined,
  vGradeUnknown: undefined,
  vGradeProject: undefined,
  vGradeRange: undefined,
  qualityRange: undefined,
  styles: undefined,
  yearRange: undefined,
  unknownYear: undefined
}

// Accessors gives strings or components to render
interface ProblemDataInterface {
  id: string,
  boulderId: string,
  name: string;
  grade: string;
  faParty: string;
  faYear: string;
  qualityRating: number;
  location: string;
}

export default function Search() {
  const { user } = useContext(UserContext);
  const [searchText, setSearchText] = useState('');
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const history = useHistory();

  // query params
  const [entries, setEntries] = useQueryParam('n', NumberParam);
  const [page, setPage] = useQueryParam('p', NumberParam);
  const [filters, setFilters] = useQueryParams({
    vGradeBasic: BooleanParam,
    vGradeUnknown: BooleanParam,
    vGradeProject: BooleanParam,
    vGradeRange: DelimitedNumericArrayParam,
    qualityRange: DelimitedNumericArrayParam,
    yearRange: DelimitedNumericArrayParam,
    unknownYear: BooleanParam
  });
  const [sort, setSort] = useQueryParams({
    id: StringParam,
    direction: StringParam
  });

  const [data, setData] = useState<ProblemDataInterface[]>([]);

  const queryClient = useQueryClient();
  const { data: result } = useQuery(
    ['problemAdv', [ user, filters, searchText, page, entries, sort]], // TODO: is the second argument the dep array or something? Why does this work?
    () => {
      const correctedFilters = paramedFiltersToFilters(filters);
      Object.keys(correctedFilters).forEach(key => (correctedFilters as any)[key] === undefined && delete (correctedFilters as any)[key])

      const newSearch = new ProblemSearch(user?.hasElevatedReadAccess());

      newSearch.filterBy({...correctedFilters})
        .pageTo(page ?? 0)
        .limitTo(entries ?? 10)
        .setText(searchText);

      if (sort.id === 'name') {
        newSearch.sortBy('name', sort.direction === 'descending' ? 'descending' : 'ascending');
      } else if (sort.id === 'grade') {
        newSearch.sortBy('grade', sort.direction === 'descending' ? 'descending' : 'ascending');
      } else if (sort.id === 'quailty') {
        newSearch.sortBy('quality', sort.direction === 'descending' ? 'descending' : 'ascending');
      } else if (sort.id === 'location') {
        newSearch.sortBy('ancestry', sort.direction === 'descending' ? 'descending' : 'ascending');
      }

      return newSearch.get();
    }
  );

  useEffect(() => {
    if (result) {
      setData(result.hits.map(hit => hitResultToProblemInterface(hit)));
    } else {
      setData([]);
    }
  }, [result])

  // Invalidate query
  // TODO: I don't seem to need this with the array in the useQuery hook... why?
  useEffect(() => {
    //queryClient.invalidateQueries('problemAdv');
  }, [queryClient, user, entries, filters]);
  
  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Grade',
      accessor: 'grade'
    },
    {
      Header: 'FA\'ist',
      accessor: 'faParty'
    },
    {
      Header: 'Year Est.',
      accessor: 'faYear'
    },
    {
      Header: 'Quality',
      accessor: 'qualityRating'
    },
    {
      Header: 'Location',
      accessor: 'location'
    }
  ], []);

  // TODO: Has to be any because the callback passes a generic object.. Only was
  // I can fix this is if I provide the table with a template type.. which isn't
  // a bad idea
  const navigate = useCallback((data: any) => {
    try {
      if (!data.boulderId) {
        throw Error('No boulder id!');
      } else if (!data.id) {
        throw Error('No problem id!');
      } else {
        history.push(`/guidebook/boulder?id=${data.boulderId}#problem-${data.id}`);
      }
    } catch (err) {
      console.log('There was a problem navigating:', err);
    }
  }, [history]);

  const changeSort = useCallback((id: string) => {
    let dir = "ascending";

    if (id === sort.id) {
      dir = sort.direction === "ascending" ? "descending" : "ascending";
    }

    const newSort = {
      id: id,
      direction: dir
    }

    // TODO: Add more
    if (id !== 'name' && id !== 'grade' && id !== 'location') {
      setSort({});
      return
    }

    setSort(newSort);
  }, [sort, setSort]);

  return (
    <>
      <Container className="pt-3">
        <Row>
          <Col xs="auto">
            <Label>
              {"Show "}
              <FormControl
                as="select"
                custom
                className="d-inline-block w-auto"
                onChange={e => { setPage(0); setEntries(+e.target.value)}}
                value={entries && entries > 50 ? 10 : entries ?? 10}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </FormControl>
              {" Problems"}
            </Label>
          </Col>
          <Col />
          <Col xs="auto">
            <InputGroup>
              <FormControl type="text" placeholder="Search" onChange={e => setSearchText(e.target.value)}/>
              <InputGroup.Append>
                <Button onClick={() => setShowFiltersModal(true)}>
                  <BsFilter />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomTable
              sort={sort ? (sort as SortedHeader) : undefined}
              onHeaderClick={changeSort}
              onRowClick={navigate}
              columns={columns}
              data={data}
            />
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs="auto">
            {result &&
              <Pagination
                page={result.page}
                nPages={result.pages}
                onPaginate={p => setPage(p)}
              />
            }
          </Col>
          <Col />
        </Row>
      </Container>
      <SearchFiltersModal
        show={showFiltersModal}
        onHide={() => {setShowFiltersModal(false)}}
        onApply={f => setFilters(f)}
        onClear={() => setFilters(undefinedFilterSet)}
        filters={paramedFiltersToFilters(filters)}
      />
    </>
  );
}

// TODO: this is pretty ugly, but it'll change
function hitResultToProblemInterface(hit: ProblemHit): ProblemDataInterface {
  let faPartyString = Array.isArray(hit._faParty) ? hit._faParty.join(', ') : '';

  let gradeString = hit._indexableGrade >= 0 ? `V${hit._indexableGrade}` : null;
  if (gradeString === null) {
    switch(hit._indexableGrade) {
      case -1:
        gradeString = 'VB'
        break;
      case -2:
        gradeString = 'V?'
        break;
      case -3:
        gradeString = 'Project'
        break;
      default:
        gradeString = 'V?'
        break;
    }
  }

  let ancestryArray = hit._ancestry.split('/');
  let location = '';
  if (ancestryArray.length > 2) {
    location = ancestryArray[ancestryArray.length-2];
  }
  
  return ({
    id: hit.objectID,
    boulderId: hit.parentBoulder,
    name: hit.name,
    grade: gradeString,
    faParty: faPartyString,
    faYear: hit._faYear === -1 ? '' : hit._faYear.toString(),
    qualityRating: hit.rating,
    location: location
  });
}

function paramedFiltersToFilters(filters: any): ProblemFilters {
  let vGradeRange: number[] = [];
  filters.vGradeRange?.forEach((n: number) => {if (n !== null) {vGradeRange.push(n)}});

  let qualityRange: number[] = [];
  filters.qualityRange?.forEach((n: number) => {if (n !== null) {qualityRange.push(n)}});

  let yearRange: number[] = [];
  filters.yearRange?.forEach((n: number) => {if (n !== null) {yearRange.push(n)}});

  let correctedFilters: ProblemFilters = {
    vGradeBasic: filters.vGradeBasic ?? undefined,
    vGradeUnknown: filters.vGradeUnknown ?? undefined,
    vGradeProject: filters.vGradeProject ?? undefined,
    vGradeRange: vGradeRange.length === 2 ? vGradeRange : undefined,
    qualityRange: qualityRange.length === 2 ? qualityRange : undefined,
    yearRange: yearRange.length === 2 ? yearRange : undefined,
    unknownYear: filters.unknownYear ?? undefined
  };

  return correctedFilters;
}

