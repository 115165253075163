export function areaIndex() {
  return 'Areas'
}

export function boulderIndex() {
  return 'Boulders'
}

export function problemIndex() {
  return 'Problems'
}

export const QUICKSEARCH_INDEX = 'QuickSearch';

type SortableProblemAttributes = 'name' | 'grade' | 'safety' | 'quality' | 'ancestry';
type SortDirection = 'ascending' | 'descending';
export function sortedProblemIndex(sorter: SortableProblemAttributes, direction: SortDirection) {
  const suffix = direction === 'descending' ? 'Desc' : 'Asc'
  const sortedAttribute = capitalize(sorter);

  return `Problems${sortedAttribute}${suffix}`;

}

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

