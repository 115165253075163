import React, { useCallback } from 'react';
import { useHistory }from 'react-router-dom';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Header } from './ThemedComponents';
import Zone from '../model/Zone';

interface ZoneCardProps {
  htmlId?: string;
  zone: Zone;
  highlight?: boolean;
}

export default function ZoneCard({ htmlId, zone, highlight }: ZoneCardProps) {
  const history = useHistory();

  const navigate = useCallback(() => {
    history.push(`/guidebook/zone?id=${zone.id}`);
  }, [zone.id, history]);

  return (
    <Card className={highlight ? 'bg-secondary' : ''} id={htmlId} onClick={navigate} style={{maxWidth: '500px', cursor: 'pointer'}}>
      <Card.Img variant="top" />
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>
              <Header>{zone.name}</Header>
            </Col>
            {zone.private &&
            <Col xs="auto">
              <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
            </Col>
            }
          </Row>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

