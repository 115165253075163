import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown';

import { Note } from '../model/Note';


interface RequestsModalProps {
  notes: Note[]
  show: boolean;
  onHide: () => void;
  onSubmitInfo: (n: Note) => void;
}

export default function RequestsModal({notes, show, onHide, onSubmitInfo}: RequestsModalProps) {

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Public Info Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {notes.map(note => (
            <RequestsItem
              key={note.id}
              note={note}
              onSubmitInfo={(n: Note) => onSubmitInfo(n)}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}


interface RequestsItemProps {
  note: Note;
  onSubmitInfo: (n: Note) => void;
}

function RequestsItem({note, onSubmitInfo}: RequestsItemProps) {

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col>
            <Card.Text>
              <ReactMarkdown>
                {note.content}
              </ReactMarkdown>
            </Card.Text>
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs="auto">
            <Button
              block
              onClick={() => onSubmitInfo(note)}
            >
              Submit info
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

