import { createContext } from 'react';
import { Theme, defaultTheme } from '../themes';

interface ContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ContextType>({
  theme: defaultTheme,
  setTheme: () => {}
});

export default ThemeContext;

