import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import {
  BsPencil,
  BsSortUp,
  BsTrash,
} from 'react-icons/bs';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiImageEditFill } from 'react-icons/ri'
import { PropagateLoader } from 'react-spinners'
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useQuery, useQueryClient } from 'react-query';

import { deleteBoulder } from '../database';
import * as Database from '../database';
import UserContext from '../context/UserContext';
import Boulder from '../model/Boulder';
import Problem from '../model/Problem';
import { Title, Subtitle, Text } from './ThemedComponents';
import ProblemModal from './ProblemModal';
import BoulderModal from './BoulderModal';
import CustomIcon from '../Icon';
import EditToposModal from './EditToposModal';
import ReorderProblemsModal from './ReorderProblemsModal';
import type { Topo } from '../model/Topo';
import { isTopo } from '../model/Topo';
import TopoView from './TopoView';
import ProblemListItem from './ProblemListItem';
import SectionContainer from './ContentSectionContainer';
import NoteAccordion from './NoteAccordion';

interface Props {
  boulder: Boulder;
}

export default function BoulderContent({ boulder }: Props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const queryClient = useQueryClient();

  const [highlight, setHighlight] = useState<string>('');
  const [showBoulderModal, setShowBoulderModal] = useState(false);
  const [showNewProblemModal, setShowNewProblemModal] = useState(false);
  const [showEditToposModal, setShowEditToposModal] = useState(false);
  const [showReorderProblemsModal, setShowReorderProblemsModal] = useState(false);

  const fetchProblems = useCallback(async () => {
    return Database.getProblems(`boulders/${boulder.id}` ?? null, user?.hasElevatedReadAccess());
  }, [boulder, user]);
  const { isLoading: isLoadingProblems, data: problems } = useQuery(['problems', boulder.id, user], fetchProblems);

  const fetchTopos = useCallback(async () => {
    let t = await Database.getTopos(boulder.id ?? null);

    // if user is not privilged, only show topos that have problems they can see
    // TODO: This shoud really be in a REST request
    if (!(user?.hasElevatedReadAccess())) {
      const problemIds = problems ? new Set(problems.map(p => p.id)) : new Set();
      t = t.filter(topo => {
        const topoProblemIds = new Set(topo.topoLines.map(line => line.problemId));
        const intersection = new Set([...topoProblemIds].filter(x => problemIds.has(x)));

        return intersection.size > 0;
      });
    }
    return t;
  }, [boulder, user, problems]);
  const { isLoading: isLoadingTopos, data: topos } = useQuery(['topos', boulder.id, user, problems], fetchTopos, { enabled: !!problems });

  const setHighlightViaHash = useCallback(() => {
    const hash = window.location.hash;
    if (hash.split('-').length > 1) {
      setHighlight(hash.split('-')[1]);
    }
  }, [])

  // highlight on hash change
  useEffect(() => {
    // init
    setHighlightViaHash();
    window.addEventListener('hashchange', setHighlightViaHash, false)
    return (() => window.removeEventListener('hashchange', setHighlightViaHash, false));
  }, [setHighlightViaHash])

  // invalidate topos on change
  useEffect(() => {
    return Database.subscribeToTopos(boulder.id ?? '', () => {
      queryClient.invalidateQueries('topos');
    }, (error) => {
      console.log(error);
    });
  }, [boulder, problems, user, queryClient]);

  // invalidate problems on change
  useEffect(() => {
    return Database.subscribeToProblems(`boulders/${boulder.id}`, () => {
      queryClient.invalidateQueries('problems');
    }, (error) => {
      console.log(error);
    }, undefined, user?.hasElevatedReadAccess());
  }, [boulder, user, queryClient]);

  const handleShowBoulderModal = useCallback(() => {
    setShowBoulderModal(true);
  }, []);

  const handleCloseBoulderModal = useCallback(() => {
    setShowBoulderModal(false);
  }, []);

  const handleDeleteBoulder = useCallback(async () => {
    if (window.confirm(`Are you sure you want to delete ${boulder.name}? This cannot be undone and will leave orphans.`)) {
      await deleteBoulder(boulder.id);
      history.push('/guidebook');
    }
  }, [boulder.id, boulder.name, history]);

  return (
    <>
      {user?.hasWriteAccess() &&
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" onClick={() => setShowNewProblemModal(true)}><CustomIcon.AddProblem width="1em" height="1em"/></Button>
            <Button variant="primary" onClick={handleShowBoulderModal}><BsPencil width="1em" height="1em"/></Button>
            <Button variant="danger" onClick={handleDeleteBoulder}><BsTrash width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={() => setShowReorderProblemsModal(true)}><BsSortUp width="1em" height="1em"/></Button>
            <Button variant="primary" onClick={() => setShowEditToposModal(true)}><RiImageEditFill width="1em" height="1em"/></Button>
          </ButtonGroup>
        </ButtonToolbar>
      }

      <SectionContainer>
        <Row>
          <Col xs="auto">
            <Title>{boulder.name}</Title>
          </Col>
          {boulder.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
          </Col>
          }
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Row>
              <Col>
                <Subtitle id="description">Description</Subtitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>{ boulder?.description ?? '' }</Text>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="6">
            <Row>
              <Col>
                <Subtitle id="directions">Directions</Subtitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>{ boulder.directions }</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                {boulder.coordinates !== null &&
                  <Text><em>lat, lon:</em> {boulder.coordinates.latitude} {boulder.coordinates.longitude}</Text>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContainer>


      <SectionContainer>
        <Row>
          <Col>
            {(isLoadingProblems || isLoadingTopos) &&
            <Row>
              <Col />
              <Col xs="auto">
                <PropagateLoader size={15} color='#c28465'/>
              </Col>
              <Col />
            </Row>
            }
            {(topos && problems) &&
              <ToposAndProblemListGroup highlightId={highlight} problems={problems ?? []} topos={topos ?? []} isDeveloper={user?.hasWriteAccess()} isHelper={user?.hasElevatedReadAccess() && !(user?.hasWriteAccess())}/>
            }
          </Col>
        </Row>
      </SectionContainer>

      { user?.hasElevatedReadAccess &&
      <NoteAccordion
        parent={`boulders/${boulder.id}`}
      />
      }

      {/* Modals */}
      {user?.hasWriteAccess() &&
        <>
          <ProblemModal
            show={showNewProblemModal}
            onHide={() => setShowNewProblemModal(false)}
            parent={`boulders/${boulder.id}`}
          />
          <BoulderModal
            show={showBoulderModal}
            onHide={handleCloseBoulderModal}
            boulder={boulder}
          />
          <EditToposModal
            tempBoulder={boulder}
            show={showEditToposModal}
            onHide={() => setShowEditToposModal(false)}
            topos={topos ?? []}
            boulderId={boulder?.id ?? ''}
          />
          <ReorderProblemsModal
            problems={problems ?? []}
            show={showReorderProblemsModal}
            onHide={() => setShowReorderProblemsModal(false)}
          />
        </>
      }
    </>
  );
}

interface ToposAndProblemLGProps {
  topos: Topo[];
  problems: Problem[];
  isDeveloper?: boolean;
  isHelper?: boolean;
  highlightId?: string;
}

function ToposAndProblemListGroup({topos, problems, isDeveloper, isHelper, highlightId}: ToposAndProblemLGProps) {
  const toposAndProblems = InterleaveProblemsAndTopos(problems, topos);
  let currentProblemLabel = 1;

  return (
    <>
      {toposAndProblems.map((item) => (
        <>
          {isTopo(item) ?
          <TopoView
            key={`topo-${item.id}`}
            topo={item}
            problems={problems}
            isPrivileged={isHelper || isDeveloper}
          />
          :
          <ProblemListItem
            highlight={highlightId === item.id}
            htmlId={`problem-${item.id}`}
            key={item.id}
            label={item.order !== null ? currentProblemLabel++ : undefined}
            problem={item}
            editable={isDeveloper}
          />
          }
        </>
      ))}
    </>
  );
}

/*
 * This function takes a list of problems and a list of topos and orders them
 * such that a topo appears before the "first" problem it has a line for. All
 * topos which do not feature problems are inserted at the end of the list.
 */
function InterleaveProblemsAndTopos(problems: Problem[], topos: Topo[]): (Problem | Topo)[] {
  let extraTopos: Topo[] = []
  let ret: (Problem | Topo)[] = []

  for (let problem of problems) {
    for (let topo of topos) {
      let problemIdsOnTopo = topo.topoLines.map(line => line.problemId);
      let firstProblemInTopo = problems.find(problem => problemIdsOnTopo.includes(problem.id));
      if (firstProblemInTopo === undefined && !extraTopos.includes(topo)) {
        extraTopos.push(topo);
      } else if (firstProblemInTopo?.id === problem.id) {
        ret.push(topo);
      }
    }

    ret.push(problem);
  }

  ret.push(...extraTopos);

  return ret
}

