import React, { ChangeEvent } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import bsCustomFileInput from 'bs-custom-file-input';
import { v4 as uuidv4 } from 'uuid';

interface CustomFormImageProps {
  label?: string; // Unused at the moment
  accept?: string;
  fbStorageSrc?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
}

export default function CustomFormImage(props: CustomFormImageProps) {
  // init
  React.useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  return (
    <>
      <FormControl
        // needs to be dynamic so there are no conflicts.
        id={uuidv4()}
        type="file"
        accept={props.accept ?? "*"}
        onChange={props.onChange}
        multiple={props.multiple}
      />
    </>
  );
}

