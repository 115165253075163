import React, { useContext, useCallback } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Auth } from './Firebase';

import UserContext from './context/UserContext';
import SignInForm from './components/SignInForm';
//import SignUpForm from './components/SignUpForm';

export default function SignIn() {
  const { user } = useContext(UserContext);

  const onSignOut = useCallback(async() => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Container>
      {user &&
      <Alert variant="warning">
        <Alert.Heading>Already signed in</Alert.Heading>
        <p>
          Signing in or up again, will sign you out of your current session.
        </p>
        <Button onClick={onSignOut}>
          Sign out
        </Button>
      </Alert>
      }
      <Tabs>
        <Tab eventKey="signin" title="Sign in">
          <SignInForm />
        </Tab>
        {/* TODO Temporary hide for public release
        <Tab eventKey="signup" title="Sign up">
          <SignUpForm />
        </Tab>
        */}
      </Tabs>
    </Container>
  );
}
