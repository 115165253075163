import React, { useCallback, ReactNodeArray } from 'react';

import BPagination from 'react-bootstrap/Pagination';


interface PaginationProps {
  page: number;
  nPages: number;
  onPaginate?: (page: number) => void;
}

export default function Pagination({ page, nPages, onPaginate }: PaginationProps) {
  const paginationItems = useCallback(() => {
    let nodes: ReactNodeArray = [];
    const p = page;
    const n = nPages

    nodes = nodes.concat(<BPagination.Item key="first" active={p===0} onClick={() => onPaginate ? onPaginate(0) : null}>1</BPagination.Item>);

    if (p < 6) {
      // (2..7)(...)
      for (let i = 1; i < 7; i++) {
        if (i > n-2) {
          break;
        }
        nodes = nodes.concat(<BPagination.Item key={i} active={p===i} onClick={() => onPaginate ? onPaginate(i) : null}>{i+1}</BPagination.Item>);
      }
      if (n > 8) {
        nodes = nodes.concat(<BPagination.Ellipsis key="upper..." onClick={() => onPaginate ? onPaginate(Math.round((6+n-1)/2)) : null} />);
      }
    } else if (p > n-8) {
      // (...)(n-1)
      nodes = nodes.concat(<BPagination.Ellipsis key="lower..." onClick={() => onPaginate ? onPaginate(Math.round((n-7)/2)): null}/>);
      for (let i = n-7; i < n-1; i++) {
        nodes = nodes.concat(<BPagination.Item key={i} active={p===i} onClick={() => onPaginate ? onPaginate(i) : null}>{i+1}</BPagination.Item>)
      }
    } else {
      // (...)(p-2..p+2)(...)
      nodes = nodes.concat(<BPagination.Ellipsis key="lower..." onClick={() => onPaginate ? onPaginate(Math.round((p-2)/2)) : null}/>);

      for (let i = p-2; i < p+3; i++) {
        nodes = nodes.concat(<BPagination.Item key={i} active={p===i} onClick={() => onPaginate ? onPaginate(i) : null}>{i+1}</BPagination.Item>)
      }

      nodes = nodes.concat(<BPagination.Ellipsis key="upper..." onClick={() => onPaginate ? onPaginate(Math.round((p+n+1)/2)) : null}/>);
    }


    if (nPages > 1) {
      nodes = nodes.concat(<BPagination.Item key="last" active={p===n-1} onClick={() => onPaginate ? onPaginate(n-1) : null}>{n}</BPagination.Item>);
    }

    return nodes;
  }, [page, nPages, onPaginate])

  return (
    <BPagination>
      <>
        <BPagination.First
          disabled={page <= 0}
          onClick={() => onPaginate ? onPaginate(0) : null}
        />
        <BPagination.Prev
          disabled={page <= 0}
          onClick={() => onPaginate ? onPaginate(page-1) : null}
        />

        {paginationItems()}

        <BPagination.Next
          disabled={page >= nPages-1}
          onClick={() => onPaginate ? onPaginate(page+1) : null}
        />
        <BPagination.Last
          disabled={page >= nPages-1}
          onClick={() => onPaginate ? onPaginate(nPages-1) : null}
        />
      </>
    </BPagination>
  );
}
