import React, { useContext, useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  BsCameraFill,
  BsPencil,
  BsTrash,
  BsCheck2Square
} from 'react-icons/bs';
import Masonry from 'react-masonry-css'
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import bsCustomFileInput from 'bs-custom-file-input';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useInfiniteQuery } from 'react-query';
import Lightbox from 'react-awesome-lightbox';
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable
} from 'firebase/storage';

import UserContext from './context/UserContext';
import { Storage, ImageStorage } from './Firebase';
import Loading from './components/Loading';
import Photo, { PhotoMarking } from './model/Photo';
import { createPhoto, getPhotos, updatePhoto, deletePhoto } from './database/index';

export default function Guidebook() {
  const { user } = useContext(UserContext);
  const [showLightbox, setShowLightbox] = useState(false);
  const [lightboxData, setLightboxData] = useState<{pid: string, title: string, url: string}[]>([])
  const [initialLightboxIndex, setInitialLightboxIndex] = useState(0);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const photoQuery = useInfiniteQuery('gallery-photos', async ({ pageParam = null }) => {
    return await getPhotos(6, pageParam);
  }, {
    getNextPageParam: ([, lastSnapshot]) => lastSnapshot
  });

  // get download-urls -- couldn't figure out dependent react-query in this case
  useEffect(() => {
    (async () => {
      if (!photoQuery?.data) {
        return;
      }

      // get downloadUrls
      let photos = photoQuery.data?.pages.map(([photos,]) => photos);
      let flatPhotos = photos.reduce((flat, toFlatten) => {
        return [...flat, ...toFlatten];
      });

      let photoRefs = await Promise.all(flatPhotos.map(photo => listAll(ref(ImageStorage, `${photo.pid}`))));
      let downloadUrls = await Promise.all(photoRefs.map(children => {
        const largeRef = children.items.find(child => child.name.includes('large'));
        if (largeRef) {
          return getDownloadURL(largeRef);
        } else {
          return ''
        }
      }));

      setLightboxData(downloadUrls.map((url, index) => ({pid: flatPhotos[index].pid, url: url ?? '', title: flatPhotos[index].caption})))
    })();
  }, [photoQuery?.data])

  const handleOpenLightbox = useCallback((pid: string) => {
    setShowLightbox(true);
    setInitialLightboxIndex(lightboxData.findIndex(data => data.pid === pid));
  }, [lightboxData])

  if (user === undefined) {
    return (
      <Loading />
    );
  } else if (!(user?.isPhotographer())) {
    return (
      <Redirect
        to="/permission"
      />
    );
  }

  // I wish I could not do this every render...
  let justPhotos = photoQuery.data?.pages.map(([photos]) => photos);
  let flatPhotos = justPhotos?.reduce((flat, toFlatten) => ([...flat, ...toFlatten]));

  return (
    <>
      <Container className="mt-3">
        <Row>
          <Col xs="auto">
            {user?.isPhotographer() &&
            <Button onClick={() => setShowAddPhotoModal(true)}><BsCameraFill /></Button>
            }
          </Col>
          <Col />
          <Col xs="auto">
            <FormControl placeholder="Search" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Masonry
            breakpointCols={3}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {flatPhotos?.map(photo => <PhotoCard photo={photo} onClick={() => handleOpenLightbox(photo.pid)}/>)}
          </Masonry>
        </Row>
        <Row>
          <Col />
          <Col xs="auto">
            <Button
              disabled={!photoQuery.hasNextPage || photoQuery.isFetching}
              onClick={() => photoQuery.fetchNextPage()}
            >
              Load More
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
      {showAddPhotoModal &&
      <AddPhotoModal show={showAddPhotoModal} onHide={() => setShowAddPhotoModal(false)}/>
      }
      {showLightbox &&
        <Lightbox
          onClose={() => setShowLightbox(false)}
          images={lightboxData}
          startIndex={initialLightboxIndex}
        />
      }
    </>
  );
}

interface PhotoCardProps {
  photo: Photo;
  onClick?: (pid: string) => void;
}

function PhotoCard({photo, onClick}: PhotoCardProps) {
  const { user } = useContext(UserContext);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditMarkingsModal, setShowEditMarkingsModal] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);

  useEffect(() => {
    // TODO consider using React Query to avoid race conditions
    (async () => {
      try {
        const children = await listAll(ref(ImageStorage,`${photo.pid}`));
        const mediumChild = children.items.find(child => child.name.includes('medium'));
        if (mediumChild) {
          setDownloadUrl(await getDownloadURL(mediumChild));
        }
      } catch(err) {
        console.log(`There was an error getting download url for image ${photo.pid}`, err);
        return undefined;
      }
    })()
  }, [photo.pid]);

  const handleMouseEnter = useCallback(() => {
    if (user?.isAdmin() || (user?.isPhotographer() && user.firebaseUser.uid === photo.uid)) {
      setShowControlPanel(true);
    }
  }, [user, photo]);

  const handleMouseLeave = useCallback(() => {
    setShowControlPanel(false);
  }, []);

  const handleEdit = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const handleEditMarkings = useCallback(() => {
    setShowEditMarkingsModal(true);
  }, []);

  const handleDelete = useCallback(() => {
    const yes = window.confirm(`Are you sure you want to delete ${photo.caption}`);
    if (yes) {
      deletePhoto(photo);
    }
  }, [photo]);

  return (
    <>
      <Card
        style={{position: 'relative'}}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showControlPanel &&
        <Row className="mt-1 mr-1" style={{position: 'absolute', top: 0, left: 0, right: 0}}>
          <Col />
          <Col xs="auto">
            <ButtonGroup>
              {(user?.isAdmin() || user?.firebaseUser.uid === photo.uid) &&
              <Button onClick={handleEdit}>
                <BsPencil />
              </Button>
              }
              {(user?.isAdmin()) &&
              <Button>
                <BsCheck2Square onClick={handleEditMarkings}/>
              </Button>
              }
              {(user?.isAdmin()) &&
              <Button variant="danger">
                <BsTrash onClick={handleDelete}/>
              </Button>
              }
            </ButtonGroup>
          </Col>
        </Row>
        }
        {downloadUrl &&
        <Card.Img src={downloadUrl} onClick={() => onClick ? onClick(photo.pid) : null} style={{cursor: 'pointer'}} />
        }
        <blockquote className="blockquote mb-0 card-body">
          <p>{photo.caption}</p>
          <footer className="blockquote-footer">
            <small className="text-muted">
              Photo by <cite>{photo.photographer}</cite>
            </small>
          </footer>
        </blockquote>
      </Card>
      {showEditModal &&
      <EditPhotoModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        photo={photo}
      />
      }
      {showEditMarkingsModal &&
      <EditMarkingsModal
        show={showEditMarkingsModal}
        onHide={() => setShowEditMarkingsModal(false)}
        photo={photo}
      />
      }
    </>
  );
}

interface AddPhotoModalProps {
  show: boolean;
  onHide: () => void;
}

// TODO: On close
//         Cancel upload
function AddPhotoModal({show, onHide}: AddPhotoModalProps) {
  const { user } = useContext(UserContext);
  const [pid, setPid] = useState('');
  const [caption, setCaption] = useState('');
  const [photographer, setPhotographer] = useState('');
  const [progress, setProgress] = useState(0);
  const uploadPhotoMutation = useMutation(async ({ id, file }: { id: string; file: File }) => {
    const ext = file.name.split('.').pop();

    const newImageRef = ref(Storage, `uploaded_images/${id}.${ext}`);

    let uploadTask = uploadBytesResumable(newImageRef, file);

    // update progress bar
    uploadTask.on('state_changed', snapshot => {
      setProgress(snapshot.bytesTransferred / snapshot.totalBytes * 100);
    });

    return await uploadTask;
  });

  const addPhoto = useMutation(async (photo: Photo) => {
    return await createPhoto(photo);
  });

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target?.files ? e.target.files[0] : null;
    
    if (file) {
      const pid = uuidv4();
      uploadPhotoMutation.mutate({id: pid, file: file});
      setPid(pid);
    }
  }, [uploadPhotoMutation]);

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleAdd = useCallback(() => {
    const data: Photo = {
      id: '',
      pid: pid,
      uid: user?.firebaseUser.uid ?? '',
      caption: caption,
      photographer: photographer,
      markings: []
    };

    addPhoto.mutate(data);
  }, [pid, caption, photographer, user, addPhoto]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>Add Gallery Photo</Modal.Title>
      </Modal.Header>
      {addPhoto.isIdle && 
      <>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Photo</FormLabel>
            {!pid &&
            <FormControl
              id={uuidv4()}
              type="file"
              label="Upload image"
              accept={"image/*"}
              onChange={handleFileChange}
              custom
            />
            }
            {uploadPhotoMutation.isLoading &&
            <ProgressBar now={progress} />
            }
            {uploadPhotoMutation.isSuccess &&
              <Alert variant="success">Successfully uploaded image.</Alert>
            }
            {uploadPhotoMutation.isError &&
              <Alert variant="danger">Failed to  upload image.</Alert>
            }
          </FormGroup>
          <FormGroup>
            <FormLabel>Caption</FormLabel>
            <FormControl
              placeholder="John Gill on The Thimble, circa 1961"
              onChange={e => {setCaption(e.target.value)}}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Photographer</FormLabel>
            <FormControl
              placeholder="John Doe"
              onChange={e => {setPhotographer(e.target.value)}}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" disabled={!pid || !uploadPhotoMutation.isSuccess || !photographer || !caption} onClick={handleAdd}>Add</Button>
        </Modal.Footer>
      </>
      }
      {addPhoto.isLoading &&
      <Modal.Body>
        <Loading />
      </Modal.Body>
      }
      {addPhoto.isSuccess &&
      <>
        <Modal.Body>
          <Alert variant="success">Successfully added photo to gallery.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
      {addPhoto.isError &&
      <>
        <Modal.Body>
          <Alert variant="danger">An error occurred while adding the photo to the gallery.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
    </Modal>
  );
}

interface EditPhotoModalProps {
  show: boolean;
  onHide: () => void;
  photo: Photo;
}

function EditPhotoModal({show, onHide, photo}: EditPhotoModalProps) {
  const [caption, setCaption] = useState(photo.caption);
  const [photographer, setPhotographer] = useState(photo.photographer);

  const editPhoto = useMutation(async (photo: Photo) => {
    return await updatePhoto(photo);
  });

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleEdit = useCallback(() => {
    const data: Photo = {
      ...photo,
      caption: caption,
      photographer: photographer
    };

    editPhoto.mutate(data);
  }, [photo, caption, photographer, editPhoto]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>Edit Gallery Photo</Modal.Title>
      </Modal.Header>
      {editPhoto.isIdle && 
      <>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Caption</FormLabel>
            <FormControl
              placeholder="John Gill on The Thimble, circa 1961"
              onChange={e => {setCaption(e.target.value)}}
              value={caption}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Photographer</FormLabel>
            <FormControl
              placeholder="John Doe"
              onChange={e => {setPhotographer(e.target.value)}}
              value={photographer}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" disabled={!photographer || !caption} onClick={handleEdit}>Update</Button>
        </Modal.Footer>
      </>
      }
      {editPhoto.isLoading &&
      <Modal.Body>
        <Loading />
      </Modal.Body>
      }
      {editPhoto.isSuccess &&
      <>
        <Modal.Body>
          <Alert variant="success">Successfully updated the photo.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
      {editPhoto.isError &&
      <>
        <Modal.Body>
          <Alert variant="danger">An error occurred while updating the photo.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
    </Modal>
  );
}

function EditMarkingsModal({show, onHide, photo}: EditPhotoModalProps) {
  const [addType, setAddType] = useState('action');
  const [addDocType, setAddDocType] = useState('areas');
  const [addDocId, setAddDocId] = useState('');
  const [markings, setMarkings] = useState(photo.markings);

  const editPhoto = useMutation(async (photo: Photo) => {
    return await updatePhoto(photo);
  });

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleEdit = useCallback(() => {
    const data: Photo = {
      ...photo,
      markings: markings
    };

    editPhoto.mutate(data);
  }, [photo, markings, editPhoto]);

  const handleAddMarking = useCallback(() => {
    if (addType === 'action' || addType === 'scenic' || addType === 'feature') {
      const newMarking: PhotoMarking = {
        parent: `${addDocType}/${addDocId}`,
        type: addType
      }

      setMarkings([...markings, newMarking]);

      setAddDocId('');
    } else {
      console.log('Something went wrong creating new marking');
    }
  }, [addType, addDocId, addDocType, markings]);

  const handleRemoveMarking = useCallback((index: number) => {
    let newMarkings = [...markings];
    newMarkings.splice(index, 1);
    setMarkings(newMarkings);
  }, [markings]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>Edit Gallery Photo</Modal.Title>
      </Modal.Header>
      {editPhoto.isIdle && 
      <>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Current Markings</FormLabel>
            {markings.length ?
            <ListGroup>
              { markings.map((mark, i) => (
              <ListGroup.Item>
                <Row>
                  <Col>
                    {mark.type} at {mark.parent}
                  </Col>
                  <Col />
                  <Col xs="auto">
                    <Button onClick={() => handleRemoveMarking(i)} variant="danger"><BsTrash /></Button>
                  </Col>
                </Row>
              </ListGroup.Item>
              ))}
            </ListGroup>
            :
            <>
              <p>Currently no markings</p>
            </>
            }
          </FormGroup>
          <FormGroup>
            <FormLabel>Add Marking</FormLabel>
            {/* TODO This should provide a navigator to attach it to a specific path */}
            <FormControl value={addType} onChange={e => setAddType(e.target.value)} as="select">
              <option value="action">Action</option>
              <option value="scenic">Scenic</option>
              <option value="feature">Feature</option>
            </FormControl>
            <Row>
              <Col>
                <FormControl value={addDocType} onChange={e => setAddDocType(e.target.value)} as="select">
                  <option value="regions">Region</option>
                  <option value="areas">Area</option>
                  <option value="sectors">Sector</option>
                  <option value="zones">Zone</option>
                  <option value="problems">Problem</option>
                </FormControl>
              </Col>
              <Col>
                <FormControl value={addDocId} placeholder="id" onChange={e => setAddDocId(e.target.value)} />
              </Col>
            </Row>
            <div className="d-grid gap-2">
              <Button disabled={!addDocId} onClick={handleAddMarking}>Add</Button>
            </div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleEdit}>Update</Button>
        </Modal.Footer>
      </>
      }
      {editPhoto.isLoading &&
      <Modal.Body>
        <Loading />
      </Modal.Body>
      }
      {editPhoto.isSuccess &&
      <>
        <Modal.Body>
          <Alert variant="success">Successfully updated the photo.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
      {editPhoto.isError &&
      <>
        <Modal.Body>
          <Alert variant="danger">An error occurred while updating the photo.</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </>
      }
    </Modal>
  );
}
