import {
  doc,
  getDoc
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { sectorConverter } from '../model/Sector';

export async function getSector(
  id: string
) {
  let docRef = doc(Firestore, `sectors/${id}`).withConverter(sectorConverter);

  return (await getDoc(docRef)).data();
}

