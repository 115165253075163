import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'


interface FeedbackData {
  issuer: string; // user id
  content: string;
  url: string; // url the feedback was sent from
  resolved: boolean;
  resolvedTime?: number; // time since epoch
}

interface Feedback extends FeedbackData {
  id: string;
}

const FeedbackConverter = {
  toFirestore(feedback: Feedback): DocumentData {
    let copy = { ...feedback };
    delete copy.id;

    if (!copy.resolved) {
      delete copy.resolvedTime;
    }

    return { ...copy };
  }, 
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Feedback {
    const data = snapshot.data(options);
    const feedback: Feedback = {
      id: snapshot.id,
      content: data.content ?? '',
      url: data.url ?? '',
      issuer: data.issuer ?? '',
      resolved: data.resolved ?? false,
      resolvedTime: data.resolvedTime ?? -1
    }

    return feedback;
  }
}

export { FeedbackConverter };
export type { FeedbackData };
export default Feedback;

