import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';

import { Auth } from '../Firebase';

export default function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const onSignInWithEmail = useCallback(async () => {
    try {
      setError('');
      setLoading(true);

      await (Auth.signInWithEmailAndPassword(email, password));
    } catch (e) {
      setError(e.message ?? 'Encountered a problem signing in.')
    } finally {
      setLoading(false);
    }
  }, [email, password]);

  return (
    <>
      {error &&
      <Alert variant="danger">
        <Alert.Heading>
          Error
        </Alert.Heading>
        <p>
          {typeof(error) === 'string' ? error : (error as Error).message ?? 'There was an issue.'}
        </p>
      </Alert>
      }
      <Form>
        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Button
            disabled={loading}
            variant="primary"
            onClick={onSignInWithEmail}
            block
          >
            Sign In
          </Button>
        </Form.Group>
      </Form>
    </>
  );
}
