import RectBoundingBox from '../model/RectBoundingBox';
import BezierChain from '../model/BezierChain';

export function drawBezierChain(ctx:CanvasRenderingContext2D, chain: BezierChain, bounds: RectBoundingBox) {
  const {
    offsetX, offsetY,
    width, height
  } = bounds;

  ctx.beginPath();
  for (let i = 0; i < chain.points.length-1; i++) {
    const p1 = chain.points[i];
    const p2 = chain.points[i+1];
    ctx.moveTo(offsetX + width*p1.x, offsetY + height*p1.y);
    ctx.bezierCurveTo(offsetX + width*p1.cx2, offsetY + height*p1.cy2,
                      offsetX + width*p2.cx1, offsetY + height*p2.cy1,
                      offsetX + width*p2.x, offsetY + height*p2.y);
  }
  ctx.stroke();
}

export function getMousePos(canvas: HTMLCanvasElement, clientX: number, clientY: number) {
  const rect = canvas.getBoundingClientRect();
  const dpr = window.devicePixelRatio || 1;
  return {
    x: (clientX - rect.left) * dpr,
    y: (clientY - rect.top) * dpr
    };
}

