import { doc, onSnapshot } from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Region, { regionConverter } from '../model/Region';

export function subscribeToRegion(
  id: string,
  onNext: (area?: Region) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  let docRef = doc(Firestore, `regions/${id}`).withConverter(regionConverter);

  return (
    onSnapshot(docRef,
      snapshot => onNext(snapshot.data()),
      onError,
      onCompletion
    )
  );
}

