import React from 'react';

export default function NoPageFound() {
  return (
    <div>
      <h2>Uh Oh...</h2>
      <p>Looks like you've tried to access a page that doesn't exist.</p>
    </div>
  )
}
