import { User as FBUser } from '@firebase/auth-types';


export enum UserRole {
  Basic = 'basic',
  CoalitionMember = 'coalition',
  Paid = 'paid',
  Elevated = 'elevated',
  Developer = 'developer',
  Admin = 'admin'
}

export default class User implements IUser {
  email: string;
  verified: boolean;
  role: UserRole;
  photographer: boolean;
  firebaseUser: FBUser;

  constructor(i: IUser) {
    this.email = i.email;
    this.verified = i.verified;
    this.role = i.role;
    this.photographer = i.photographer;
    this.firebaseUser = i.firebaseUser;
  }

  hasGuideAccess() {
    return (
      this.role === UserRole.CoalitionMember ||
      this.role === UserRole.Paid ||
      this.role === UserRole.Elevated ||
      this.role === UserRole.Developer ||
      this.role === UserRole.Admin
    );
  }

  hasElevatedReadAccess() {
    return (
      this.role === UserRole.Elevated ||
      this.role === UserRole.Developer ||
      this.role === UserRole.Admin
    );
  }

  hasWriteAccess() {
    return (
      this.role === UserRole.Developer ||
      this.role === UserRole.Admin
    );
  }

  isAdmin() {
    return (
      this.role === UserRole.Admin
    );
  }

  isPhotographer() {
    return (
      this.role === UserRole.Admin ||
      this.photographer
    );
  }
}

interface IUser {
  email: string;
  verified: boolean;
  role: UserRole;
  photographer: boolean;
  firebaseUser: FBUser;
}

export async function UserFromFirebaseUser(fbUser: FBUser | null): Promise<User | null> {
  if (fbUser === null) {
    return null;
  }

  const claims = (await fbUser?.getIdTokenResult()).claims;

  return new User({
    email: fbUser.email ?? '',
    verified: fbUser.emailVerified ?? false,
    role: claims.role ?? UserRole.Basic,
    photographer: claims.photographer ?? false,
    firebaseUser: fbUser
  });
}

