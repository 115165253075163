import {
  collection,
  where,
  query,
  orderBy,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { boulderConverter } from '../model/Boulder';

export async function getBoulders(
  parent: string,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'boulders').withConverter(boulderConverter);
  let q = query(colRef, where('parent', '==', parent))

  if (!isPrivileged) {
    q = query(q, where('public', '==', true))
  }

  q = query(q, orderBy('order'));

  return (await getDocs(q)).docs.map(doc => doc.data());
}

