import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Boulder, { boulderConverter } from '../model/Boulder';

export function subscribeToBoulders(
  parent: string,
  onNext: (boulders: Boulder[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'boulders').withConverter(boulderConverter);
  let colQuery = query(colRef, where('parent', '==', parent));

  if (!isPrivileged) {
    colQuery = query(colQuery, where('public', '==', true))
  }

  colQuery = query(colQuery, orderBy('order'));

  return (
    onSnapshot(colQuery,
      querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data())),
      onError,
      onCompletion
    )
  );
}

