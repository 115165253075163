import { SearchOptions } from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch';

import client from './searchClient';
import { AreaHit } from './hitTypes'
import { areaIndex } from './indeces';


export interface AreaFilters {
  ancestryId?: string;
}

export class AreaSearch {
  filters?: AreaFilters;
  text?: string;
  page?: number;
  maxPage?: number;

  private _index: SearchIndex;
  private _privileged: boolean;

  constructor(privileged=false) {
    this._index = client.initIndex(areaIndex());
    this._privileged = privileged;
  }

  filterBy(filters: AreaFilters) {
    this.filters = filters;
    return this;
  }

  setText(s: string) {
    this.text = s;
    return this;
  }

  pageTo(page: number) {
    this.page = page;
    return this;
  }

  limitTo(max: number) {
    this.maxPage = max;
    return this;
  }

  private _convertFilters(filters: AreaFilters): string {
    const outerFilters: string[] = [];

    // ancestry filters
    if (filters.ancestryId) {
      outerFilters.push(`(_ancestryIds:${filters.ancestryId})`);
    }
    // end ancestry filters

    // public filters
    if (!this._privileged) {
      outerFilters.push(`(public:true)`);
    }
    // end public filters

    // combine filters
    return outerFilters.join(' AND ');
  }

  async get() {
    const searchOptions: SearchOptions = {
      page: this.page ?? 0,
      hitsPerPage: this.maxPage ?? 10,
      filters: this.filters ? this._convertFilters(this.filters) : undefined
    };

    let { hits, nbPages, page, nbHits } = await this._index.search(this.text ?? '', searchOptions);

    return ({
      hits: hits as AreaHit[],
      page: page,
      pages: nbPages,
      nbHits: nbHits
    });
  }
}

