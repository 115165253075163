import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import UserContext from '../context/UserContext';
import { ProblemSearch } from '../search/index';


interface ProblemCountProps {
  ancestryPath: string | null;
}

export default function ProblemCount({ ancestryPath }: ProblemCountProps) {
  const { user } = useContext(UserContext);
  const { data: problemCount } = useQuery(
    ['problemCount', [ ancestryPath ]],
    () => {
      let problemSearch = new ProblemSearch(user?.hasElevatedReadAccess());

      if (ancestryPath) {
        problemSearch.filterBy({ ancestryId: ancestryPath });
      }

      return (
        problemSearch.get()
          .then(data => data.nbHits)
          .catch(e => { console.log(e); return null; })
      );
    }
  );

  return (
    <>
      {problemCount ?? '-'}
    </>
  );
}

