import { doc, onSnapshot } from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Zone, { zoneConverter } from '../model/Zone';

export function subscribeToZone(
  id: string,
  onNext: (zone?: Zone) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  let docRef = doc(Firestore, `zones/${id}`).withConverter(zoneConverter);

  return (
    onSnapshot(docRef,
      snapshot => onNext(snapshot.data()),
      onError,
      onCompletion
    )
  );
}

