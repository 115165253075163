import {
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  WithFieldValue
} from 'firebase/firestore'
import { Firestore } from '../Firebase';
import { ProblemData, problemConverter } from '../model/Problem';
import { VGradeValidator } from '../model/Grade';


export async function createProblem(problem: ProblemData) {
  return addDoc(collection(Firestore, 'problems').withConverter(problemConverter), problem);
}

export async function updateProblem(id: string, data: WithFieldValue<ProblemData>) {
  // TODO Cannot use withConverter for some reason... so instead I'll do this validation here...
  if (typeof(data.grade) === 'string') {
    if (!(new VGradeValidator().isAcceptable(data.grade))) {
      data.grade = 'V?'
    }
  }

  return updateDoc(doc(Firestore, `problems/${id}`), data);
}

export async function deleteProblem(id: string) {
  return deleteDoc(doc(Firestore, `problems/${id}`));
}

