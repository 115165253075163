import {
  collection,
  getDocs
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { FeedbackConverter } from '../model/Feedback';

export async function getFeedback() {
  const colRef = collection(Firestore, 'feedback').withConverter(FeedbackConverter);
  return (await getDocs(colRef)).docs.map(doc => doc.data());
}


