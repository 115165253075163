import { doc, onSnapshot } from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Sector, { sectorConverter } from '../model/Sector';

export function subscribeToSector(
  id: string,
  onNext: (sector?: Sector) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  let docRef = doc(Firestore, `sectors/${id}`).withConverter(sectorConverter);

  return (
    onSnapshot(docRef,
      snapshot => onNext(snapshot.data()),
      onError,
      onCompletion
    )
  );
}

