import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { ZoneData } from '../model/Zone';


export async function createZone(zone: ZoneData): Promise<void> {
  await FirestoreV8Compat.collection('zones').add(zone);
  // returning void so all of these have the same return type
  return;
}

export async function updateZone(id: string, data: UpdateData<ZoneData>) {
  return FirestoreV8Compat.doc(`zones/${id}`).update(data);
}

export async function deleteZone(id: string) {
  return FirestoreV8Compat.doc(`zones/${id}`).delete();
}

