import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';

import * as Database from '../database/index';
import UserContext from '../context/UserContext';
import Area, { isArea } from '../model/Area';
import Boulder, { isBoulder } from '../model/Boulder';
import Problem from '../model/Problem';
import type { Topo } from '../model/Topo';

// TODO: Use <Link to="" replace /> for the # links

const ToCListItem = styled.li`
& div {
  display: inline-block;
  color: var(--primary);
  font-size: 0.875rem;
  padding: 0.125rem 1.5rem;
  text-decoration: none;
  filter: grayscale(1);
}
& a {
  display: inline-block;
  color: var(--primary);
  font-size: 0.875rem;
  padding: 0.125rem 1.5rem;
  text-decoration: none;
  filter: grayscale(1);
}
& a:hover {
  display: inline-block;
  color: var(--primary);
  font-size: 0.875rem;
  padding: 0.125rem 1.5rem;
  text-decoration: none;
  filter: grayscale(0);
}
`

interface ToCProps {
  item: Area | Boulder | null;
}

export default function TableOfContentsProvider({ item }: ToCProps) {
  if (isArea(item ?? {})) {
    return <AreaToC area={item as Area} />
  }

  if (isBoulder(item ?? {})) {
    return <BoulderToC boulder={item as Boulder} />
  }

  return null;
}


interface AreaToCProps {
  area: Area;
}

function AreaToC({ area }: AreaToCProps) {
  const [subareas] = useState<Area[]>([]);
  const [boulders, setBoulders] = useState<Boulder[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      //const subareasPromise = Database.getSubareas(area.id ?? '', user?.hasElevatedReadAccess());
      const bouldersPromise = Database.getBoulders(area.id ?? '', user?.hasElevatedReadAccess());
      //const [subareas, boulders] = await Promise.all([subareasPromise, bouldersPromise]);
      const [boulders] = await Promise.all([bouldersPromise]);
      //setSubareas(subareas);
      setBoulders(boulders);
    })();
  }, [area, user]);

  return (
    <ul className="list-unstyled border-left">
      <ToCListItem>
        <a href="#description">Description</a>
      </ToCListItem>
      <ToCListItem>
        <a href="#directions">Directions</a>
      </ToCListItem>
      <ToCListItem>
        <a href="#map">Map</a>
      </ToCListItem>
      <ToCListItem>
        <a href="#rocktypes">Rock Type(s)</a>
      </ToCListItem>
      {subareas.length > 0 &&
      <>
        <ToCListItem>
          <div>Subareas</div>
        </ToCListItem>
        <ul className="list-unstyled pl-3">
        {subareas.map(subarea => {
          return (
            <ToCListItem key={`subarea-toc-${subarea.id}`}>
              <a href={`#subarea-${subarea.id}`}>{subarea.name}</a>
            </ToCListItem>
          );
        })}
        </ul>
      </>
      }
      {boulders.length > 0 &&
      <>
        <ToCListItem>
          <div>Boulders</div>
        </ToCListItem>
        <ul className="list-unstyled pl-3">
        {boulders.map(boulder => {
          return (
            <ToCListItem key={`boulder-toc-${boulder.id}`}>
              <a href={`#boulder-${boulder.id}`}>{boulder.name}</a>
            </ToCListItem>
          );
        })}
        </ul>
      </>
      }
    </ul>
  );
}

interface BoulderToCProps {
  boulder: Boulder;
}

function BoulderToC({ boulder }: BoulderToCProps) {
  const [problems, setProblems] = useState<Problem[]>([]);
  const [topos, setTopos] = useState<Topo[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const problemsPromise = Database.getProblems(`boulders/${boulder.id}` ?? '', user?.hasElevatedReadAccess());
      const toposPromise = Database.getTopos(boulder.id ?? '');
      let [problems, topos] = await Promise.all([problemsPromise, toposPromise]);
      // only include topos that have problems on them for non authd users
      if (!(user?.hasElevatedReadAccess())) {
        const problemIds = new Set(problems.map(problem => problem.id));
        topos = topos.filter(topo => {
          // if I can find a problem in the topo, then we include it
          const topoProblems = new Set(topo.topoLines.map(line => line.problemId));
          const intersection = new Set([...problemIds].filter(x => topoProblems.has(x ?? '')));
          return intersection.size > 0;
        });
      }
      setProblems(problems);
      setTopos(topos);
    })();
  }, [boulder, user]);

  return (
    <ul className="list-unstyled border-left">
      <ToCListItem>
        <a href="#description">Description</a>
      </ToCListItem>
      <ToCListItem>
        <a href="#directions">Directions</a>
      </ToCListItem>
      {problems.length > 0 &&
      <>
        <ToCListItem>
          <div>Problems</div>
        </ToCListItem>
        <ul className="list-unstyled pl-3">
        {problems.map(problem => {
          return (
            <ToCListItem key={`problem-toc-${problem.id}`}>
              <a href={`#problem-${problem.id}`}>{problem.name}</a>
            </ToCListItem>
          );
        })}
        </ul>
      </>
      }
      {topos.length > 0 &&
      <>
        <ToCListItem>
          <div>Topos</div>
        </ToCListItem>
        <ul className="list-unstyled pl-3">
        {topos.map(topo => {
          return (
            <ToCListItem key={`topo-toc-${topo.id}`}>
              <a href={`#topo-${topo.id}`}>{topo.caption ? topo.caption : '<Placeholder>'}</a>
            </ToCListItem>
          );
        })}
        </ul>
      </>
      }
    </ul>
  );
}

