import React, { useEffect, useCallback } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';

interface SortableObject {
  display: string; // what is displayed
  id: string; // should be unique
}

export type ReorderChangeEvent = (unsorted: SortableObject[], sorted: SortableObject[]) => void;

interface Props {
  defaultUnordered: SortableObject[];
  defaultOrdered: SortableObject[];
  onChange?: ReorderChangeEvent;
}

const UNSORTED_LIST_ID = 'unsortedProblems';
const SORTED_LIST_ID = 'sortedProblems'

export default function ReorderControl({defaultUnordered, defaultOrdered, onChange}: Props) {
  const [unordered, setUnordered] = React.useState<SortableObject[]>(defaultUnordered);
  const [ordered, setOrdered] = React.useState<SortableObject[]>(defaultOrdered);

  const onDragEnd = useCallback((result) => {
    if (!result.destination || !result.source) {
      return;
    }

    const newUnordered = Array.from(unordered);
    const newOrdered = Array.from(ordered);

    let moved: SortableObject;

    // splice problem from source
    if (result.source.droppableId === UNSORTED_LIST_ID) {
      moved = newUnordered.splice(result.source.index, 1)[0];
    } else {
      moved = newOrdered.splice(result.source.index, 1)[0];
    }

    // splice problem into destination
    if (result.destination.droppableId === UNSORTED_LIST_ID) {
      newUnordered.splice(result.destination.index, 0, moved);
    } else {
      newOrdered.splice(result.destination.index, 0, moved);
    }

    setUnordered(newUnordered);
    setOrdered(newOrdered);
  }, [unordered, ordered]);

  useEffect(() => {
    if (onChange) {
      onChange(unordered, ordered);
    }
  }, [unordered, ordered, onChange]);

  return (
    <Container>
      <Row>
        <Col>
          <h5>Unordered</h5>
        </Col>
        <Col>
          <h5>Ordered</h5>
        </Col>
      </Row>
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Col>
            <Droppable droppableId={UNSORTED_LIST_ID}>
              {(provided, snapshot) => {
                return (
                  <ListGroup
                    style={{height: '100%'}}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {unordered.map((sortable, index) => <DraggableListItem index={index} sortable={sortable} /> )}
                    {provided.placeholder}
                  </ListGroup>
                );
              }}
            </Droppable>
          </Col>
          <Col>
            <Droppable droppableId={SORTED_LIST_ID}>
              {(provided, snapshot) => {
                return (
                  <ListGroup
                    style={{height: '100%'}}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {ordered.map((sortable, index) => <DraggableListItem index={index} sortable={sortable} /> )}
                    {provided.placeholder}
                  </ListGroup>
                );
              }}
            </Droppable>
          </Col>
        </DragDropContext>
      </Row>
    </Container>
  );
}

interface ProblemListItemProps {
  sortable: SortableObject;
  index: number
}

function DraggableListItem({sortable, index}: ProblemListItemProps) {
  return (
    <Draggable key={sortable.id} draggableId={sortable.id} index={index}>
      {(provided, snapshot) => {
        return (
          <ListGroup.Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {sortable.display}
          </ListGroup.Item>
        );
      }}
    </Draggable>
  );
}

export type { SortableObject };

