import React, { useState, useCallback } from 'react';

import { useMutation } from 'react-query';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import ProblemCollection from '../model/ProblemCollection';
import { createProblemCollection } from '../database/index';


interface Props {
  show : boolean;
  onHide: () => void;
  parentArea: string | null;
}

export default function NewProblemCollectionModal({show, onHide, parentArea}: Props) {
  const [name, setName] = useState('');
  const [attachedToArea, setAttachedToArea] = useState(parentArea ? true : false);
  const createMutation = useMutation(createProblemCollection);

  const handleSave = useCallback(async () => {
    let collection: ProblemCollection = {
      id: '',
      name: name,
      problems: [],
      parentArea: attachedToArea ? parentArea : null
    };

    try {
      await createMutation.mutateAsync(collection);
    } catch (err) {
      console.log(err);
    } finally {
      onHide();
    }
  }, [name, attachedToArea, parentArea, createMutation, onHide]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header>New Problem Collection</Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              onChange={e => setName(e.target.value)}
              placeholder="Area Classics"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              disabled={parentArea === null}
              defaultChecked={parentArea ? true : false}
              label="Belongs to area"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAttachedToArea(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={createMutation.isLoading} variant="secondary" onClick={onHide}>Close</Button>
        <Button disabled={createMutation.isLoading} variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
