import React from "react";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import FormControl from "react-bootstrap/FormControl";
import Button from 'react-bootstrap/Button';

import { FirestoreV8Compat } from '../Firebase';
import Area from "../model/Area";

interface DeleteAreaModalProps {
  show: boolean;
  onHide: () => void;
  area: Area | undefined;
}

export default function DeleteAreaModal(props: DeleteAreaModalProps) {
  const confirmTextRef = React.createRef<HTMLInputElement>();
  const [canDelete, setCanDelete] = React.useState(false);

  const onConfirmTextChange = () => {
    if (props.area) {
      const confirmTextInput = confirmTextRef.current!;
      if (props.area.name === confirmTextInput.value) {
        setCanDelete(true);
      } else {
        setCanDelete(false);
      }
    }
  }

  const onDelete = () => {
    FirestoreV8Compat.collection('FixedAreas').doc(props.area?.id).delete()
    .then(_ => {
      console.log('Deletion successful');
    })
    .catch(err => {
      console.log('Failed to delete area:', err);
    });
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">Read this, or you'll regret it.</Alert>
        <p>You are about to delete "{props.area?.name}". This action <strong>cannot</strong> be undone. This will delete all data in accordance with this area, including its boulders, subareas, and their data.</p>
        <p>If you are sure, type <strong>{props.area?.name}</strong> into the field below.</p>
        <FormControl ref={confirmTextRef} onChange={onConfirmTextChange}></FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onDelete} block disabled={!canDelete}>Yes, I know what I'm doing</Button>
      </Modal.Footer>

    </Modal>
  );
}
