import React, { useEffect, useState, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

import * as Database from './database/index';
import Loading from './components/Loading';
import UserContext from './context/UserContext';
import Sidenav from './components/Sidenav';
import Region from './model/Region';
import Area from './model/Area';
import Sector from './model/Sector';
import Zone from './model/Zone';
import Boulder from './model/Boulder';
import TableOfContentsProvider from './components/TableOfContentsProvider';
import HomeContent from './components/HomeContent';
import RegionContent from './components/RegionContent';
import AreaContent from './components/AreaContent';
import SectorContent from './components/SectorContent';
import ZoneContent from './components/ZoneContent';
import BoulderContent from './components/BoulderContent';
import Breadcrumb from './components/Breadcrumb';

const StickySidebar = styled(Col)`
@media (min-width: 768px) {
  position: sticky;
  top: 0;
  height: calc(100vh);
  overflow-y: auto;
}
`

export default function Guidebook() {
  const { user } = useContext(UserContext);
  const [id, setId] = useState<string>();
  const [pageType, setPageType] = useState<string>();
  let { path } = useRouteMatch();
  const history = useHistory();

  const [region, setRegion] = useState<Region | null>(null);
  const [area, setArea] = useState<Area | null>(null);
  const [sector, setSector] = useState<Sector | null>(null);
  const [zone, setZone] = useState<Zone | null>(null);
  const [boulder, setBoulder] = useState<Boulder | null>(null);

  // get initial id and page type
  useEffect(() => {
    let { id, pageType } = parseUrlForIdAndCollection();
    setId(id);
    setPageType(pageType);
  }, []);

  // when the url changes be sure to get the id and page type
  useEffect(() => {
    return history.listen(() => {
      let { id, pageType } = parseUrlForIdAndCollection();
      setId(id);
      setPageType(pageType);
    });
  }, [history])

  // subscribe to region changes
  useEffect(() => {
    if (pageType === "region") {
      return Database.subscribeToRegion(id ?? '', (region) => {
        setRegion(region ?? null);
      });
    } else {
      setRegion(null);
    }
  }, [id, pageType]);

  // subscribe to area changes
  useEffect(() => {
    if (pageType === "area") {
      return Database.subscribeToArea(id ?? '', (area) => {
        setArea(area ?? null);
      });
    } else {
      setArea(null);
    }
  }, [id, pageType]);

  // subscribe to sector changes
  useEffect(() => {
    if (pageType === "sector") {
      return Database.subscribeToSector(id ?? '', (sector) => {
        setSector(sector ?? null);
      });
    } else {
      setSector(null);
    }
  }, [id, pageType]);

  // subscribe to zone changes
  useEffect(() => {
    if (pageType === "zone") {
      return Database.subscribeToZone(id ?? '', (zone) => {
        setZone(zone ?? null);
      });
    } else {
      setZone(null);
    }
  }, [id, pageType]);


  // subscribe to boulder changes
  useEffect(() => {
    if (pageType === "boulder") {
      return Database.subscribeToBoulder(id ?? '', (boulder) => {
        setBoulder(boulder ?? null);
      });
    } else {
      setBoulder(null);
    }
  }, [id, pageType]);

  const redirectToHome = useCallback(() => {
    history.push('/guidebook');
  }, [history]);

  const redirectToPage = useCallback((path: string) => {
    const collection = path.split('/')[0];
    const id = path.split('/')[1];

    let type = '';
    switch (collection) {
      case 'regions':
        type = 'region';
        break;
      case 'areas':
        type = 'area';
        break;
      case 'sectors':
        type = 'sector';
        break;
      case 'zones':
        type = 'zone';
        break;
      case 'boulders':
        type = 'boulder';
        break;
      default:
        history.push('/guidebook');
        return;
    }
    history.push(`/guidebook/${type}?id=${id}`);
  }, [history]);

  if (user === undefined) {
    return (
      <Loading />
    );
  } else if (!(user?.hasGuideAccess())) {
    return (
      <Redirect
        to="/permission"
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <StickySidebar className="bg-light border" xl={2} md={3}>
          <Sidenav
            selected={region ? region
                    : area ? area
                    : sector ? sector
                    : zone ? zone
                    : boulder ? boulder
                    : null}
          />
        </StickySidebar>
        <Col className="pt-3" xl={8} md={9}>
          <div>
            <Breadcrumb
              selected={boulder ? boulder
              : zone ? zone
              : sector ? sector
              : area ? area
              : region ? region
              : null}
              onHome={redirectToHome}
              onSelect={redirectToPage}
            />
          </div>
          <Switch>
            <Route exact path={path}>
              <HomeContent />
            </Route>
            <Route exact path={`${path}/region`}>
              {region ?
                <RegionContent
                  region={region}
                /> : <p>Region not found</p>
              }
            </Route>
            <Route exact path={`${path}/area`}>
              {area ?
                <AreaContent
                  area={area}
                /> : <p>Area not found</p>
              }
            </Route>
            <Route exact path={`${path}/sector`}>
              {sector ?
                <SectorContent
                  sector={sector}
                /> : <p>Sector not found</p>
              }
            </Route>
            <Route exact path={`${path}/zone`}>
              {zone ?
                <ZoneContent
                  zone={zone}
                /> : <p>Zone not found</p>
              }
            </Route>
            <Route exact path={`${path}/boulder`}>
              { boulder ?
                <BoulderContent
                  boulder={boulder}
                /> : null
              }
            </Route>
          </Switch>
        </Col>
        <StickySidebar className="d-none d-xl-block py-4" xl={2}>
          <TableOfContentsProvider item={boulder ? boulder : area}/>
        </StickySidebar>
      </Row>
    </Container>
  );
}

function parseUrlForIdAndCollection() {
  let id, pageType;

  const params = queryString.parse(window.location.search);
  const encodedUriPath = params.id;
  if (typeof encodedUriPath == 'string') {
    id = decodeURI(encodedUriPath);
  }

  // assumes pathname starts with '/guidebook'
  let pathParts = window.location.pathname.split('/').slice(1)
  if (pathParts.length > 1) {
    switch(pathParts[1]) {
      case 'region':
        pageType = 'region'
        break;
      case 'area':
        pageType = 'area'
        break;
      case 'sector':
        pageType = 'sector'
        break;
      case 'zone':
        pageType = 'zone'
        break;
      case 'boulder':
        pageType = 'boulder'
        break;
    }
  }

  return {
    id: id,
    pageType: pageType
  };
}

