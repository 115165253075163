import React, { useContext, useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  collection,
  addDoc
} from 'firebase/firestore';

import UserContext from '../context/UserContext';
import Feedback, { FeedbackConverter } from '../model/Feedback';
import { Firestore } from '../Firebase';

interface Props {
  withRegards?: string;
  show: boolean;
  onHide: () => void;
}

export default function EditBoulderModal({ show, onHide, withRegards }: Props) {
  const { user } = useContext(UserContext);
  const [content, setContent] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [canSend, setCanSend] = useState(false);

  useEffect(() =>{
    setCanSend((user?.hasGuideAccess() ?? false) && content !== '');
  }, [user, content]);

  // TODO Can probably change this to a React Query mutation
  const send = async () => {
    try {
      setIsSending(true);
      if (!(user?.firebaseUser.uid)) {
        throw Error('Invalid uid');
      }

      let feedback: Feedback = {
        id: '', // TODO: I need to fix this uglyness on everything that does this.
        issuer: user?.firebaseUser.uid ?? '',
        url: window.location.pathname + window.location.search + window.location.hash,
        content: content + (withRegards ? `\n\nThis feedback is with regards to ${withRegards}` : ''),
        resolved: false
      }

      console.log('Sending feedback', feedback);
      await addDoc(collection(Firestore, 'Feedback').withConverter(FeedbackConverter), feedback);
    } catch(err) {
      console.log('Could not send feedback', err);
    } finally {
      setIsSending(false);
      onHide();
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Use this feedback form to report incorrect information, supply additional information or make suggestions.</p>
        <p>Thank you for helping keep the guidebook as accurate as possible!</p>
        <Form>
          <Form.Group>
            <Form.Control
              as="textarea"
              defaultValue={''}
              onChange={e => setContent(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={isSending} onClick={() => onHide()}>Cancel</Button>
        <Button variant="primary" disabled={!canSend || isSending} onClick={send}>Send</Button>
      </Modal.Footer>
    </Modal>
  );
}

