import React, { useState, useCallback, MouseEvent } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

import { Note, NoteData, Classification } from '../model/Note';

interface NewNoteProps {
  parent: string;
}

interface EditNoteProps {
  note: Note;
}

interface CommonProps {
  onSubmit: (note: NoteData) => void;
  onCancel?: () => void;
}

type NoteFormProps = (NewNoteProps | EditNoteProps) & CommonProps;

function isNew(obj: {parent:string}|{note:Note}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function NoteForm({onSubmit, onCancel, ...props}: NoteFormProps) {
  const [classification, setClassification] = useState<Classification>(isNew(props) ? 'INFO' : props.note.classification);
  const [content, setContent] = useState<string>(isNew(props) ? '' : props.note.content);

  const handleSubmit = useCallback((e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    // Submits NoteData
    onSubmit({
      classification: classification,
      content: content,
      parent: isNew(props) ? props.parent : props.note.parent,
      creationTime: new Date().getTime(),
    });
  }, [props, onSubmit, classification, content]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Form>
      <Form.Group controlId="noteFormClass">
        <Form.Label>Classification</Form.Label>
        <Form.Control
          as="select"
          onChange={e => setClassification(e.target.value as Classification)}
        >
          <option selected={classification === 'INFO'} value="INFO">Information</option>
          <option selected={classification === 'TODO'} value="TODO">Todo</option>
          <option selected={classification === 'DEV'} value="DEV">Development</option>
          <option selected={classification === 'REQ'} value="REQ">Public Info Request</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="noteFormContent">
        <Form.Label>Content</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={e => setContent(e.target.value)}
          defaultValue={content}
        />
      </Form.Group>
      <Row>
        <Col />
        { onCancel &&
          <Col xs="auto">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        }
        <Col xs="auto">
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

