import { 
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  WithFieldValue
} from 'firebase/firestore'

interface FBUserContribData {
  uid: string;
  pid: string;
  photographer: string;
  description: string;
  read: boolean;
  timestamp: number; // number of second since ECMAScript Epoch
}

interface AnonContribData {
  contributor: string;
  pid: string;
  photographer: string;
  description: string;
  read: boolean;
  timestamp: number;
}

type PhotoContributionData = FBUserContribData | AnonContribData;

interface FBUserPhotoContribution extends FBUserContribData {
  id?: string;
}

interface AnonContribution extends AnonContribData {
  id?: string;
}

type PhotoContribution = FBUserPhotoContribution | AnonContribution;

const PhotoContributionConverter: FirestoreDataConverter<PhotoContribution> = {
  toFirestore(photo: WithFieldValue<PhotoContribution>): DocumentData {
    let copy = {...photo};
    delete copy.id;
    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): PhotoContribution {
    let data = snapshot.data(options);

    if (data.uid) {
      // fb user
      return {
        id: snapshot.id,
        uid: data.uid ?? '',
        pid: data.pid ?? '',
        photographer: data.photographer ?? '',
        description: data.description ?? '',
        read: data.read ?? false,
        timestamp: data.timestamp ?? ''
      }
    } else {
      // anon user
      return {
        id: snapshot.id,
        contributor: data.contributor ?? '',
        pid: data.pid ?? '',
        photographer: data.photographer ?? '',
        description: data.description ?? '',
        read: data.read ?? false,
        timestamp: data.timestamp ?? ''
      }
    }
  }
}

export default PhotoContribution;
export type { PhotoContributionData }
export { PhotoContributionConverter };

