import React from 'react';
import { useQuery } from 'react-query';
import DefaultBreadcrumb from 'react-bootstrap/Breadcrumb';
import styled from 'styled-components';
import Anchor from 'react-bootstrap/Anchor';

import Region, { isRegion } from '../model/Region';
import Area, { isArea } from '../model/Area';
import Sector, { isSector } from '../model/Sector';
import Zone, { isZone } from '../model/Zone';
import Boulder, { isBoulder } from '../model/Boulder';
import { getRegion, getArea, getSector, getZone } from '../database';
import Icon from '../Icon';
import { BsHouse } from 'react-icons/bs';
import { Span } from './ThemedComponents'


const BreadcrumbAnchor = styled(Anchor)`
text-decoration: none;
`


interface Props {
  selected: Region | Area | Sector | Zone | Boulder | null;
  onSelect?: (path: string) => void;
  onHome?: () => void;
}

export default function Breadcrumb({ selected, onHome, onSelect }: Props) {
  const { data: boulder, status: statusBoulder, isLoading: boulderLoading } = useQuery(['boulder', selected], () => {
    if (isBoulder(selected)) {
      return selected;
    } else {
      return null;
    }
  });
  const { data: zone, status: statusZone, isLoading: zoneLoading } = useQuery(['zone', boulder, selected], () => {
    if (boulder && boulder.parent.split('/')[0] === 'zones') {
      return getZone(boulder.parent.split('/')[1]);
    } else if (isZone(selected)) {
      return selected;
    } else {
      return null;
    }
  }, {
    enabled: statusBoulder === 'success'
  });
  const { data: sector, status: statusSector, isLoading: sectorLoading } = useQuery(['sector', boulder, zone, selected], () => {
    if (boulder && boulder.parent.split('/')[0] === 'sectors') {
      return getSector(boulder.parent.split('/')[1]);
    } else if (zone) {
      return getSector(zone.parent.split('/')[1]);
    } else if (isSector(selected)) {
      return selected;
    } else {
      return null;
    }
  }, {
    enabled: statusBoulder === 'success' && statusZone === 'success'
  });
  const { data: area, status: statusArea, isLoading: areaLoading } = useQuery(['area', sector, selected], () => {
    if (sector) {
      return getArea(sector.parent.split('/')[1]);
    } else if (isArea(selected)) {
      return selected;
    } else {
      return null;
    }
  }, {
    enabled: statusSector === 'success'
  });
  const { data: region, isLoading: regionLoading } = useQuery(['region', area, selected], () => {
    if (area) {
      return getRegion(area.parent.split('/')[1]);
    } else if (isRegion(selected)) {
      return selected;
    } else {
      return null;
    }
  }, {
    enabled: statusArea === 'success'
  });


  if (selected === null) {
    return (<></>);
  }

  const isLoading = boulderLoading || zoneLoading || sectorLoading || areaLoading || regionLoading;
  const willLoadRegion = isRegion(selected) || isArea(selected) || isSector(selected) || isZone(selected) || isBoulder(selected);
  const willLoadArea = isArea(selected) || isSector(selected) || isZone(selected) || isBoulder(selected);
  const willLoadSector = isSector(selected) || isZone(selected) || isBoulder(selected);
  const willLoadZone = isZone(selected) || isBoulder(selected);
  const willLoadBoulder = isBoulder(selected);
  const numToLoad = [willLoadRegion, willLoadArea, willLoadSector, willLoadZone, willLoadBoulder].reduce(
    (acc, current) => acc + (current ? 1 : 0), 0
  )

  return (
    <DefaultBreadcrumb>
        { onHome &&
          <DefaultBreadcrumb.Item>
            <BsHouse
              onClick={() => {onHome!()}}
            />
          </DefaultBreadcrumb.Item>
        }
      {isLoading &&
        <>
          {Array(numToLoad).fill(0).map((_, i) => (
            <DefaultBreadcrumb.Item key={`breadcrumb-load-${i}`}>
              <span>
                {/* Loading... */}
              </span>
            </DefaultBreadcrumb.Item>
          ))}
        </>
      }
      { (!isLoading && region) &&
        <DefaultBreadcrumb.Item
          key={`regions/${region.id}`}
          onClick={() => onSelect && onSelect(`regions/${region.id}`)}
          active={isRegion(selected)}
          linkAs={BreadcrumbAnchor}
        >
          <span>
            <Icon.Area
              height="1em"
              width="1em"
              style={{
                verticalAlign: "center",
                marginRight: "4px"
              }}
            />
            <Span>
              {region.name}
            </Span>
          </span>
        </DefaultBreadcrumb.Item>
      }
      { (!isLoading && area) &&
        <DefaultBreadcrumb.Item
          key={`areas/${area.id}`}
          onClick={() => onSelect && onSelect(`areas/${area.id}`)}
          active={isArea(selected)}
          linkAs={BreadcrumbAnchor}
        >
          <span>
            <Icon.Area
              height="1em"
              width="1em"
              style={{
                verticalAlign: "center",
                marginRight: "4px"
              }}
            />
            <Span>
              {area.name}
            </Span>
          </span>
        </DefaultBreadcrumb.Item>
      }
      { (!isLoading && sector) &&
        <DefaultBreadcrumb.Item
          key={`sectors/${sector.id}`}
          onClick={() => onSelect && onSelect(`sectors/${sector.id}`)}
          active={isSector(selected)}
          linkAs={BreadcrumbAnchor}
        >
          <span>
            <Icon.Area
              height="1em"
              width="1em"
              style={{
                verticalAlign: "center",
                marginRight: "4px"
              }}
            />
            <Span>
              {sector.name}
            </Span>
          </span>
        </DefaultBreadcrumb.Item>
      }
      { (!isLoading && zone) &&
        <DefaultBreadcrumb.Item
          key={`zones/${zone.id}`}
          onClick={() => onSelect && onSelect(`zones/${zone.id}`)}
          active={isZone(selected)}
          linkAs={BreadcrumbAnchor}
        >
          <span>
            <Icon.Area
              height="1em"
              width="1em"
              style={{
                verticalAlign: "center",
                marginRight: "4px"
              }}
            />
            <Span>
              {zone.name}
            </Span>
          </span>
        </DefaultBreadcrumb.Item>
      }
      { (!isLoading && boulder) &&
        <DefaultBreadcrumb.Item
          key={`boulders/${boulder.id}`}
          onClick={() => onSelect && onSelect(`boulders/${boulder.id}`)}
          active={isBoulder(selected)}
          linkAs={BreadcrumbAnchor}
        >
          <span>
            <Icon.Boulder
              height="1em"
              width="1em"
              style={{
                verticalAlign: "center",
                marginRight: "4px"
              }}
            />
            <Span>
              {boulder.name}
            </Span>
          </span>
        </DefaultBreadcrumb.Item>
      }
    </DefaultBreadcrumb>
  );
}

