export interface Theme {
  name?: string;
  serifFont?: string;
  sansSerifFont?: string;
  primaryColor?: string;
}

export const noTheme: Theme = {
  name: 'None'
}

export const defaultTheme: Theme = {
  name: 'Default',
  serifFont: 'Roboto Slab',
  sansSerifFont: 'Roboto',
  primaryColor: '#cd815f'
}

const themes = [noTheme, defaultTheme];

export default themes;

