import {
  doc,
  getDoc
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { zoneConverter } from '../model/Zone';

export async function getZone(
  id: string
) {
  let docRef = doc(Firestore, `zones/${id}`).withConverter(zoneConverter);

  return (await getDoc(docRef)).data();
}

