import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Boulder from '../model/Boulder';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  boulders: Boulder[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderBouldersModal({ boulders, show, onHide }: Props) {
  const [unsortedBoulders, setUnsortedBoulders] = React.useState<Boulder[]>([]);
  const [sortedBoulders, setSortedBoulders] = React.useState<Boulder[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Boulder[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Boulder[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(boulders.filter(boulder => boulder.order === null));
    setSortedResetState(boulders.filter(boulder => boulder.order !== null));
  }, [boulders])

  // Initial boulder state
  useEffect(() => {
    setUnsortedBoulders(boulders.filter(boulder => boulder.order === null));
    setSortedBoulders(boulders.filter(boulder => boulder.order !== null));
  }, [boulders])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedBoulders, sortedResetState) && deepEqual(unsortedBoulders, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedBoulders, sortedResetState, unsortedBoulders, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedBoulders.forEach((boulder, index) => {
          const original = boulders.find(original => original.id === boulder.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `boulders/${boulder.id}`), {order: index})
          }
        });
        unsortedBoulders.forEach(boulder => {
          const original = boulders.find(original => original.id === boulder.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `boulders/${boulder.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [boulders, sortedBoulders, unsortedBoulders, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = boulders.filter(boulder => unsorted.find(sortable => sortable.id === boulder.id));
    const newSorted = boulders.filter(boulder => sorted.find(sortable => sortable.id === boulder.id))
      // sort boulders based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedBoulders(newUnsorted);
    setSortedBoulders(newSorted);
  }), [boulders]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Boulders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedBoulders.map(boulder => ({id: boulder.id ?? '', display: boulder.name}))}
          defaultOrdered={sortedBoulders.map(boulder => ({id: boulder.id ?? '', display: boulder.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

