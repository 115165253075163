import React, { useEffect, useState, useCallback,ChangeEvent } from 'react';
import deepEqual from 'deep-equal';
import { useMutation } from 'react-query'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Zone, { ZoneData } from '../model/Zone';
import { createZone, updateZone } from '../database';


interface NewZoneProps {
  show: boolean;
  onHide: () => void;
  parent: string;
}

interface EditZoneProps {
  show: boolean;
  onHide: () => void;
  zone: Zone;
}

type ZoneModalProps = NewZoneProps | EditZoneProps;

function isNew(obj: {parent:string}|{zone:Zone}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function ZoneModal({show, onHide, ...props }: ZoneModalProps) {
  const [oldProps, setOldProps] = useState(props);
  const [oldZone, setOldZone] = useState(isNew(props) ? undefined : props.zone);
  const [name, setName] = useState(isNew(props) ? '' : props.zone.name);
  const [description, setDescription] = useState(isNew(props) ? '' : props.zone.description);
  const [directions, setDirections] = useState(isNew(props) ? '' : props.zone.directions)
  const [isPrivate, setIsPrivate] = useState(isNew(props) ? false : props.zone.private);
  const [order, setOrder] = useState(isNew(props) ? null : props.zone.order)
  const [parent, setParent] = useState(isNew(props) ? props.parent : props.zone.parent)

  // useState won't update bindings that rely on `isNew(props)` for some
  // reason, do it manaully here, also its firing even though props isn't
  // changing, what the fuck?
  useEffect(() => {
    if (deepEqual(oldProps, props)) {
      return;
    }
    setOldProps(props);

    if (isNew(props)) {
      setOldZone(undefined);
      setName('');
      setDescription('');
      setDirections('');
      setIsPrivate(false);
      setOrder(null);
      setParent(props.parent)
    } else {
      setOldZone(props.zone);
      setName(props.zone.name);
      setDescription(props.zone.description);
      setDirections(props.zone.directions);
      setIsPrivate(props.zone.private);
      setOrder(props.zone.order);
      setParent(props.zone.parent)
    }
  }, [oldProps, props])

  const { mutateAsync: mutateZone } = useMutation((): Promise<void> => {
    const newZone: ZoneData = {
      name: name,
      description: description,
      directions: directions,
      private: isPrivate,
      order: order,
      parent: parent,
    }
    if (oldZone) {
      // TODO: It'd be nice to use a diff to generate UpdateData<ZoneData>
      //       with field values if we ever need to delete a field.
      return updateZone(oldZone.id, newZone);
    } else {
      return createZone(newZone);
    }
  });

  const handleSave = useCallback(async () => {
    try {
      await mutateZone();
    } catch (e) {
      console.log('There was a problem mutating zone:', e);
    }
    onHide();
  }, [onHide, mutateZone]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{oldZone ? 'Update Zone' : 'Create Zone'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Directions</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={directions}
              onChange={e => setDirections(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Private</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={isPrivate}
              label="Private"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

