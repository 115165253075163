import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';
import deepClone from 'lodash/cloneDeep';

import TopoLine from './TopoLine';

interface TopoData {
  boulderId: string;
  imageId: string;
  caption: string;
  topoLines: TopoLine[];
  private: boolean;
}

interface Topo extends TopoData {
  id: string;
}


// TODO: Throw errors if data coming from database is bad.
const topoConverter: FirestoreDataConverter<Topo> = {
  toFirestore(topo: WithFieldValue<Topo>): DocumentData {
    const data = deepClone(topo);

    delete data.id;

    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Topo {
    const data = snapshot.data(options);
    const topo: Topo = {
      id: snapshot.id,
      boulderId: data.boulderId,
      imageId: data.imageId,
      caption: data.caption ?? '',
      topoLines: data.topoLines ?? [],
      private: data.private ?? false
    }

    return topo;
  }
}

function isTopo(obj: object): obj is Topo {
  try {
    return (obj as Topo).topoLines !== undefined;
  } catch {
    return false;
  }
}

export { topoConverter, isTopo }
export type { Topo }

