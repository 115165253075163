import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { NoteData } from '../model/Note';


export async function createNote(area: NoteData): Promise<void> {
  await FirestoreV8Compat.collection('notes').add(area);
  // returning void so all of these have the same return type
  return;
}

export async function updateNote(id: string, data: UpdateData<NoteData>) {
  return FirestoreV8Compat.doc(`notes/${id}`).update(data);
}

export async function deleteNote(id: string) {
  return FirestoreV8Compat.doc(`notes/${id}`).delete();
}

