import React from 'react';

import FormCheck from 'react-bootstrap/FormCheck';


export default function UserViewToggle() {
  return (
    <FormCheck
      id="meaningless-id-to-make-this-work"
      type="switch"
      label=""
      //onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPublic(e.target.checked)}
    />
  );
}

