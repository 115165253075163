import RectBoundingBox from './RectBoundingBox';

/**
 * This structure represents bezier curves chained together
 */
export default interface BezierChain {
    points: BezierPoint[];
}

export interface BezierSegment {
    p1: BezierPoint
    p2: BezierPoint
}

export function boundSegment(segment: BezierSegment, bounds: RectBoundingBox): BezierSegment {
    return ({
        p1: boundPoint(segment.p1, bounds),
        p2: boundPoint(segment.p2, bounds)
    });
}

export function boundPoint(point: BezierPoint, bounds: RectBoundingBox): BezierPoint {
    return new BezierPoint(bounds.offsetX + bounds.width * point.x,
                           bounds.offsetY + bounds.height * point.y,
                           bounds.offsetX + bounds.width * point.cx1,
                           bounds.offsetY + bounds.height * point.cy1,
                           bounds.offsetX + bounds.width * point.cx2,
                           bounds.offsetY + bounds.height * point.cy2
                          );
}

const THRESHOLD = 0.001

/*
 * Only comparing the parts that actually affect the visible segment
 */
export function approxEqualSegments(lhs: BezierSegment, rhs: BezierSegment) {
    return (
        Math.abs(lhs.p1.x - rhs.p1.x) < THRESHOLD &&
        Math.abs(lhs.p1.y - rhs.p1.y) < THRESHOLD &&
        Math.abs(lhs.p1.cx2 - rhs.p1.cx2) < THRESHOLD &&
        Math.abs(lhs.p1.cy2 - rhs.p1.cy2) < THRESHOLD &&
        Math.abs(lhs.p2.x - rhs.p2.x) < THRESHOLD &&
        Math.abs(lhs.p2.y - rhs.p2.y) < THRESHOLD &&
        Math.abs(lhs.p2.cx1 - rhs.p2.cx1) < THRESHOLD &&
        Math.abs(lhs.p2.cy1 - rhs.p2.cy1) < THRESHOLD
    );
}

/*
 * A bezier point has a position and positions of its controls.
 * When connected to a second bezier point, c1 controls the curve before while c2 controls the point after
 */
export class BezierPoint {
    x: number;
    y: number;
    cx1: number;
    cy1: number;
    cx2: number; 
    cy2: number;

    constructor(x: number, y: number, cx1: number, cy1: number, cx2: number, cy2: number) {
        this.x = x;
        this.y = y;
        this.cx1 = cx1;
        this.cy1 = cy1;
        this.cx2 = cx2;
        this.cy2 = cy2;
    }

    static FromXY(x: number, y: number): BezierPoint {
        return new BezierPoint(x, y, x, y, x, y);
    }
}
