import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { Note, NoteConverter } from '../model/Note';
import Area, { isArea } from '../model/Area';
import Boulder, { isBoulder } from '../model/Boulder';
import Problem from '../model/Problem';

export function subscribeToNotes(
  notable: Area | Boulder | Problem | null,
  onNext: (notes: Note[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  let parent: null | string = null;
  if (notable !== null) {
    if (isArea(notable)) {
      parent = `areas/${notable.id}`;
    } else if (isBoulder(notable)) {
      parent = `boulders/${notable.id}`;
    } else {
      parent = `problems/${notable.id}`;
    }
  }
  const colRef = collection(Firestore, 'notes').withConverter(NoteConverter);
  const colQuery = query(colRef, where('parent', '==', parent), orderBy('creationTime'));

  return (
    onSnapshot(colQuery,
      querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data())),
      onError,
      onCompletion
    )
  );
}

