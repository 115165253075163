import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import deepEqual from 'deep-equal';

import { useMutation } from 'react-query'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import Boulder, { BoulderData } from '../model/Boulder';
import { createBoulder, updateBoulder } from '../database';

interface NewBoulderProps {
  show: boolean;
  onHide: () => void;
  parent: string;
}

interface EditBoulderProps {
  show: boolean;
  onHide: () => void;
  boulder: Boulder;
}

type BoulderModalProps = NewBoulderProps | EditBoulderProps;

function isNew(obj: {parent:string}|{boulder:Boulder}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function BoulderModal({ show, onHide, ...props }: BoulderModalProps) {
  const [oldProps, setOldProps] = useState(props);
  const [oldBoulder, setOldBoulder] = useState(isNew(props) ? undefined : props.boulder);
  const [name, setName] = useState(isNew(props) ? '' : props.boulder.name);
  const [description, setDescription] = useState(isNew(props) ? '' : props.boulder.description);
  const [directions, setDirections] = useState(isNew(props) ? '' : props.boulder.directions);
  const [latitude, setLatitude] = useState(isNew(props) ? null : props.boulder.coordinates?.latitude ?? null);
  const [longitude, setLongitude] = useState(isNew(props) ? null : props.boulder.coordinates?.longitude ?? null);
  const [isPrivate, setIsPrivate] = useState(isNew(props) ? false : props.boulder.private);
  const [order, setOrder] = useState(isNew(props) ? null : props.boulder.order)
  const [parent, setParent] = useState(isNew(props) ? props.parent : props.boulder.parent)

  // useState won't update bindings that rely on `isNew(props)` for some
  // reason, do it manaully here, also its firing even though props isn't
  // changing, what the fuck?
  useEffect(() => {
    if (deepEqual(oldProps, props)) {
      return;
    }
    setOldProps(props);

    if (isNew(props)) {
      setOldBoulder(undefined);
      setName('');
      setDescription('');
      setDirections('');
      setLatitude(null);
      setLongitude(null);
      setIsPrivate(false);
      setOrder(null);
      setParent(props.parent)
    } else {
      setOldBoulder(props.boulder);
      setName(props.boulder.name);
      setDescription(props.boulder.description);
      setDirections(props.boulder.directions);
      setLatitude(props.boulder.coordinates?.latitude ?? null);
      setLongitude(props.boulder.coordinates?.longitude ?? null);
      setIsPrivate(props.boulder.private);
      setOrder(props.boulder.order);
      setParent(props.boulder.parent)
    }
  }, [oldProps, props])

  const { mutateAsync: mutateBoulder } = useMutation((): Promise<void> => {
    const newBoulder: BoulderData = {
      name: name,
      description: description,
      directions: directions,
      private: isPrivate,
      coordinates: (latitude && longitude) ? { latitude: latitude, longitude: longitude } : null,
      order: order,
      parent: parent,
    }
    if (oldBoulder) {
      // TODO: It'd be nice to use a diff to generate UpdateData<BoulderData>
      //       with field values if we ever need to delete a field.
      return updateBoulder(oldBoulder.id, newBoulder);
    } else {
      return createBoulder(newBoulder);
    }
  });

  const handleSave = useCallback(async () => {
    try {
      await mutateBoulder();
    } catch (e) {
      console.log('There was a problem mutating boulder:', e);
    }
    onHide();
  }, [onHide, mutateBoulder]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{oldBoulder ? 'Update Boulder' : 'Create Boulder'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Directions</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={directions}
              onChange={e => setDirections(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Coordinates</Form.Label>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                    defaultValue={latitude ?? undefined}
                    placeholder="latitude"
                    type="number"
                    step="any"
                    onChange={e => setLatitude(Number(e.target.value))}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                    defaultValue={longitude ?? undefined}
                    placeholder="longitude"
                    type="number"
                    step="any"
                    onChange={e => setLongitude(Number(e.target.value))}
                    />
                </Form.Group>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Private</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={isPrivate}
              label="Private"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
