import {
  doc,
  getDoc
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { problemConverter } from '../model/Problem';

export async function getProblem(
  id: string,
) {
  const docRef = doc(Firestore, `problems/${id}`).withConverter(problemConverter);
  return (await getDoc(docRef)).data();
}

