import React, { useContext } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import { Auth, Functions } from './Firebase';
import ThemeContext from './context/ThemeContext';
import themes from './themes';

export default function Account() {
  // TODO: Verify email if not verified
  const [authd, setAuthd] = React.useState(false);
  const [devClaim, setDevClaim] = React.useState(false);
  const [countingBoulders, setCountingBoulders] = React.useState(false);
  const [countingProblems, setCountingProblems] = React.useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  // get auth status
  React.useEffect(() => {
    Auth.onAuthStateChanged(user => {
      if (user) {
        setAuthd(true);
      } else {
        setAuthd(false);
      }
    })
  }, []);

  // get custom claims
  React.useEffect(() => {
    if (authd) {
      Auth.currentUser?.getIdTokenResult()
        .then(idTokenResult => {
          if (idTokenResult.claims.developer) {
            setDevClaim(true);
          }
        })
        .catch(error => {
          console.log('There was an error getting the auth claims:', error);
        });
    }
  }, [authd])

  const signOut = () => {
    Auth.signOut()
      .catch(error => {
        console.log('Failed to sign out: ', error);
      });
  };

  const recountBoulders = () => {
    setCountingBoulders(true);
    const recountBoulders = Functions.httpsCallable('BoulderCount-recountBoulders')
    recountBoulders()
      .then(_ => {
        setCountingBoulders(false);
        console.log('Successfully counted boulders');
      })
      .catch(error => {
        setCountingBoulders(false);
        console.log('There was an error counting the boulders:', error);
      });
  };

  const recountProblems = () => {
    setCountingProblems(true);
    const recountProblems = Functions.httpsCallable('ProblemCount-recount')
    recountProblems()
      .then(_ => {
        setCountingProblems(false);
        console.log('Successfully counted problems');
      })
      .catch(error => {
        setCountingProblems(false);
        console.log('There was an error counting the problems:', error);
      });
  };

  return (
    <>
      {devClaim &&
        <ButtonGroup>
          <Button variant="primary" onClick={recountBoulders} disabled={countingBoulders}>Recount Boulders</Button>
          <Button variant="primary" onClick={recountProblems} disabled={countingProblems}>Recount Problems</Button>
        </ButtonGroup>
      }
      <DropdownButton
        title='Change Theme'
      >
        {themes.map((t, i) => {
          return (
            <Dropdown.Item
              eventKey={`${i}`}
              active={t.name === theme.name}
              onClick={() => setTheme(t)}
            >
              {t.name ?? ''}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <Button variant="primary" onClick={signOut} disabled={!authd} block>Sign out</Button>
    </>
  );
}

