import React, { useState, useEffect } from 'react';
import Bezier from 'bezier-js';
import styled from 'styled-components';
import deepClone from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { ImageStorage } from '../Firebase';
import type { Topo } from '../model/Topo';
import type { BezierSegment } from '../model/BezierChain';
import { boundSegment, approxEqualSegments } from '../model/BezierChain';
import Problem from '../model/Problem';
import { contain } from '../utils/math';
import RectBoundingBox from '../model/RectBoundingBox';
import { compareGrades, gradeInfo, SpecialGrade } from '../model/Grade';
import {
  getDownloadURL,
  listAll,
  ref
} from 'firebase/storage';

const TopoViewContainer = styled.div`
  height: 24em;
  width: 100%;
  text-align: center;
  background-color: #e9ecef;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

interface Props {
  topo: Topo;
  isPrivileged?: boolean;
  problems: Problem[];
};

export default function TopoView({ topo, ...props }: Props) {
  const [canvasRef] = useState(React.createRef<HTMLCanvasElement>());
  const [img] = useState<HTMLImageElement>(new Image());
  const [validImg, setValidImg] = useState(false);
  const [imgBounds, setImgBounds] = useState<RectBoundingBox>();

  // resize the canvas when the window resizes
  useEffect(() => {
    const onResize = () => {
      resizeCanvas();
      fixImgBounds();
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
    // Don't rerun after function defs change onrerender
    // eslint-disable-next-line
  }, []);

  // setup canvas
  React.useEffect(() => {
    resizeCanvas();
    fixImgBounds();
    // Don't rerun after function defs change onrerender
    // eslint-disable-next-line
  }, [canvasRef])

  // get img
  React.useEffect(() => {
    setValidImg(false);

    listAll(ref(ImageStorage, topo.imageId))
    .then(list => {
      const item = list.items.find(item => item.name.includes('medium'))
      if (item) {
        return getDownloadURL(item);
      } else {
        throw(Error(`Item ${topo.imageId} does not exist.`));
      }
    })
    .then(url => {
      img.onload = () => { fixImgBounds(); setValidImg(true); }
      img.src = url
    })
    .catch(err => {
      console.log('Could not load topo image:', err);
    });
    // Don't rerun after function defs change onrerender
    // But rerun if the topo changes because then we know the image is invalid
    // eslint-disable-next-line
  }, [topo]);

  // redraw
  React.useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext('2d')!;

    if (!(validImg && imgBounds)) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }

    const dpr = window.devicePixelRatio || 1;
    ctx.drawImage(img, imgBounds.offsetX, imgBounds.offsetY, imgBounds.width, imgBounds.height);

    // Don't need to draw any lines if there are no associated problems
    if (props.problems === undefined) {
      return;
    }

    // START prepare lines to be drawn
    // filter lines to just those which can be associated with problems
    const problemIds = props.problems.map(problem => problem.id);
    const lines = deepClone(topo.topoLines).filter(line => problemIds.includes(line.problemId));

    // associate each line with a grade
    const gradeAssociatedLines = lines.map(line => ({ line: line, grade: props.problems.find(p => p.id === line.problemId)?.grade ?? "V?" }));
    const sortedGradeAssociatedLines = gradeAssociatedLines.sort((lhs, rhs) => compareGrades(lhs.grade, rhs.grade));

    // minimize necessary segments, keep lowest grade
    const gradedSegments: ({segment: BezierSegment, grade: string})[] = [];
    sortedGradeAssociatedLines.forEach(gradedLine => {
      const segments: BezierSegment[] = gradedLine.line.bezier.points.map((p1, idx, arr) => ({ p1: p1, p2: idx+1 !== arr.length ? arr[idx+1] : p1 })).filter(({p1, p2}) => !isEqual(p1, p2));
      segments.forEach(segment => {
        if (!gradedSegments.find(({segment: aSegment}) => approxEqualSegments(segment, aSegment))) {
          gradedSegments.push({ segment: segment, grade: gradedLine.grade });
        }
      });
    });
    const gradedBoundedSegments: ({segment:BezierSegment, grade: string})[] = gradedSegments.map(segment => ({ grade: segment.grade, segment: boundSegment(segment.segment, imgBounds) }));
    // END prepare lines to be drawn

    // Draw line backgrounds
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';
    ctx.lineWidth = 4 * dpr;
    ctx.lineCap = 'round';
    gradedBoundedSegments.forEach(gradedSegment => {
      const { p1, p2 } = gradedSegment.segment;
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.bezierCurveTo(p1.cx2, p1.cy2, p2.cx1, p2.cy1, p2.x, p2.y);
      ctx.stroke();
    });

    // Draw label backgrounds
    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
    lines.forEach(line => {
      const labelIndex = line.labelIndex;
      const labelPosition = line.labelPosition;
      const { p1, p2 } = boundSegment({ p1: line.bezier.points[labelIndex], p2: line.bezier.points[labelIndex+1] }, imgBounds);
      const bezier = new Bezier(p1.x, p1.y, p1.cx2, p1.cy2, p2.cx1, p2.cy1, p2.x, p2.y)
      const labelPoint = bezier.get(labelPosition / 100);
      ctx.beginPath();
      ctx.arc(labelPoint.x, labelPoint.y, 7.25 * dpr, 0, 2 * Math.PI);
      ctx.fill();
    });

    gradedBoundedSegments.reverse().forEach(gradedSegment => {
      const { p1, p2 } = gradedSegment.segment;
      ctx.strokeStyle = colorFromGrade(gradedSegment.grade);
      ctx.lineWidth = 1.75 * dpr;
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.bezierCurveTo(p1.cx2, p1.cy2, p2.cx1, p2.cy1, p2.x, p2.y);
      ctx.stroke();
    });

    gradeAssociatedLines.forEach(({ grade, line }) => {
      const label = problemIds.indexOf(line.problemId) + 1;
      const labelIndex = line.labelIndex;
      const labelPosition = line.labelPosition;
      const { p1, p2 } = boundSegment({ p1: line.bezier.points[labelIndex], p2: line.bezier.points[labelIndex+1] }, imgBounds);
      const bezier = new Bezier(p1.x, p1.y, p1.cx2, p1.cy2, p2.cx1, p2.cy1, p2.x, p2.y)
      const labelPoint = bezier.get(labelPosition / 100);
      ctx.fillStyle = colorFromGrade(grade);
      ctx.beginPath();
      ctx.arc(labelPoint.x, labelPoint.y, 6 * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.strokeStyle = 'black';
      ctx.font = `${8 * dpr}px sans-serif`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.strokeText(`${label}`,labelPoint.x, labelPoint.y);
    });

  }, [canvasRef, validImg, props.problems, topo.topoLines, img, imgBounds]);

  const resizeCanvas = () => {
    const dpr = window.devicePixelRatio || 1;
    const canvas = canvasRef.current!;
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
  };

  const fixImgBounds = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      setImgBounds(contain(canvas.width, canvas.height, img.width, img.height));
    }
  }

  return (
    <TopoViewContainer>
      <canvas
        ref={canvasRef}
        className="w-100 h-100"
      />
    </TopoViewContainer>
  );
}

function colorFromGrade(grade: string) {
  const info = gradeInfo(grade);

  if (info === SpecialGrade.Basic) {
    return "green";
  }

  if (info === SpecialGrade.Project ||
      info === SpecialGrade.Unknown ) {
    return "white"
  }

  if (compareGrades(grade, "V3-") < 0) {
    return "green";
  } else if (compareGrades(grade, "V5-") < 0) {
    return "yellow";
  } else if (compareGrades(grade, "V8-") < 0) {
    return "orange";
  } else if (compareGrades(grade, "V11-") < 0) {
    return "red";
  } else {
    return "magenta"
  }
}

