import React, { useState, useCallback } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SearchFiltersForm from './SearchFiltersForm'
import { ProblemQuery } from '../search/index';


interface SearchFiltersModalProps {
  show: boolean;
  onHide?: () => void;
  onApply?: (query: ProblemQuery) => void;
  onClear?: () => void;
  filters?: ProblemQuery;
}

export default function SearchFiltersModal({show, onHide, onApply,onClear, filters}: SearchFiltersModalProps) {
  const [newFilters, setNewFilters] = useState(filters)

  const handleClear = useCallback(() => {
    if (onClear) {
      onClear();
    }
    if (onHide) {
      onHide();
    }
  }, [onClear, onHide]);

  const handleApply = useCallback(() => {
    if (onApply) {
      // generate query
      onApply(newFilters ?? {});
    }
    if (onHide) {
      onHide();
    }
  }, [onApply, onHide, newFilters]);

  const handleFormChange = useCallback((f: ProblemQuery) => {
    setNewFilters(f);
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Search Filters
      </Modal.Header>
      <Modal.Body>
        <SearchFiltersForm
          onChange={handleFormChange}
          defaultFilters={filters} // passing the props filters, not the new ones to avoid circular rerender
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClear}>
          Clear
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

