import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import {
  BsPencil,
  BsTrash,
} from 'react-icons/bs';
import ListGroup from 'react-bootstrap/ListGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import { Container, Row, Col } from 'react-bootstrap';
import deepEqual from 'deep-equal';
import TopoImagesUploader from './TopoImagesUploader';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc,
} from 'firebase/firestore';

import { Firestore } from '../Firebase';
import type { Topo } from '../model/Topo';
import { topoConverter } from '../model/Topo';
import FirebaseImage from './FirebaseImage';
import TopoEditor from './TopoEditor/TopoEditor';
import Boulder from '../model/Boulder';

interface Props {
  show: boolean;
  onHide: () => void;
  boulderId: string;
  topos: Topo[];
  tempBoulder?: Boulder; // todo I can probably get rid of this after I move collections in firebase
}

export default function EditToposModal(props: Props) {
  const [topos, setTopos] = React.useState(props.topos);
  const [isModified, setIsModified] = React.useState(false);
  const [edittingTopo, setEdittingTopo] = React.useState<Topo | null>();

  /* uploading state */
  const [canSave, setCanSave] = React.useState(false);
  const [topoImagesAreUploading, setTopoImagesAreUploading] = React.useState(false);

  const boulderId = props.boulderId;
  const resetState = props.topos;

  React.useEffect(() => {
    setTopos(props.topos);
  }, [props.topos])

  React.useEffect(() => {
    // if anything is uploading, cannot save
    setCanSave(!topoImagesAreUploading);
  }, [topoImagesAreUploading])

  // check for modifications
  React.useEffect(() => {
    if (deepEqual(topos, resetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [topos, resetState]);

  const addTopos = (...imageIds: string[]) => {
    if (imageIds && boulderId) {
      const newTopos: Topo[] = imageIds.map(id => {
        return ({
          id: '',
          boulderId: boulderId,
          imageId: id,
          caption: '',
          topoLines: [],
          private: false
        });
      });
      setTopos([...topos, ...newTopos])
    }
  };

  const deleteTopo = (id: string) => {
    setTopos(topos.filter(topo => topo.id !== id));
  }

  const editTopo = (topo: Topo) => {
    setEdittingTopo(topo);
  }

  const saveEditedTopo = (topo: Topo) => {
    setTopos(topos.map(temp => {
      if (temp.id === topo.id) {
        return topo;
      } else {
        return temp;
      }
    }));
    setEdittingTopo(null);
  }

  const handleReset = () => {
    setTopos(resetState);
  };

  const handleSave = () => {
    // upload new topos
    const newTopos = topos.filter(topo => !topo.id)
    const newTopoPromises = newTopos.map(topo => {
      return addDoc(collection(Firestore, 'topos').withConverter(topoConverter), topo);
    });

    Promise.all(newTopoPromises)
      .then(_ => {
        console.log('Successfully uploaded new topos.');
      })
      .catch(error => {
        console.log('Error uploading topos:', error);
      });

    // delete old topos
    const deletedTopos = resetState.filter(topo => !topos.find(temp => temp.id === topo.id));
    const deleteTopoPromises = deletedTopos.map(topo => {
      return deleteDoc(doc(Firestore, `topos/${topo.id}`).withConverter(topoConverter));
    });

    Promise.all(deleteTopoPromises)
      .then(_ => {
        console.log('Successfully deleted old topos.');
      })
      .catch(error => {
        console.log('Error deleting topos:', error);
      });

    // todo: get modified docs (docs both in topos and reset state)
    //   get changed fields for each doc and update them
    const edittedTopos = topos.filter(topo => resetState.find(orig => {
      if (topo.id === orig.id) {
        const topoData = topoConverter.toFirestore(topo);
        const origData = topoConverter.toFirestore(orig);
        if (!deepEqual(topoData, origData)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }));

    const edittedPromises = edittedTopos.map(topo => {
      return setDoc(doc(Firestore, `topos/${topo.id}`).withConverter(topoConverter), topo)
    });

    Promise.all(edittedPromises)
      .then(_ => {
        console.log('Successfully updated topos.');
      })
      .catch(error => {
        console.log('Error updating topos:', error);
      });

  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        enforceFocus={false} // Damn react bootstrap. I need this for nested modals, this disables nice accessibility features. Perhaps I should rethink the editor being in a modal
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Topos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {topos.map((topo, index) => {
              return (
                <ListGroup.Item key={index}>
                  <Container>
                    <Row>
                      <Col>
                        <FirebaseImage imageId={topo.imageId} size={'sm'} thumbnail/>
                      </Col>
                      <Col md="auto">
                        <ButtonGroup>
                          <Button variant="primary" onClick={() => editTopo(topo)}><BsPencil /></Button>
                          <Button variant="danger" onClick={() => deleteTopo(topo.id)}><BsTrash /></Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <hr />
          <InputGroup>
            <TopoImagesUploader
              pushImageIds={addTopos}
              onUploadStateChange={setTopoImagesAreUploading}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset} disabled={!isModified}>Reset</Button>
          <Button variant="primary" onClick={handleSave} disabled={!isModified || !canSave}>Save</Button>
        </Modal.Footer>
      </Modal>
      {/* Nested edit topo modal */}
      {edittingTopo &&
        <TopoEditor
          topo={edittingTopo}
          onHide={() => setEdittingTopo(null)}
          onSave={saveEditedTopo}
        />
      }
    </>
  );
}
