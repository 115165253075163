import { SearchOptions } from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch';

import client from './searchClient';
import { QUICKSEARCH_INDEX } from './indeces';


type QUICK_SEARCH_HIT_TYPE = 'region' | 'area' | 'sector' | 'zone' | 'boulder' | 'problem';

export interface QuickSearchHit {
  objectID: string;
  name: string;
  type: QUICK_SEARCH_HIT_TYPE;
  _highlightResult: any;
}

export class QuickSearch {
  text?: string;
  page?: number;
  maxPage?: number;

  private _index: SearchIndex;

  constructor() {
    this._index = client.initIndex(QUICKSEARCH_INDEX);
  }

  setText(s: string) {
    this.text = s;
    return this;
  }

  limitTo(max: number) {
    this.maxPage = max;
    return this;
  }

  async get() {
    const searchOptions: SearchOptions = {
      hitsPerPage: this.maxPage ?? 5,
    };

    let { hits, nbPages, page, nbHits } = await this._index.search(this.text ?? '', searchOptions);

    return ({
      hits: hits as QuickSearchHit[],
      page: page,
      pages: nbPages,
      nbHits: nbHits
    });
  }
}

