import Point from '../model/Point';

export const contain = fit(true)
export const cover = fit(false)

export function rectContainsPoint(x: number, y: number,
                                  w: number, h: number,
                                  px: number, py: number): boolean {
  const leftOfLeftBound = px > x;
  const rightOfRightBound = px < x + w;
  const belowTopBound = py > y;
  const aboveBottomBound = py < y + h;
  return leftOfLeftBound && rightOfRightBound && belowTopBound && aboveBottomBound;
}

export function hitPoint(p1: Point, p2: Point, threshold: number) {
  return rectContainsPoint(p2.x-threshold, p2.y-threshold, threshold*2, threshold*2, p1.x, p1.y)
}

export function findSnapPoint(p: Point, snapPoints: Point[], threshold: number) {
  const possiblePoints = snapPoints.filter(point => distance2d(p, point) < threshold);
  if (possiblePoints.length > 0) {
    return possiblePoints.reduce((currentMin, nextPoint) => {
      return distance2d(nextPoint, p) < distance2d(currentMin, p) ? nextPoint : currentMin;
    });
  }
}

export function distance2d(p1: Point, p2: Point): number {
  return Math.hypot(p2.x-p1.x, p2.y-p1.y);
}


/*********************************
 * private/internal util functions
 ********************************/
function fit(contains: boolean) {
  return (parentWidth: number, parentHeight: number, childWidth: number, childHeight: number, scale = 1, offsetX = 0.5, offsetY = 0.5) => {
    const childRatio = childWidth / childHeight
    const parentRatio = parentWidth / parentHeight
    let width = parentWidth * scale
    let height = parentHeight * scale

    if (contains ? (childRatio > parentRatio) : (childRatio < parentRatio)) {
      height = width / childRatio

    } else {
      width = height * childRatio

    }

    return {
      width,
      height,
      offsetX: (parentWidth - width) * offsetX,
      offsetY: (parentHeight - height) * offsetY

    }
  }
}

