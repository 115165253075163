import { FirestoreV8Compat } from '../Firebase';

import ProblemCollection, { ProblemCollectionConverter } from '../model/ProblemCollection';


export async function createProblemCollection(collection: ProblemCollection) {
  const convertedCollection = ProblemCollectionConverter.toFirestore(collection);
  return FirestoreV8Compat.collection('problem_collections').add(convertedCollection);
}

