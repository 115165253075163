import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";

import Problem, { ProblemData } from '../model/Problem';
import { VGradeValidator } from '../model/Grade';
import { createProblem, updateProblem } from '../database';

interface NewProblemProps {
  show: boolean;
  onHide: () => void;
  parent: string;
}

interface EditProblemProps {
  show: boolean;
  onHide: () => void;
  problem: Problem;
}

type ProblemModalProps = NewProblemProps | EditProblemProps;

function isNew(obj: {parent:string}|{problem:Problem}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function EditProblemModal({show, onHide, ...props}: ProblemModalProps) {
  const [oldProblem] = useState(isNew(props) ? undefined : props.problem);
  const [name, setName] = useState(isNew(props) ? '' : props.problem.name);
  const [description, setDescription] = useState(isNew(props) ? '' : props.problem.description);
  const [grade, setGrade] = useState(isNew(props) ? 'V?' : props.problem.grade);
  const [gradeIsValid, setGradeIsValid] = useState(true);
  const [isPrivate, setIsPrivate] = useState(isNew(props) ? false : props.problem.private);
  const [order] = useState(isNew(props) ? null : props.problem.order)
  const [parent] = useState(isNew(props) ? props.parent : props.problem.parent)
  const [faParty, setFaParty] = useState(isNew(props) ? undefined : props.problem.fa?.party);
  const [faDate, setFaDate] = useState(isNew(props) ? undefined : props.problem.fa?.year);
  const [unsafe, setUnsafe] = useState(isNew(props) ? false : props.problem.unsafe);
  const [rating, setRating] = useState(isNew(props) ? 0 : props.problem.rating);

  // TODO Refactor like the other modals
  const handleSave = () => {
    const newProblem: ProblemData = {
      name: name,
      description: description,
      private: isPrivate,
      order: order,
      parent: parent,
      grade: grade,
      rating: rating,
      style: [],
      unsafe: unsafe
    }
    
    // TODO hmm.. better way?
    if (faParty || faDate) {
      newProblem.fa = {};
      if (faParty) {
        newProblem.fa.party = faParty;
      }
      if (faDate) {
        newProblem.fa.year = faDate;
      }
    } else {
      delete newProblem.fa;
    }

    if (oldProblem) {
      // TODO: It'd be nice to use a diff to generate UpdateData<ProblemData>
      //       with field values if we ever need to delete a field.
      onHide();
      return updateProblem(oldProblem.id, newProblem);
    } else {
      onHide();
      return createProblem(newProblem);
    }
  }

  useEffect(() => {
    setGradeIsValid(new VGradeValidator().isAcceptable(grade));
  }, [grade]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isNew(props) ? 'Create Problem' : 'Update Problem'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row>
            <Form.Group className="col-sm-8" as={Col}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                defaultValue={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-sm-4" as={Col}>
              <Form.Label>Grade</Form.Label>
              <Form.Control
                isInvalid={!gradeIsValid}
                defaultValue={oldProblem?.grade ?? 'V?'}
                onChange={e => setGrade(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Safety Rating</Form.Label>
            <Form.Check
              id="another-meaningless-id-to-make-this-work"
              type="switch"
              checked={unsafe}
              onClick={() => setUnsafe(!unsafe)}
              label="Safety concern"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Rating</Form.Label>
            <Form.Control
              type="range"
              min="0"
              max="4"
              defaultValue={rating}
              onChange={e => setRating(+e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>First Ascent</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  defaultValue={faParty}
                  placeholder="FA Party"
                  onChange={e => setFaParty(e.target.value)}
                />
              </Col>
              <Col>
                <DatePicker
                  selected={faDate ? new Date(faDate, 1, 6) : undefined}
                  minDate={new Date(1900, 1, 0)}
                  maxDate={new Date()}
                  onChange={ date => { setFaDate(date ? (date as Date).getFullYear() : undefined)} }
                  isClearable
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Private</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={isPrivate}
              label="Private"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

