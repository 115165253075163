import styled from 'styled-components';

// Props can be passed directly or can be passed via styled-components
// ThemeProvider (context)

export const Title = styled.h2`
font-family: ${({theme}) => theme.serifFont};
font-weight: bold;
`

export const Subtitle = styled.h4`
font-family: ${({theme}) => theme.sansSerifFont};
font-weight: bold;
`

export const Header = styled.h6`
font-family: ${({theme}) => theme.sansSerifFont};
font-weight: bold;
`

export const Subtext = styled.p`
font-family: ${({theme}) => theme.serifFont};
font-size: x-small;
color: darkgray;
`

export const Text = styled.p`
font-family: ${({theme}) => theme.sansSerifFont};
`

export const Div = styled.div`
font-family: ${({theme}) => theme.sansSerifFont};
`

export const Span = styled.span`
font-family: ${({theme}) => theme.sansSerifFont};
`

