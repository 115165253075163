import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

import type { idAble } from './idAble';


type Classification = 'TODO' | 'INFO' | 'DEV' | 'REQ';

interface NoteData {
  parent: string | null;
  classification: Classification;
  content: string;
  creationTime: number;
}

type Note = idAble & NoteData;

const NoteConverter: FirestoreDataConverter<Note> = {
  toFirestore(note: WithFieldValue<Note>): DocumentData {
    const n = note;
    delete n.id;
    return (n);
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Note {
    // TODO: I should probably ensure that the fields that must exist, exist,
    //       otherwise I will get errors later.
    const data = snapshot.data(options) as Note;
    data.id = snapshot.id;
    return data;
  }
}

export type { Note, NoteData, Classification };
export { NoteConverter };

