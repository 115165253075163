import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Region, { regionConverter } from '../model/Region';

export function subscribeToRegions(
  onNext: (regions: Region[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'regions').withConverter(regionConverter);
  let colQuery = query(colRef);

  if (!isPrivileged) {
    colQuery = query(colQuery, where('public', '==', true))
  }

  colQuery = query(colQuery, orderBy('order'));

  return (
    onSnapshot(colQuery,
      querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data())),
      onError,
      onCompletion
    )
  );
}

