import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { SectorData } from '../model/Sector';


export async function createSector(sector: SectorData): Promise<void> {
  await FirestoreV8Compat.collection('sectors').add(sector);
  // returning void so all of these have the same return type
  return;
}

export async function updateSector(id: string, data: UpdateData<SectorData>) {
  return FirestoreV8Compat.doc(`sectors/${id}`).update(data);
}

export async function deleteSector(id: string) {
  return FirestoreV8Compat.doc(`sectors/${id}`).delete();
}

