import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Sector from '../model/Sector';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  sectors: Sector[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderSectorsModal({ sectors, show, onHide }: Props) {
  const [unsortedSectors, setUnsortedSectors] = React.useState<Sector[]>([]);
  const [sortedSectors, setSortedSectors] = React.useState<Sector[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Sector[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Sector[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(sectors.filter(sector => sector.order === null));
    setSortedResetState(sectors.filter(sector => sector.order !== null));
  }, [sectors])

  // Initial sector state
  useEffect(() => {
    setUnsortedSectors(sectors.filter(sector => sector.order === null));
    setSortedSectors(sectors.filter(sector => sector.order !== null));
  }, [sectors])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedSectors, sortedResetState) && deepEqual(unsortedSectors, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedSectors, sortedResetState, unsortedSectors, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedSectors.forEach((sector, index) => {
          const original = sectors.find(original => original.id === sector.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `sectors/${sector.id}`), {order: index})
          }
        });
        unsortedSectors.forEach(sector => {
          const original = sectors.find(original => original.id === sector.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `sectors/${sector.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [sectors, sortedSectors, unsortedSectors, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = sectors.filter(sector => unsorted.find(sortable => sortable.id === sector.id));
    const newSorted = sectors.filter(sector => sorted.find(sortable => sortable.id === sector.id))
      // sort sectors based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedSectors(newUnsorted);
    setSortedSectors(newSorted);
  }), [sectors]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Sectors</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedSectors.map(sector => ({id: sector.id ?? '', display: sector.name}))}
          defaultOrdered={sortedSectors.map(sector => ({id: sector.id ?? '', display: sector.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

