import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import {
  BsPencil,
  BsQuestionCircle,
  BsSortUp,
  BsTrash,
  BsUpload
} from 'react-icons/bs';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { PropagateLoader } from 'react-spinners'
import { getDownloadURL, ref } from 'firebase/storage';

import ResponsiveCardDeck from './ResponsiveCardDeck';
import {
  deleteSector,
  getZones,
  getBoulders,
  getInfoRequests, 
  subscribeToZones,
  subscribeToBoulders
} from '../database';
import UserContext from '../context/UserContext';
import { Note } from '../model/Note';
import Sector from '../model/Sector';
import { Title, Subtitle, Text } from './ThemedComponents';
import ZoneCard from './ZoneCard';
import BoulderCard from './BoulderCard';
import RequestsModal from './RequestsModal';
import FeedbackModal from './FeedbackModal';
import SectionContainer from './ContentSectionContainer';
import SectorModal from './SectorModal';
import BoulderModal from './BoulderModal';
import ZoneModal from './ZoneModal';
import CustomIcon from '../Icon';
import ReorderZonesModal from './ReorderZonesModal';
import ReorderBouldersModal from './ReorderBouldersModal';
import NoteAccordion from './NoteAccordion';
import UploadButton from './UploadButton';
import { MapStorage } from '../Firebase';
import { updateSector } from '../database';


interface Props {
  sector: Sector;
}

export default function SectorContent({ sector }: Props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState<string>('');

  const [showMapDelete, setShowMapDelete] = useState(false);
  const [showSectorModal, setShowSectorModal] = useState(false);
  const [showBoulderModal, setShowBoulderModal] = useState(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [showReorderBouldersModal, setShowReorderBouldersModal] = useState(false);
  const [showReorderZonesModal, setShowReorderZonesModal] = useState(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalRegards, setFeedbackModalRegards] = useState('');

  const { mutate: addMap } = useMutation(async (path: string) => {
    await updateSector(sector.id, {map: path});
  });

  const { mutate: removeMap } = useMutation(async () => {
    await updateSector(sector.id, {map: null});
  });

  const fetchZones = useCallback(async () => {
    return getZones(`sectors/${sector.id}` ?? null, user?.hasElevatedReadAccess());
  }, [user, sector.id]);

  const fetchBoulders = useCallback(async () => {
    return getBoulders(`sectors/${sector.id}` ?? null, user?.hasElevatedReadAccess());
  }, [user, sector.id]);

  const fetchReqs = useCallback(async () => {
    return getInfoRequests(`sectors/${sector.id}`);
  }, [sector.id]);

  const { isLoading: isLoadingZones, data: zones } = useQuery(['zones', sector.id, user], fetchZones);
  const { isLoading: isLoadingBoulders, data: boulders } = useQuery(['boulders', sector.id, user], fetchBoulders);
  const { data: reqs } = useQuery(['reqs', sector.id], fetchReqs);
  const { data: mapSource } = useQuery(['map', sector.map], async () => sector.map ? getDownloadURL(ref(MapStorage, sector.map)) : null );
  // TODO: Notes

  const setHighlightViaHash = useCallback(() => {
    const hash = window.location.hash;
    if (hash.split('-').length > 1) {
      setHighlight(hash.split('-')[1]);
    }
  }, [])

  // highlight on hash change
  useEffect(() => {
    // init
    setHighlightViaHash();
    window.addEventListener('hashchange', setHighlightViaHash, false)
    return (() => window.removeEventListener('hashchange', setHighlightViaHash, false));
  }, [setHighlightViaHash])

  // get zones
  useEffect(() => {
    if (sector && sector.id) {
      return subscribeToZones(`sectors/${sector.id}`, () => {
        queryClient.invalidateQueries(['zones', sector.id]);
      }, (error) => {
        console.log('There was an error subscribing to zones:', error);
      }, undefined, user?.hasElevatedReadAccess());
    }
  }, [sector, user, queryClient]);

  // get boulders
  useEffect(() => {
    if (sector && sector.id) {
      return subscribeToBoulders(sector.id, () => {
        queryClient.invalidateQueries(['boulders', sector.id]);
      }, (error) => {
        console.log('There was an error subscribing to boulders:', error);
      }, undefined, user?.hasElevatedReadAccess());
    }
  }, [sector, user, queryClient]);

  const handleRequestModalSubmitInfo = useCallback((note: Note) => {
    setShowRequestsModal(false);
    setFeedbackModalRegards(`note ${note.id}`);
    setShowFeedbackModal(true);
  }, [])

  const handleShowSectorModal = useCallback(() => {
    setShowSectorModal(true);
  }, []);

  const handleCloseSectorModal = useCallback(() => {
    setShowSectorModal(false);
  }, []);

  const handleShowBoulderModal = useCallback(() => {
    setShowBoulderModal(true);
  }, []);

  const handleCloseBoulderModal = useCallback(() => {
    setShowBoulderModal(false);
  }, []);

  const handleShowZoneModal = useCallback(() => {
    setShowZoneModal(true);
  }, []);

  const handleCloseZoneModal = useCallback(() => {
    setShowZoneModal(false);
  }, []);

  const handleShowReorderBouldersModal = useCallback(() => {
    setShowReorderBouldersModal(true);
  }, []);

  const handleCloseReorderBouldersModal = useCallback(() => {
    setShowReorderBouldersModal(false);
  }, []);

  const handleShowReorderZonesModal = useCallback(() => {
    setShowReorderZonesModal(true);
  }, []);

  const handleCloseReorderZonesModal = useCallback(() => {
    setShowReorderZonesModal(false);
  }, []);

  const handleMapUpload = useCallback((path: string) => {
    addMap(path);
  }, [addMap]);

  const handleDeleteMap = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete the map?')) {
      removeMap();
    }
  }, [removeMap]);

  const handleDeleteSector = useCallback(async () => {
    if (window.confirm(`Are you sure you want to delete ${sector.name}? This cannot be undone and will leave orphans.`)) {
      await deleteSector(sector.id);
      history.push('/guidebook');
    }
  }, [sector.id, sector.name, history]);

  return (
    <>
      { user?.hasWriteAccess() &&
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="primary" onClick={handleShowSectorModal}><BsPencil width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowBoulderModal}><CustomIcon.AddBoulder width="1em" height="1em" /></Button>
            <Button variant="primary" onClick={handleShowZoneModal}><CustomIcon.AddArea width="1em" height="1em" /></Button>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle>
                <BsSortUp width="1em" height="1em" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleShowReorderZonesModal}>Zones</Dropdown.Item>
                <Dropdown.Item onClick={handleShowReorderBouldersModal}>Boulders</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="danger" onClick={handleDeleteSector}><BsTrash width="1em" height="1em" /></Button>
          </ButtonGroup>
        </ButtonToolbar>
      }

      <ButtonToolbar className="justify-content-end">
        <ButtonGroup>
          {reqs !== undefined && reqs.length > 0 &&
          <Button
            onClick={() => setShowRequestsModal(true)}
          >
            <BsQuestionCircle />
            {" "}
            <Badge className="text-dark rounded-pill" bg="light">{reqs?.length}</Badge>
          </Button>
          }
        </ButtonGroup>
      </ButtonToolbar>

      <SectionContainer>
        <Row>
          <Col xs="auto">
            <Title>{sector ? sector.name : ''}</Title>
          </Col>
          {sector.private &&
          <Col xs="auto">
            <AiOutlineEyeInvisible size={38} color="#ffdc6b" />
          </Col>
          }
        </Row>
        <Row className="d-flex flex-wrap">
          <Col md="6" sm="12" className="flex-grow-1">
            <Row>
              <Col>
                <Subtitle id="description">Description</Subtitle>
                <Text>{sector.description}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Subtitle id="directions">Directions</Subtitle>
                <Text>{sector.directions}</Text>
              </Col>
            </Row>
          </Col>
          { (!sector.map && user?.isAdmin()) &&
          <Col md="6" sm="12" className="flex-grow-1">
            <UploadButton
              storage={MapStorage}
              path={'temp'}
              className="w-100 h-100"
              accept=".svg"
              onUpload={handleMapUpload}
            >
              <div>
                <BsUpload size="1.5em"/>
              </div>
              Upload Map
            </UploadButton>
          </Col>
          }
          { sector.map &&
          <Col md="6" sm="12" className="flex-grow-1" style={{position: 'relative'}}
            onMouseEnter={() => setShowMapDelete(true)}
            onMouseLeave={() => setShowMapDelete(false)}
          >
            {/* TODO On-click expand */}
            <Image
              style={{background: 'white', borderRadius: '0.5em'}}
              fluid
              src={mapSource ?? ''}
            />
            {showMapDelete &&
            <Button
              variant="danger"
              style={{position: 'absolute', right: '1em', top: '0.5em'}}
            >
              <BsTrash width="1em" height="1em" onClick={handleDeleteMap}/>
            </Button>
            }
          </Col>
          }
        </Row>
      </SectionContainer>

      <SectionContainer>
        <Row>
          <Col>
            { (isLoadingZones || isLoadingBoulders) &&
              <Row>
                <Col />
                <Col xs="auto">
                  <PropagateLoader size={15} color='#c28465'/>
                </Col>
                <Col />
              </Row>
            }
            {(zones && boulders) &&
              <ResponsiveCardDeck>
                <>
                {zones.map((zone, index) => {
                  return <ZoneCard highlight={highlight === zone.id} htmlId={`zone-${zone.id}`} key={`zone-${index}`} zone={zone}/>
                })}
                </>
                <>
                {boulders.map((boulder, index) => {
                    return <BoulderCard highlight={highlight === boulder.id} htmlId={`boulder-${boulder.id}`} key={`boulder-${index}`} boulder={boulder}/>
                })}
                </>
              </ResponsiveCardDeck>
            }
          </Col>
        </Row>
      </SectionContainer>

      { user?.hasElevatedReadAccess &&
      <NoteAccordion
        parent={`sectors/${sector.id}`}
      />
      }

      {/* Modals */}
      {user?.hasWriteAccess() &&
        <>
          <RequestsModal
            notes={reqs ?? []}
            show={showRequestsModal}
            onHide={() => setShowRequestsModal(false)}
            onSubmitInfo={handleRequestModalSubmitInfo}
          />
          <FeedbackModal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            withRegards={feedbackModalRegards}
          />
          <SectorModal
            show={showSectorModal}
            onHide={handleCloseSectorModal}
            sector={sector}
          />
          <BoulderModal
            show={showBoulderModal}
            onHide={handleCloseBoulderModal}
            parent={`sectors/${sector.id}`}
          />
          <ZoneModal
            show={showZoneModal}
            onHide={handleCloseZoneModal}
            parent={`sectors/${sector.id}`}
          />
          {zones &&
            <ReorderZonesModal
              show={showReorderZonesModal}
              onHide={handleCloseReorderZonesModal}
              zones={zones}
            />
          }
          {boulders &&
            <ReorderBouldersModal
              show={showReorderBouldersModal}
              onHide={handleCloseReorderBouldersModal}
              boulders={boulders}
            />
          }
        </>
      }
    </>
  );
}

