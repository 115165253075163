import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { BoulderData } from '../model/Boulder';


export async function createBoulder(boulder: BoulderData): Promise<void> {
  await FirestoreV8Compat.collection('boulders').add(boulder);
  // returning void so all of these have the same return type
  return;
}

export async function updateBoulder(id: string, data: UpdateData<BoulderData>) {
  return FirestoreV8Compat.doc(`boulders/${id}`).update(data);
}

export async function deleteBoulder(id: string) {
  return FirestoreV8Compat.doc(`boulders/${id}`).delete();
}

