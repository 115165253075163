import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

import { VGradeValidator } from './Grade';

export enum ProblemType {
  Highball = 'highball',
  Compression = 'compression',
  Traverse = 'traverse',
  Crimpy = 'crimpy',
  Crack = 'crack'
}

export type FirstAscentType = {
  party?: string;
  year?: number;
}

interface ProblemData {
  name: string;
  description: string;
  grade: string;
  parent: string;
  order: number | null;
  style: ProblemType[];
  unsafe: boolean;
  rating: number;
  private: boolean;

  fa?: FirstAscentType;
}

export interface Problem extends ProblemData {
  id: string;
}

let problemConverter:FirestoreDataConverter<Problem> = {
  toFirestore(problem: WithFieldValue<Problem>): DocumentData {
    let copy = {...problem};
    delete copy.id;

    if (!copy.fa) {
      delete copy.fa;
    }

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Problem {
    const data = snapshot.data(options);
    const problem: Problem = {
      id: snapshot.id,
      name: data.name ?? '',
      description: data.description ?? '',
      grade: data.grade ?? 'V?',
      style: [],
      unsafe: data.unsafe ?? false,
      rating: data.rating ?? 0,
      order: data.order ?? null,
      parent: data.parent ?? '',
      private: data.private ?? false
    }

    if (data.fa?.party || data.fa?.year) {
      problem.fa = {};
      if (data.fa?.party) {
        problem.fa.party = data.fa.party;
      }
      if (data.fa?.year) {
        problem.fa.year = data.fa.year;
      }
    }

    if (!(new VGradeValidator().isAcceptable(problem.grade))) {
      problem.grade = 'V?'
    }

    return problem;
  }
}

function isProblem(obj: object): obj is Problem {
  try {
    return (obj as Problem).grade !== undefined;
  } catch {
    return false;
  }
}

export default Problem;
export { problemConverter, isProblem };
export type { ProblemData };
