import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

enum RockType {
  Granite = 'granite',
  Limestone = 'limestone',
  Sandstone = 'sandstone',
  Schist = 'schist',
}

interface AreaData {
  name: string;
  directions: string;
  description: string;
  parent: string;
  order: number | null;
  map: string | null;
  rockType: RockType[];
  private: boolean;

  cardImageId?: string;
}
// volumes: ('free', 'vol1', 'vol2', 'baldySelect')[]

interface Area extends AreaData {
  id: string;
  discriminator: 'Area';
}

const areaConverter: FirestoreDataConverter<Area> = {
  toFirestore(area: WithFieldValue<Area>): DocumentData {
    let copy = {...area};
    delete copy.id;
    delete copy.discriminator;

    // delete any undefined values
    if (!copy.cardImageId) {
      delete copy.cardImageId;
    }

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Area {
    const data = snapshot.data(options);
    const area: Area = {
      id: snapshot.id,
      name: data.name ?? '',
      directions: data.directions ?? '',
      description: data.description ?? '',
      rockType: data.rockType ?? [],
      parent: data.parent ?? null,
      map: data.map ?? null,
      order: data.order ?? null,
      private: data.private ?? false,
      discriminator: 'Area'
    }

    if (data.cardImageId) {
      area.cardImageId = data.cardImageId;
    }

    return area;
  }
};

function isArea(obj: any): obj is Area {
  try {
    return (obj as Area).discriminator === 'Area';
  } catch {
    return false;
  }
}

export default Area;
export { areaConverter, isArea, RockType };
export type { AreaData };

