import { initializeApp } from "firebase/app"
// eslint-disable-next-line
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO Get rid of eventually...
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/auth';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyA2mg9QWvNtF9rJsOcThAUuEZ7jMSwkJpA",
    authDomain: "bhbh-5f0a6.firebaseapp.com",
    databaseURL: "https://bhbh-5f0a6.firebaseio.com",
    projectId: "bhbh-5f0a6",
    storageBucket: "bhbh-5f0a6.appspot.com",
    messagingSenderId: "426631733351",
    appId: "1:426631733351:web:6721ac8ca98e394f9474c2",
    measurementId: "G-28WF2W0MW4"
});

firebase.initializeApp({
    apiKey: "AIzaSyA2mg9QWvNtF9rJsOcThAUuEZ7jMSwkJpA",
    authDomain: "bhbh-5f0a6.firebaseapp.com",
    databaseURL: "https://bhbh-5f0a6.firebaseio.com",
    projectId: "bhbh-5f0a6",
    storageBucket: "bhbh-5f0a6.appspot.com",
    messagingSenderId: "426631733351",
    appId: "1:426631733351:web:6721ac8ca98e394f9474c2",
    measurementId: "G-28WF2W0MW4"
})

export let Auth = firebase.app().auth();
export let Firestore = getFirestore(firebaseApp);
export let FirestoreV8Compat = firebase.app().firestore();
export let Storage = getStorage(firebaseApp);
export let ImageStorage = getStorage(firebaseApp, 'gs://bhbh-5f0a6-images');
export let MapStorage = getStorage(firebaseApp, 'gs://bhbh-5f0a6-maps');
export let Functions = firebase.app().functions();

// Start emulators with `firebase emulators:start`
// Uncomment out the emulator lines below to use emulators
if (window.location.hostname === 'localhost') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    //Functions.useEmulator('localhost', 5001)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    //FirestoreV8Compat.useEmulator('localhost', 8080);
    //connectFirestoreEmulator(Firestore, 'localhost', 8080);
}

// TODO: Need to implement some image storage retriever that takes a prefered
// size and gets the image..

