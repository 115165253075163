import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface BoulderData {
  name: string;
  description: string;
  directions: string;
  coordinates: Coordinates | null;
  parent: string;
  order: number | null;
  private: boolean;
}

interface Boulder extends BoulderData {
  id: string;
  discriminator: 'Boulder'
}

let boulderConverter: FirestoreDataConverter<Boulder> = {
  toFirestore(boulder: WithFieldValue<Boulder>): DocumentData {
    let copy = {...boulder};
    delete copy.id;
    delete copy.discriminator;

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Boulder {
    // Make no assumptions about database typings
    const data = snapshot.data(options);
    const boulder: Boulder = {
      id: snapshot.id,
      name: data.name ?? '',
      directions: data.directions ?? '',
      description: data.description ?? '',
      coordinates: data.coordinates ?? null,
      parent: data.parent ?? '',
      order: data.order ?? null,
      private: data.private ?? false,
      discriminator: 'Boulder'
    }

    return boulder;
  }
}

function isBoulder(obj: any): obj is Boulder {
  try {
    return (obj as Boulder).discriminator === 'Boulder';
  } catch {
    return false;
  }
}

export default Boulder;
export { isBoulder, boulderConverter };
export type { BoulderData, Coordinates };
