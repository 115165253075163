import React, { CSSProperties } from 'react';
import granite_sm from './assets/granite_48x48.png';
import granite_md from './assets/granite_96x96.png';
import granite_lg from './assets/granite_256x256.png';
import schist_sm from './assets/schist_48x48.png';
import schist_md from './assets/schist_96x96.png';
import schist_lg from './assets/schist_256x256.png';
import limestone_sm from './assets/limestone_48x48.png';
import limestone_md from './assets/limestone_96x96.png';
import limestone_lg from './assets/limestone_256x256.png';
import sandstone_sm from './assets/sandstone_48x48.png';
import sandstone_md from './assets/sandstone_96x96.png';
import sandstone_lg from './assets/sandstone_256x256.png';

interface IconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
  style?: CSSProperties;
  className?: string;
}

export interface PNGIconProps {
  size?: 'sm' | 'md' | 'lg';
}

export default function Icon() {
  // just a container for icons
}

Icon.Area = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      style={style}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M 0 6.856 L 3.428 6.856 L 3.428 10.284 L 0 10.284 L 0 6.856 Z M 0 13.712 L 3.428 13.712 L 3.428 17.14 L 0 17.14 L 0 13.712 Z M 20.568 13.712 L 23.996 13.712 L 23.996 17.14 L 20.568 17.14 L 20.568 13.712 Z M 13.712 20.568 L 17.14 20.568 L 17.14 23.996 L 13.712 23.996 L 13.712 20.568 Z M 6.856 20.568 L 10.284 20.568 L 10.284 23.996 L 6.856 23.996 L 6.856 20.568 Z M 20.568 6.856 L 23.996 6.856 L 23.996 10.284 L 20.568 10.284 L 20.568 6.856 Z M 13.712 0 L 17.14 0 L 17.14 3.428 L 13.712 3.428 L 13.712 0 Z M 6.856 0 L 10.284 0 L 10.284 3.428 L 6.856 3.428 L 6.856 0 Z M 3.428 0 L 3.422 3.427 L 0 3.4210000000000003 L 3.428 0 Z M 20.571 0.003 L 20.571 3.43 L 23.993 3.43 L 20.571 0.003 Z M 20.574 23.995 L 20.574 20.568 L 23.996 20.568 L 20.574 23.995 Z M 3.428 23.995 L 3.428 20.568 L 0.006 20.568 L 3.428 23.995 Z"
      />
    </svg>
  );
}

Icon.AddArea = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      style={style}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M 0 6.8580000000000005 L 3.4290000000000003 6.8580000000000005 L 3.4290000000000003 10.286 L 0 10.286 L 0 6.8580000000000005 Z M 3.4290000000000003 23.999 L 3.4290000000000003 20.572 L 0.006 20.572 L 3.4290000000000003 23.999 Z M 20.575 0.004 L 20.575 3.431 L 23.997 3.431 L 20.575 0.004 Z M 3.4290000000000003 0 L 3.423 3.427 L 0 3.4210000000000003 L 3.4290000000000003 0 Z M 6.8580000000000005 0 L 10.286 0 L 10.286 3.428 L 6.8580000000000005 3.428 L 6.8580000000000005 0 Z M 13.714 0 L 17.143 0 L 17.143 3.428 L 13.714 3.428 L 13.714 0 Z M 20.573 6.8580000000000005 L 24 6.8580000000000005 L 24 10.286 L 20.573 10.286 L 20.573 6.8580000000000005 Z M 6.8580000000000005 20.572 L 10.286 20.572 L 10.286 24 L 6.8580000000000005 24 L 6.8580000000000005 20.572 Z M 0 13.714 L 3.4290000000000003 13.714 L 3.4290000000000003 17.142 L 0 17.142 L 0 13.714 Z M 18.008 14.013 L 20.005 14.013 L 20.005 18.008 L 24 18.008 L 24 20.005 L 20.005 20.005 L 20.005 24 L 18.008 24 L 18.008 20.005 L 14.013 20.005 L 14.013 18.008 L 18.008 18.008 Z"
      />
    </svg>
  );
}

Icon.Boulder = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      style={style}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        // thimble
        d="M 3.939 24 C 2.535 22.273 6.593 9.85 8.305 8.789 C 10.316 7.544 11.563 0.1 14.052 0.004 C 19.431 -0.217 20.646 9.775 20.295 10.984 C 15.914 12.622 16.175 22.981 16.372 23.945"
        // campground
        // d="M 4.184 23.95 C 4.615 23.507 3.101 20.247 1.96 20.166 C 1.473 19.867 -0.043 16.902 0.132 15.988 C -0.309 15.202 1.356 10.631 0.774 10.483 C 0.106 9.893 -0.304 6.355 0.281 5.913 C 0.348 4.262 6.454 2.323 7.693 2.473 C 10.514 2.02 18.437 5.15 19.997 7.289 C 21.241 8.316 22.336 11.115 22.666 12.648 C 22.48 14.222 23.5 15.53 24 16.578 C 23.654 18.894 21.925 21.372 21.579 24"
        // prow
        // d="M 0.445 5.088 C 3.884 3.502 5.022 0.388 6.264 0.164 C 6.933 0.055 7.952 -0.234 8.704 0.379 C 10.106 1.523 13.225 3.59 14.254 3.926 C 14.878 4.429 19.675 5.257 22.703 5.201 C 23.599 5.185 23.704 5.502 24 6.157 C 23.363 7.33 24.61 17.412 22.43 20.374 C 20.271 19.883 14.121 20.619 12.684 21.629 C 10.525 22.328 7.055 24.064 5.432 23.998 C 3.552 23.779 0.056 21.464 0.121 21.303 C 0.406 20.599 0.667 9.397 0.017 5.78 C -0.053 5.389 0.092 5.102 0.445 5.088 Z"


      />
    </svg>
  );
}

Icon.AddBoulder = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        width={width}
        height={height}
        style={style}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill={fill}
          // thimble
          d="M 18.008 14.013 L 20.005 14.013 L 20.005 18.008 L 24 18.008 L 24 20.005 L 20.005 20.005 L 20.005 24 L 18.008 24 L 18.008 20.005 L 14.013 20.005 L 14.013 18.008 L 18.008 18.008 L 18.008 14.013 Z M 3.939 24 C 2.535 22.273 6.593 9.85 8.305 8.789 C 10.316 7.544 11.563 0.1 14.052 0.004 C 19.431 -0.217 20.646 9.775 20.295 10.984 C 18.226 11.758 17.192 14.477 16.698 17.234 C 16.668 17.398 16.562 17.377 16.658 17.514 C 16.698 17.571 13.518 17.514 13.52 17.563 C 13.521 17.585 13.53 20.383 13.533 20.374 C 13.593 20.396 16.182 20.297 16.221 20.446 C 16.259 20.592 16.232 20.661 16.219 20.812 C 16.091 22.29 16.331 23.745 16.372 23.945"
          // campground
          // d="M 4.184 23.95 C 4.615 23.507 3.101 20.247 1.96 20.166 C 1.473 19.867 -0.043000000000000003 16.902 0.132 15.988 C -0.309 15.202 1.3559999999999999 10.631 0.774 10.483 C 0.10600000000000001 9.893 -0.304 6.355 0.281 5.913 C 0.348 4.2620000000000005 6.454 2.323 7.693 2.473 C 10.514 2.02 18.437 5.15 19.997 7.289 C 21.241 8.316 22.336 11.115 22.666 12.648 C 22.48 14.222 23.5 15.53 24 16.578 C 23.93 17.046 24.088 17.188 23.815 17.614 C 23.772 17.681 20.271 17.511 20.374 17.579 C 20.427 17.614 20.418 13.604 20.422 13.618 C 20.45 13.711 17.639 13.596 17.579 13.600999999999999 C 17.529 13.606 17.647 17.412 17.614 17.527 C 17.598 17.584 13.833 17.676 13.739 17.686 C 13.597 17.701 13.669 20.235 13.684 20.291 C 13.744 20.518 17.615 20.463 17.583 20.439 C 17.542 20.409 17.641 23.76 17.622 23.922 C 17.603 24.088 21.579 23.996 21.579 24 M 18.008 14.013 L 20.005 14.013 L 20.005 18.008 L 24 18.008 L 24 20.005 L 20.005 20.005 L 20.005 24 L 18.008 24 L 18.008 20.005 L 14.013 20.005 L 14.013 18.008 L 18.008 18.008 L 18.008 14.013 Z M 23 20.311 C 22.203 20.231 21.588 23.97 21.884 24 L 20.298000000000002 24 L 20.469 20.353"
          // prow
          // d="M 18.008 14.013 L 20.005 14.013 L 20.005 18.008 L 24 18.008 L 24 20.005 L 20.005 20.005 L 20.005 24 L 18.008 24 L 18.008 20.005 L 14.013 20.005 L 14.013 18.008 L 18.008 18.008 L 18.008 14.013 Z M 0.445 5.088 C 3.884 3.502 5.022 0.388 6.264 0.164 C 6.933 0.055 7.952 -0.234 8.704 0.379 C 10.106 1.523 13.225 3.59 14.254 3.926 C 14.878 4.429 19.675 5.257 22.703 5.201 C 23.599 5.185 23.704 5.502 24 6.157 C 23.52 7.041 22.716 15.441 23.109 17.517 C 23.124 17.595 20.56 17.489 20.542 17.511 C 20.538 17.517 20.501 13.507 20.491 13.604 C 20.486 13.65 17.636 13.547 17.618 13.608 C 17.594 13.689 17.555 17.533 17.637 17.574 C 17.637 17.574 13.704 17.581 13.694 17.591 C 13.669 17.616 13.71 20.321 13.707 20.354 C 13.704 20.395 22.949 20.352 22.902 20.417 C 20.743 20.312 14.121 20.619 12.684 21.629 C 10.525 22.328 7.055 24.064 5.432 23.998 C 3.552 23.779 0.056 21.464 0.121 21.303 C 0.406 20.599 0.667 9.397 0.017 5.78 C -0.053 5.389 0.092 5.102 0.445 5.088 Z"
        />
      </svg>
    );
}

Icon.Problem = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        width={width}
        height={height}
        style={style}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill={fill}
          d="M 17.322 0.404 L 19.76 3.017 L 16.586 3.825 L 17.322 0.404 Z M 20.145 5.023 L 17.821 8.849 L 16.97 5.831 L 20.145 5.023 Z M 14.626 6.424 L 15.477 9.442 L 12.302 10.25 L 11.452 7.233 L 14.626 6.424 Z M 6.605 11.879 L 8.929 8.052 L 9.78 11.07 L 6.605 11.879 Z M 10.357 13.472 L 11.208 16.49 L 8.033 17.298000000000002 L 7.183 14.281 L 10.357 13.472 Z M 11.679 18.768 L 9.356 22.593 L 8.505 19.577 L 11.679 18.768 Z M 6.201 20.175 L 7.052 23.192 L 3.8770000000000002 24 L 3.027 20.983 L 6.201 20.175 Z"
        />
      </svg>
    );
}

Icon.AddProblem = (props: IconProps) => {
  let { width, height, fill, style, className } = props;

  return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        width={width}
        height={height}
        style={style}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill={fill}
          d="M 17.999 13.998 L 19.999 13.998 L 19.999 17.999 L 24 17.999 L 24 19.999 L 19.999 19.999 L 19.999 24 L 17.999 24 L 17.999 19.999 L 13.998 19.999 L 13.998 17.999 L 17.999 17.999 Z M 17.322 0.404 L 19.76 3.017 L 16.586 3.825 L 17.322 0.404 Z M 20.145 5.023 L 17.821 8.849 L 16.97 5.831 L 20.145 5.023 Z M 14.626 6.424 L 15.477 9.442 L 12.302 10.25 L 11.452 7.233 L 14.626 6.424 Z M 6.605 11.879 L 8.929 8.052 L 9.78 11.07 L 6.605 11.879 Z M 10.357 13.472 L 11.208 16.49 L 8.033 17.298000000000002 L 7.183 14.281 L 10.357 13.472 Z M 11.679 18.768 L 9.356 22.593 L 8.505 19.577 L 11.679 18.768 Z M 6.201 20.175 L 7.052 23.192 L 3.8770000000000002 24 L 3.027 20.983 L 6.201 20.175 Z"
        />
      </svg>
    );
}

Icon.Granite = (props: PNGIconProps) => {
  let src = '../assets/granite_96x96.png';

  switch(props.size) {
    case 'sm':
      src = granite_sm;
      break;
    case 'md':
      src = granite_md;
      break;
    case 'lg':
      src = granite_lg;
      break;
    default:
      src = granite_md;
      break;
  }

  return (
    <img
      src={src}
      alt="granite"
    />
  );
}

Icon.Schist = (props: PNGIconProps) => {
  let src = '../assets/schist_96x96.png';

  switch(props.size) {
    case 'sm':
      src = schist_sm;
      break;
    case 'md':
      src = schist_md;
      break;
    case 'lg':
      src = schist_lg;
      break;
    default:
      src = schist_md;
      break;
  }

  return (
    <img
      src={src}
      alt="schist"
    />
  );
}

Icon.Limestone = (props: PNGIconProps) => {
  let src = '../assets/limestone_96x96.png';

  switch(props.size) {
    case 'sm':
      src = limestone_sm;
      break;
    case 'md':
      src = limestone_md;
      break;
    case 'lg':
      src = limestone_lg;
      break;
    default:
      src = limestone_md;
      break;
  }

  return (
    <img
      src={src}
      alt="limestone"
    />
  );
}

Icon.Sandstone = (props: PNGIconProps) => {
  let src = '../assets/sandstone_96x96.png';

  switch(props.size) {
    case 'sm':
      src = sandstone_sm;
      break;
    case 'md':
      src = sandstone_md;
      break;
    case 'lg':
      src = sandstone_lg;
      break;
    default:
      src = sandstone_md;
      break;
  }

  return (
    <img
      src={src}
      alt="sandstone"
    />
  );
}

