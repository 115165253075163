import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

import { idAble } from './idAble';

enum RockType {
  Granite = 'granite',
  Limestone = 'limestone',
  Sandstone = 'sandstone',
  Schist = 'schist',
}

interface SectorData {
  name: string;
  directions: string;
  description: string;
  parent: string;
  order: number | null;
  map: string | null;
  rockType: RockType[];
  private: boolean;
}

interface Discriminator {
  discriminator: 'Sector';
}

type Sector = idAble & SectorData & Discriminator;

const sectorConverter: FirestoreDataConverter<Sector> = {
  toFirestore(sector: WithFieldValue<Sector>): DocumentData {
    let copy = {...sector};
    delete copy.id;
    delete copy.discriminator;

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Sector {
    const data = snapshot.data(options);
    const sector: Sector = {
      id: snapshot.id,
      name: data.name ?? '',
      directions: data.directions ?? '',
      description: data.description ?? '',
      rockType: data.rockType ?? [],
      parent: data.parent ?? null,
      map: data.map ?? null,
      order: data.order ?? null,
      private: data.private ?? false,
      discriminator: 'Sector'
    }

    return sector;
  }
};

function isSector(obj: any): obj is Sector {
  try {
    return (obj as Sector).discriminator === 'Sector';
  } catch {
    return false;
  }
}

export default Sector;
export { sectorConverter, isSector, RockType };
export type { SectorData };

