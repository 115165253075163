import { createContext } from 'react';

import User from '../model/User';

interface ContextType {
  user?: User | null;
  setUser: (user: User | null) => void;
}

const UserContext = createContext<ContextType>({
  user: null,
  setUser: () => {}
});

export default UserContext;

