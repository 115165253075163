import React, { useState, useCallback, useEffect } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import { useQuery, useQueryClient } from 'react-query';

import Feedback from '../../model/Feedback';
import { getFeedback, subscribeToFeedback } from '../../database';
import FeedbackItem from './FeedbackItem';


export default function FeedbackMonitor() {
  const queryClient = useQueryClient();
  
  const fetchFeedback = useCallback(() => {
    return getFeedback();
  }, []);
  const { isError, data: feedback } = useQuery(['admin-feedback'], fetchFeedback);

  const [unresolvedFeedback, setUnresolvedFeedback] = useState<Feedback[]>([])
  const [resolvedFeedback, setResolvedFeedback] = useState<Feedback[]>([])

  useEffect(() => {
    return subscribeToFeedback(() => queryClient.invalidateQueries('admin-feedback'));
  }, [queryClient])

  // set resolved and unresolved feedbacks
  useEffect(() => {
    if (feedback) {
      setUnresolvedFeedback(feedback.filter(f => !f.resolved));
      setResolvedFeedback(feedback.filter(f => f.resolved));
    } else if (isError) {
      setUnresolvedFeedback([]);
      setResolvedFeedback([]);
    }
  }, [feedback, isError]);

  return (
    <Tabs defaultActiveKey="unresolved">
      <Tab eventKey="unresolved" title="Unresolved">
        <ListGroup className="mt-4">
          {unresolvedFeedback.map(f => <FeedbackItem feedback={f} />)}
        </ListGroup>
      </Tab>
      <Tab eventKey="resolved" title="Resolved">
        <ListGroup className="mt-4">
          {resolvedFeedback.map(f => <FeedbackItem feedback={f} />)}
        </ListGroup>
      </Tab>
    </Tabs>
  );
}

