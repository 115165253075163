import React, { useContext } from 'react';

import ThemeContext from '../context/ThemeContext';
import { MoonLoader } from 'react-spinners';


export default function Loading() {
  const style = { display: 'grid', placeItems: 'center', marginTop: '1rem' }
  const { theme } = useContext(ThemeContext);

  return (
    <div style={style}>
      <MoonLoader color={theme.primaryColor}/>
    </div>
  );
}

