import React, { useEffect, useState, useCallback,ChangeEvent } from 'react';
import deepEqual from 'deep-equal';
import { useMutation } from 'react-query'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Sector, { SectorData } from '../model/Sector';
import { createSector, updateSector } from '../database';


interface NewSectorProps {
  show: boolean;
  onHide: () => void;
  parent: string;
}

interface EditSectorProps {
  show: boolean;
  onHide: () => void;
  sector: Sector;
}

type SectorModalProps = NewSectorProps | EditSectorProps;

function isNew(obj: {parent:string}|{sector:Sector}): obj is {parent:string} {
  try {
    return (obj as {parent:string}).parent !== undefined;
  } catch {
    return false;
  }
}

export default function SectorModal({show, onHide, ...props }: SectorModalProps) {
  const [oldProps, setOldProps] = useState(props);
  const [oldSector, setOldSector] = useState(isNew(props) ? undefined : props.sector);
  const [name, setName] = useState(isNew(props) ? '' : props.sector.name);
  const [description, setDescription] = useState(isNew(props) ? '' : props.sector.description);
  const [directions, setDirections] = useState(isNew(props) ? '' : props.sector.directions)
  const [isPrivate, setIsPrivate] = useState(isNew(props) ? false : props.sector.private);
  const [parent, setParent] = useState(isNew(props) ? props.parent : props.sector.parent)
  // TODO: Create a react-like rock type form control
  const [order, setOrder] = useState(isNew(props) ? null : props.sector.order);
  const [map, setMap] = useState(isNew(props) ? null : props.sector.map);
  const [rockTypes] = useState(isNew(props) ? [] : props.sector.rockType);

  // useState won't update bindings that rely on `isNew(props)` for some
  // reason, do it manaully here, also its firing even though props isn't
  // changing, what the fuck?
  useEffect(() => {
    if (deepEqual(oldProps, props)) {
      return;
    }
    setOldProps(props);

    if (isNew(props)) {
      setOldSector(undefined);
      setName('');
      setDescription('');
      setDirections('');
      setIsPrivate(false);
      setOrder(null);
      setMap(null);
      setParent(props.parent)
    } else {
      setOldSector(props.sector);
      setName(props.sector.name);
      setDescription(props.sector.description);
      setDirections(props.sector.directions);
      setIsPrivate(props.sector.private);
      setOrder(props.sector.order);
      setMap(props.sector.map);
      setParent(props.sector.parent)
    }
  }, [oldProps, props])

  const { mutateAsync: mutateSector } = useMutation((): Promise<void> => {
    const newSector: SectorData = {
      name: name,
      description: description,
      directions: directions,
      private: isPrivate,
      order: order,
      parent: parent,
      map: map,
      rockType: rockTypes
    }
    if (oldSector) {
      // TODO: It'd be nice to use a diff to generate UpdateData<SectorData>
      //       with field values if we ever need to delete a field.
      return updateSector(oldSector.id, newSector);
    } else {
      return createSector(newSector);
    }
  });

  const handleSave = useCallback(async () => {
    try {
      await mutateSector();
    } catch (e) {
      console.log('There was a problem mutating sector:', e);
    }
    onHide();
  }, [onHide, mutateSector]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{oldSector ? 'Update Sector' : 'Create Sector'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Directions</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={directions}
              onChange={e => setDirections(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Privateity</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={isPrivate}
              label="Private"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

