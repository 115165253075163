import React, { useState, useCallback, useContext } from 'react';

import { useQuery } from 'react-query';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { BsJournalPlus } from 'react-icons/bs';

import UserContext from '../context/UserContext';
import { NoteItem } from './NotesModal';
import Loading from './Loading';
import { getNotes } from '../database';
import NoteModal from './NoteModal';


interface NoteAccordionProps {
  parent: string;
}

export default function NoteAccordion({ parent }: NoteAccordionProps) {
  // state
  const { user } = useContext(UserContext);
  const [showNoteModal, setShowNoteModal] = useState(false);

  // queries
  const fetchNotes = useCallback(async () => {
    return getNotes(parent);
  }, [parent]);
  const { isLoading: isLoadingNotes, data: notes } = useQuery(['notes', parent, user], fetchNotes);

  // handlers
  const handleShowNoteModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowNoteModal(true);
  }, []);

  const handleCloseNoteModal = useCallback(() => {
    setShowNoteModal(false);
  }, []);

  return (
    <>
      <Accordion className="mt-3" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Row>
              <Col>
                <h4>Notes</h4>
              </Col>
              <Col xs="auto">
                <ButtonToolbar className="justify-content-end">
                  <ButtonGroup>
                    <Button
                      onClick={handleShowNoteModal}
                    >
                      <BsJournalPlus/>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
              <Row>
                <Col>
                  { isLoadingNotes &&
                  <Row>
                    <Col />
                    <Col xs="auto">
                      <Loading />
                    </Col>
                    <Col />
                  </Row>
                  }
                  {notes &&
                    <ListGroup>
                      {notes.map((note) => {
                        return <NoteItem key={note.id} note={note} />
                      })}
                    </ListGroup>
                  }
                </Col>
              </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      { user?.hasWriteAccess() &&
      <NoteModal
        show={showNoteModal}
        onHide={handleCloseNoteModal}
        parent={parent}
      />
      }
    </>
  );
}
