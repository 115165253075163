import {
  doc,
  getDoc
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import { areaConverter } from '../model/Area';

export async function getArea(
  id: string
) {
  let docRef = doc(Firestore, `areas/${id}`).withConverter(areaConverter);

  return (await getDoc(docRef)).data();
}

