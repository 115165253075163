import { SearchOptions } from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch';

import client from './searchClient';
import { problemIndex, sortedProblemIndex } from './indeces';
import { ProblemHit } from './hitTypes'


export interface ProblemFilters {
  vGradeBasic?: boolean;
  vGradeUnknown?: boolean;
  vGradeProject?: boolean;
  vGradeRange?: number[];
  // TODO safety boolean
  qualityRange?: number[];
  styles?: string[];
  yearRange?: number[];
  unknownYear?: boolean;
  ancestryId?: string;
}

export interface ProblemQuery {
  text?: string;
  page?: number;
  textAttributes?: string[];
  vGradeBasic?: boolean;
  vGradeUnknown?: boolean;
  vGradeProject?: boolean;
  vGradeRange?: number[];
  // TODO safety boolean
  qualityRange?: number[];
  styles?: string[];
  yearRange?: number[];
  unknownYear?: boolean;
  areaId?: string;
  maxEntries?: number;
}

type SortableAttributes = 'name' | 'grade' | 'safety' | 'quality' | 'ancestry';
type SortDirection = 'ascending' | 'descending';

export class ProblemSearch {
  filters?: ProblemFilters;
  text?: string;
  page?: number;
  maxPage?: number;
  sorter?: SortableAttributes;
  sortDirection?: SortDirection;

  private _index: SearchIndex;
  private _privileged: boolean;

  constructor(privileged=false) {
    this._index = client.initIndex(problemIndex());
    this._privileged = privileged;
  }

  filterBy(filters: ProblemFilters) {
    this.filters = filters;
    return this;
  }

  sortBy(sorter: SortableAttributes, direction: SortDirection = 'ascending') {
    this.sorter = sorter;
    this.sortDirection = direction;
    this._index = client.initIndex(sortedProblemIndex(sorter, direction))
    return this;
  }

  setText(s: string) {
    this.text = s;
    return this;
  }

  pageTo(page: number) {
    this.page = page;
    return this;
  }

  limitTo(max: number) {
    this.maxPage = max;
    return this;
  }

  private _convertFilters(filters: ProblemFilters): string {
    const outerFilters: string[] = [];

    // grade-filters
    const gradeFiltersArray: string[] = [];
    if (filters.vGradeRange && filters.vGradeRange.length > 1) {
      gradeFiltersArray.push(`_indexableGrade:${filters.vGradeRange[0]} TO ${filters.vGradeRange[1]}`);
    }
    if (filters.vGradeBasic) {
      //gradeFiltersArray.push(`_indexableGrade = ${vSpecialEnum.BASIC}`);
    }
    if (filters.vGradeUnknown) {
      //gradeFiltersArray.push(`_indexableGrade = ${vSpecialEnum.UNKNOWN}`);
    }
    if (filters.vGradeProject) {
      //gradeFiltersArray.push(`_indexableGrade = ${vSpecialEnum.PROJECT}`);
    }
    if (gradeFiltersArray.length > 0) {
      outerFilters.push(`(${gradeFiltersArray.join(' OR ')})`);
    }
    // end grade-filters

    // safety-filters
    // TODO
    // end safety-filters

    // style-filters
    if (filters.styles && filters.styles.length > 0) {
      let styleFilters = filters.styles.map(style => `style:${style}`);
      outerFilters.push(`(${styleFilters.join(' OR ')})`);
    }
    // end style-filters

    // rating-filters
    if (filters.qualityRange && filters.qualityRange.length > 1) {
      outerFilters.push(`(rating:${filters.qualityRange[0]} TO ${filters.qualityRange[1]})`);
    }
    // end rating-filters

    // fa date filters
    const faFilterArray: string[] = [];
    if (filters.yearRange && filters.yearRange.length > 1) {
      faFilterArray.push(`(_faYear:${filters.yearRange[0]} TO ${filters.yearRange[1]})`);
    }
    if (filters.unknownYear) {
      faFilterArray.push('_faYear = -1');
    }
    if (faFilterArray.length > 0) {
      outerFilters.push(`(${faFilterArray.join(' OR ')})`);
    }
    // end fa date filters

    // ancestry filters
    if (filters.ancestryId) {
      outerFilters.push(`(_ancestryIds:${filters.ancestryId})`);
    }
    // end ancestry filters

    // public filters
    if (!this._privileged) {
      outerFilters.push(`(public:true)`);
    }
    // end public filters

    // combine filters
    return outerFilters.join(' AND ');
  }

  async get() {
    const searchOptions: SearchOptions = {
      page: this.page ?? 0,
      hitsPerPage: this.maxPage ?? 10,
      filters: this.filters ? this._convertFilters(this.filters) : undefined
    };

    let { hits, nbPages, page, nbHits } = await this._index.search(this.text ?? '', searchOptions);

    return ({
      hits: hits as ProblemHit[],
      page: page,
      pages: nbPages,
      nbHits: nbHits
    });
  }
}

