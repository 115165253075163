import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import Logo from './assets/logo_512.png';


export default function Home() {
  return (
    <div className="pt-4 pb-4" style={{backgroundColor: "#e9ecef"}}>
      <Container>
        <Row>
          <Col sm={1} md={3} lg={4} />
          <Col xs={12} sm={10} md={6} lg={4}>
            <Image fluid src={Logo} />
          </Col>
          <Col sm={1} md={3} lg={4} />
        </Row>
      </Container>
    </div>
  )
}

