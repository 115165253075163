import React, { useState, useEffect, ChangeEvent } from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

import { ProblemQuery } from '../search/index';


interface SearchFiltersFormProps {
  onChange?: (filters: ProblemQuery) => void;
  defaultFilters?: ProblemQuery;
}

export default function SearchFiltersForm({defaultFilters, onChange}: SearchFiltersFormProps) {
  const [gradeFilterActive, setGradeFilterActive] = useState(
    defaultFilters?.vGradeBasic !== undefined ||
    defaultFilters?.vGradeUnknown !== undefined ||
    defaultFilters?.vGradeProject !== undefined ||
    defaultFilters?.vGradeRange !== undefined
  );
  const [qualityFiltersActive, setQualityFiltersActive] = useState(
    defaultFilters?.qualityRange !== undefined
  );
  const [faFiltersActive, setFaFiltersActive] = useState(
    defaultFilters?.yearRange !== undefined
  );

  // grade filters
  const [gradeBasic, setGradeBasic] = useState(defaultFilters?.vGradeBasic ?? false);
  const [gradeUnknown, setGradeUnknown] = useState(defaultFilters?.vGradeUnknown ?? false);
  const [gradeProject, setGradeProject] = useState(defaultFilters?.vGradeProject ?? false);
  const [gradeRangeActive, setGradeRangeActive] = useState((defaultFilters?.vGradeRange ?? []).length > 1);
  const [gradeRange] = useState((defaultFilters?.vGradeRange ?? [0, 17]).length < 1 ? [0, 17] : defaultFilters?.vGradeRange ?? [0,17]);

  // quality filters
  const [qualityRange] = useState((defaultFilters?.qualityRange ?? [0, 4]).length < 1 ? [0, 4] : defaultFilters?.qualityRange ?? [0,4]);

  // fa filters
  const [startDate, setStartDate] = useState<Date | null>(defaultFilters?.yearRange ? new Date(defaultFilters.yearRange[0], 0) : new Date(1900, 0));
  const [endDate, setEndDate] = useState<Date | null>(defaultFilters?.yearRange ? new Date(defaultFilters.yearRange[1], 0) : new Date());
  const [yearRangeActive, setYearRangeActive] = useState((defaultFilters?.yearRange ?? []).length > 1);
  const [yearUnknown, setYearUnknown] = useState(defaultFilters?.unknownYear ?? false);

  // on change output the state
  useEffect(() => {
    let filters: ProblemQuery = {
      vGradeBasic: gradeFilterActive ? gradeBasic : undefined,
      vGradeUnknown: gradeFilterActive ? gradeUnknown : undefined,
      vGradeProject: gradeFilterActive ? gradeProject : undefined,
      vGradeRange: gradeFilterActive ? (gradeRangeActive ? gradeRange : []) : undefined,
      qualityRange: qualityFiltersActive ? qualityRange : undefined,
      yearRange: faFiltersActive ? (yearRangeActive ? [startDate?.getFullYear() ?? 1900, endDate?.getFullYear() ?? new Date().getFullYear()] : undefined) : undefined,
      unknownYear: faFiltersActive ? yearUnknown : undefined
    };

    if (onChange) {
      onChange(filters);
    }
  }, [
    onChange,
    gradeFilterActive,
    gradeBasic,
    gradeUnknown,
    gradeProject,
    gradeRange,
    gradeRangeActive,
    qualityFiltersActive,
    qualityRange,
    faFiltersActive,
    startDate,
    endDate,
    yearUnknown,
    yearRangeActive
  ]);

  return (
    <Form>
      <Form.Check
        custom
        type="checkbox"
        id={'toggleGradeFilter'}
        label={'Grade Filters'}
        defaultChecked={gradeFilterActive}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setGradeFilterActive(e.target.checked)}
      />
      {gradeFilterActive &&
      <div className="ml-2 pl-2 border-left">
        <Form.Check
          custom
          type="checkbox"
          id="grade-range"
        >
          <Form.Check.Input
            type="checkbox"
            defaultChecked={gradeRangeActive}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setGradeRangeActive(e.target.checked)}
          />
          <Form.Check.Label style={{width: '100%'}} />
          <p>{'Not implemeneted anymore'}</p>
        </Form.Check>
        <Form.Check
          custom
          type="checkbox"
          id="grade-basic"
          label="Basic"
          defaultChecked={gradeBasic}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setGradeBasic(e.target.checked)}
        />
        <Form.Check
          custom
          type="checkbox"
          id="grade-project"
          label="Project"
          defaultChecked={gradeProject}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setGradeProject(e.target.checked)}
        />
        <Form.Check
          custom
          type="checkbox"
          id="grade-unknown"
          label="Unknown"
          defaultChecked={gradeUnknown}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setGradeUnknown(e.target.checked)}
        />
      </div>
      }
      <Form.Check
        custom
        type="checkbox"
        id={'toggleQualityFilter'}
        label={'Quality Filters'}
        defaultChecked={qualityFiltersActive}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setQualityFiltersActive(e.target.checked)}
      />
      {qualityFiltersActive &&
        <div className="ml-2 pl-2 border-left">
          <p>{'Not implemeneted anymore'}</p>
        </div>
      }
      <Form.Check
        custom
        type="checkbox"
        id={'toggleFaFilters'}
        label={'FA Filters'}
        defaultChecked={faFiltersActive}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setFaFiltersActive(e.target.checked)}
      />
      {faFiltersActive &&
        <div className="ml-2 pl-2 border-left">
          <Form.Check
            custom
            type="checkbox"
            id="year-range"
          >
            <Form.Check.Input
              type="checkbox"
              defaultChecked={yearRangeActive}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setYearRangeActive(e.target.checked)}
            />
            <Form.Check.Label style={{width: '100%'}} />
            <DatePicker
              disabled={!yearRangeActive}
              minDate={new Date(1900, 0)}
              maxDate={new Date()}
              selected={startDate}
              onChange={date => setStartDate(date as Date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              showYearPicker
              dateFormat="yyyy"
            />
            <Col>
              to
            </Col>
            <DatePicker
              disabled={!yearRangeActive}
              minDate={new Date(1900, 0)}
              maxDate={new Date()}
              selected={endDate}
              onChange={date => setEndDate(date as Date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy"
              showYearPicker
            />
            </Form.Check>
            <Form.Check
              custom
              type="checkbox"
              id="year-unknown"
              label="Unknown"
              defaultChecked={yearUnknown}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setYearUnknown(e.target.checked)}
            />
        </div>
      }
    </Form>
  );
}

