import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Area, { areaConverter } from '../model/Area';

export function subscribeToAreas(
  parent: string | null,
  onNext: (areas: Area[]) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void,
  isPrivileged: boolean = false
) {
  const colRef = collection(Firestore, 'areas').withConverter(areaConverter);
  let colQuery = query(colRef, where('parent', '==', parent));

  if (!isPrivileged) {
    colQuery = query(colQuery, where('public', '==', true))
  }

  colQuery = query(colQuery, orderBy('order'));

  return (
    onSnapshot(colQuery,
      querySnapshot => onNext(querySnapshot.docs.map(doc => doc.data())),
      onError,
      onCompletion
    )
  );
}

