import type {
  WithFieldValue,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';

import type { idAble } from './idAble';


interface RegionData {
  name: string;
  directions: string;
  description: string;
  order: number | null;
  private: boolean;
}

interface Discriminator {
  discriminator: 'Region';
}

type Region = idAble & RegionData & Discriminator;

const regionConverter: FirestoreDataConverter<Region> = {
  toFirestore(region: WithFieldValue<Region>): DocumentData {
    let copy = {...region};
    delete copy.id;
    delete copy.discriminator;

    return {...copy};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Region {
    const data = snapshot.data(options);
    const region: Region = {
      id: snapshot.id,
      name: data.name ?? '',
      directions: data.directions ?? '',
      description: data.description ?? '',
      order: data.order ?? null,
      private: data.private ?? false,
      discriminator: 'Region'
    }

    return region;
  }
};

function isRegion(obj: any): obj is Region {
  try {
    return (obj as Region).discriminator === 'Region';
  } catch {
    return false;
  }
}

export default Region;
export { regionConverter, isRegion };
export type { RegionData };

