import React, { useEffect, useState, useCallback,ChangeEvent } from 'react';
import { useMutation } from 'react-query'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import deepEqual from 'deep-equal';

import Region, { RegionData } from '../model/Region';
import { createRegion, updateRegion } from '../database';

interface EditAreaModalProps {
  show: boolean;
  onHide: () => void;
  region?: Region;
}

export default function EditAreaModal({show, onHide, region}: EditAreaModalProps) {
  const [oldRegion, setOldRegion] = useState(region);
  const [name, setName] = useState(region?.name ?? '');
  const [description, setDescription] = useState(region?.description ?? '');
  const [directions, setDirections] = useState(region?.directions ?? '')
  const [isPrivate, setIsPrivate] = useState(region?.private ?? false);
  const [order, setOrder] = useState(region?.order ?? null);

  // useState won't update bindings that rely on `region` for some reason, do
  // it manaully here, also its firing even though region isn't changing, what
  // the fuck?
  useEffect(() => {
    if (deepEqual(oldRegion, region)) {
      return;
    }
    setOldRegion(region);

    if (region === undefined) {
      setName('');
      setDescription('');
      setDirections('');
      setIsPrivate(false);
      setOrder(null);
    } else {
      setName(region.name);
      setDescription(region.description);
      setDirections(region.directions);
      setIsPrivate(region.private);
      setOrder(region.order);
    }
  }, [oldRegion, region])

  const { mutateAsync: mutateRegion } = useMutation((): Promise<void> => {
    const newRegion: RegionData = {
      name: name,
      description: description,
      directions: directions,
      private: isPrivate,
      order: order
    }
    if (oldRegion) {
      // TODO: It'd be nice to use a diff to generate UpdateData<RegionData>
      //       with field values if we ever need to delete a field.
      return updateRegion(oldRegion.id, newRegion);
    } else {
      return createRegion(newRegion);
    }
  });

  const handleSave = useCallback(async () => {
    try {
      await mutateRegion();
    } catch (e) {
      console.log('There was a problem mutating region:', e);
    }
    onHide()
  }, [onHide, mutateRegion]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{oldRegion ? 'Update Region' : 'Create Region'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Directions</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={directions}
              onChange={e => setDirections(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Privateity</Form.Label>
            <Form.Check
              id="meaningless-id-to-make-this-work"
              type="switch"
              defaultChecked={isPrivate}
              label="Private"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

