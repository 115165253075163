import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deepEqual from 'deep-equal';
import { doc, runTransaction } from 'firebase/firestore';

import { Firestore } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Area from '../model/Area';
import ReorderControl, { SortableObject } from './ReorderControl';

interface Props {
  areas: Area[];
  show: boolean;
  onHide: () => void;
}

export default function ReorderAreasModal({ areas, show, onHide }: Props) {
  const [unsortedAreas, setUnsortedAreas] = React.useState<Area[]>([]);
  const [sortedAreas, setSortedAreas] = React.useState<Area[]>([]);
  const [isModified, setIsModified] = React.useState(false);
  const [sortedResetState, setSortedResetState] = React.useState<Area[]>([]);
  const [unsortedResetState, setUnsortedResetState] = React.useState<Area[]>([]);

  // Reset states
  useEffect(() => {
    setUnsortedResetState(areas.filter(area => area.order === null));
    setSortedResetState(areas.filter(area => area.order !== null));
  }, [areas])

  // Initial area state
  useEffect(() => {
    setUnsortedAreas(areas.filter(area => area.order === null));
    setSortedAreas(areas.filter(area => area.order !== null));
  }, [areas])

  // check for modifications
  useEffect(() => {
    if (deepEqual(sortedAreas, sortedResetState) && deepEqual(unsortedAreas, unsortedResetState)) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [sortedAreas, sortedResetState, unsortedAreas, unsortedResetState]);

  const handleSave = useCallback(async () => {
    try {
      await runTransaction(Firestore, async (transaction) => {
        sortedAreas.forEach((area, index) => {
          const original = areas.find(original => original.id === area.id);
          if (original?.order !== index) {
            transaction.update(doc(Firestore, `areas/${area.id}`), {order: index})
          }
        });
        unsortedAreas.forEach(area => {
          const original = areas.find(original => original.id === area.id);
          if (original?.order !== null) {
            transaction.update(doc(Firestore, `areas/${area.id}`), {order: null})
          }
        });
      });
    } catch (e) {
      console.log('Reorder transaction failed', e);
    }
    onHide();
  }, [areas, sortedAreas, unsortedAreas, onHide]);

  const onSort = useCallback(((unsorted: SortableObject[], sorted: SortableObject[]) => {
    const newUnsorted = areas.filter(area => unsorted.find(sortable => sortable.id === area.id));
    const newSorted = areas.filter(area => sorted.find(sortable => sortable.id === area.id))
      // sort areas based on sorted result
      .sort((a, b) => sorted.indexOf(sorted.find(s => s.id === a.id)!) - sorted.indexOf(sorted.find(s => s.id === b.id)!));

    setUnsortedAreas(newUnsorted);
    setSortedAreas(newSorted);
  }), [areas]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Areas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReorderControl
          defaultUnordered={unsortedAreas.map(area => ({id: area.id ?? '', display: area.name}))}
          defaultOrdered={sortedAreas.map(area => ({id: area.id ?? '', display: area.name}))}
          onChange={onSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!isModified}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

