import { doc, onSnapshot } from 'firebase/firestore';
import { Firestore } from '../Firebase';
import Area, { areaConverter } from '../model/Area';

export function subscribeToArea(
  id: string,
  onNext: (area?: Area) => void,
  onError?: (error: Error) => void,
  onCompletion?: () => void
) {
  let docRef = doc(Firestore, `areas/${id}`).withConverter(areaConverter);

  return (
    onSnapshot(docRef,
      snapshot => onNext(snapshot.data()),
      onError,
      onCompletion
    )
  );
}

