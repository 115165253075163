import React, { useState, ChangeEvent } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { Mutex } from 'async-mutex';
import {
  UploadTask,
  ref,
  uploadBytesResumable
} from 'firebase/storage';

import { Storage } from '../Firebase';
import CustomFileInput from './CustomFileInput';


interface Props {
  pushImageIds: (..._: string[]) => void;
  onUploadStateChange: (_: boolean) => void;
}

interface ProgressObj {
  id: string;
  progress: number;
}


export default function TopoImagesUploader(props: Props) {
  const [uploadProgress, setUploadProgress] = useState<ProgressObj[]>([]);
  const [uploadTasks, setUploadTasks] = useState<UploadTask[]>();
  const [mutex] = useState(new Mutex());

  const cancelUploads = () => {
    uploadTasks?.forEach(task => {
      task.cancel();
    });
  }

  const onImageFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files

    if (files) {
      // upload the image to the file server
      props.onUploadStateChange(true);
      let uploads = Array.from(files).map(file => {
        const ext = file.name.split('.').pop();
        const uuid = uuidv4();
        const newImageRef = ref(Storage, `uploaded_images/${uuid}.${ext}`);

        return uploadBytesResumable(newImageRef, file);
      });

      setUploadTasks(uploads);

      uploads.forEach(upload => {
        uploadProgress.push({
          id: upload.snapshot.ref.name,
          progress: 0
        })
      });

      uploads.forEach(upload => {
        upload.on('state_changed', snapshot => {
          mutex
            .acquire()
            .then(release => {
              let pObj = uploadProgress.find(p => p.id === snapshot.ref.name);
              if (pObj) {
                pObj.progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                setUploadProgress([...uploadProgress]);
              }
              release();
            });
        });
      });

      Promise.all(uploads.map((upload) => upload.then()))
        .then(() => {
          const ids = uploads.map(upload => upload.snapshot.ref.name.split('.')[0]);
          props.pushImageIds(...ids);
          props.onUploadStateChange(false);
          setUploadProgress([]);
        })
        .catch(err => {
          props.onUploadStateChange(false);
          setUploadProgress([]);
          console.log('There was an error uploading the images:', err);
        });
    }
  }


  return (
    <>
      { uploadProgress.length > 0 &&
        <>
          <ProgressBar className="w-100">
            { uploadProgress.map((p, i) => {
                return (
                  <ProgressBar now={p.progress} label={`${Math.trunc(p.progress)}%`} key={i}/>
                );
              })
            }
          </ProgressBar>
          <Button onClick={cancelUploads} variant="outline-danger">
            Cancel
          </Button>
        </>
      }
      { uploadProgress.length === 0  &&
        <CustomFileInput
          label="Images"
          accept="image/*"
          onChange={onImageFileSelect}
          multiple
        />
      }
    </>
  );
}
