import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import UserContext from '../context/UserContext';
import { AreaSearch } from '../search/index';


interface AreaCountProps {
  ancestryPath: string | null;
}

export default function AreaCount({ ancestryPath }: AreaCountProps) {
  const { user } = useContext(UserContext);
  const { data: areaCount } = useQuery(
    ['areaCount', [ ancestryPath, user ]],
    () => {
      let areaSearch = new AreaSearch(user?.hasElevatedReadAccess());

      if (ancestryPath) {
        areaSearch.filterBy({ ancestryId: ancestryPath });
      }

      // TODO: Only return number of "sectors"
      return (
        areaSearch.get()
          .then(data => data.nbHits)
          .catch(e => { console.log(e); return null; })
      );
    }
  );

  return (
    <>
      {areaCount ?? '-'}
    </>
  );
}

