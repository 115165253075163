import React, { useState, useCallback, useContext } from 'react';

import { useQuery } from 'react-query';
import {
  BsPencil,
  BsPlus,
  BsTrash,
} from 'react-icons/bs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown';

import UserContext from '../context/UserContext';
import Loading from './Loading';
import { deleteNote, getNotes } from '../database';
import NoteModal from './NoteModal';
import { Note }from '../model/Note';


interface NotesModalProps {
  parent: string;
  show: boolean;
  onHide: () => void;
}

export default function NotesModal({parent, show, onHide}: NotesModalProps) {
  const { user } = useContext(UserContext);
  const [showNoteModal, setShowNoteModal] = useState(false);

  // queries
  const fetchNotes = useCallback(async () => {
    return getNotes(parent);
  }, [parent]);
  const { isLoading: isLoadingNotes, data: notes } = useQuery(['notes', parent, user], fetchNotes);

  const handleShowNoteModal = useCallback(() => {
    setShowNoteModal(true);
  }, []);

  const handleCloseNoteModal = useCallback(() => {
    setShowNoteModal(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { isLoadingNotes &&
          <Row>
            <Col />
            <Col xs="auto">
              <Loading />
            </Col>
            <Col />
          </Row>
          }
          { notes &&
          <ListGroup>
            {notes.map((note) => {
              return <NoteItem key={note.id} note={note} />
            })}
          </ListGroup>
          }
        </Modal.Body>
        {user?.hasWriteAccess() &&
        <Modal.Footer>
          <Container>
            <Button block onClick={handleShowNoteModal}><BsPlus /></Button>
          </Container>
        </Modal.Footer>
        }
      </Modal>
      <NoteModal
        show={showNoteModal}
        onHide={handleCloseNoteModal}
        parent={parent}
      />
    </>
  );
}


interface NoteItemProps {
  note: Note;
}

function NoteItem({ note }: NoteItemProps) {
  const { user } = useContext(UserContext);
  const [showNoteModal, setShowNoteModal] = useState(false);

  const onDelete = useCallback(() => {
    if (window.confirm('Are you sure? This cannot be undone.')) {
      deleteNote(note.id)
      .then(() => console.log('Successfully deleted note:', note.id))
      .catch(err => console.log('Failed to delete note:', err));
    }
  }, [note]);

  const handleShowNoteModal = useCallback(() => {
    setShowNoteModal(true);
  }, []);

  const handleCloseNoteModal = useCallback(() => {
    setShowNoteModal(false);
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title>
                {note.classification}
              </Card.Title>
            </Col>
            {user?.hasWriteAccess() &&
            <Col xs="auto">
              <ButtonGroup>
                <Button variant="secondary" onClick={handleShowNoteModal}>
                  <BsPencil />
                </Button>
                <Button variant="danger" onClick={onDelete}>
                  <BsTrash />
                </Button>
              </ButtonGroup>
            </Col>
            }
          </Row>
          <Card.Text>
            <ReactMarkdown>
              {note.content}
            </ReactMarkdown>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{`${new Date(note.creationTime).toDateString()}`}</small>
        </Card.Footer>
      </Card>
      {user?.hasWriteAccess() &&
        <NoteModal
          show={showNoteModal}
          onHide={handleCloseNoteModal}
          note={note}
        />
      }
    </>
  );
}

export { NoteItem };
