import React from 'react';

export default function About() {
  return (
    <div>
      <h2>About</h2>
      <p>This is the about page for the Black Hills Bouldering Hub. There may be a donate button here.</p>
    </div>
  )
}
