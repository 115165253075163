import { FirestoreV8Compat } from '../Firebase';
import Photo, { PhotoConverter } from '../model/Photo';


export async function updatePhoto(photo: Photo) {
  const id = photo.id;
  const convertedPhoto = PhotoConverter.toFirestore(photo);
  return FirestoreV8Compat.collection('gallery').doc(id).update(convertedPhoto);
}

