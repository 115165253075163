import { FirestoreV8Compat } from '../Firebase';
import { UpdateData } from 'firebase/firestore';
import { AreaData } from '../model/Area';


export async function createArea(area: AreaData): Promise<void> {
  await FirestoreV8Compat.collection('areas').add(area);
  // returning void so all of these have the same return type
  return;
}

export async function updateArea(id: string, data: UpdateData<AreaData>) {
  return FirestoreV8Compat.doc(`areas/${id}`).update(data);
}

export async function deleteArea(id: string) {
  return FirestoreV8Compat.doc(`areas/${id}`).delete();
}

