import styled from 'styled-components';


const styledContainer = styled.div`
background-color: #e9ecef;
border-radius: .25rem;
margin: 1rem 0 1rem 0;
padding: 1rem;
`;

export default styledContainer;

